import { toast as t } from "react-hot-toast";
import { format as formatTZ, utcToZonedTime } from "date-fns-tz";
import { createAvatar } from "@dicebear/avatars";
import * as style from "@dicebear/pixel-art";
import { en } from "i18n/en";
import { ua } from "i18n/ua";
import { ru } from "i18n/ru";
import { es } from "i18n/es";
import { de } from "i18n/de";
import { cs } from "i18n/cs";
import { pt } from "i18n/pt";
import { hi } from "i18n/hi";
import { fr } from "i18n/fr";
import { it } from "i18n/it";
import { pl } from "i18n/pl";
import { parse } from "uuid";
import {
  formatDistanceStrict,
  formatDistanceToNow,
  isAfter,
  parseISO,
  subHours,
} from "date-fns";
import * as Sentry from "@sentry/nextjs";
import { IChatMessage } from "types/chat";
import {
  uk,
  ru as ruLocale,
  enUS,
  es as esLocale,
  de as deLocale,
  cs as csLocale,
  pt as ptLocale,
  hi as hiLocale,
  fr as frLocale,
  it as itLocale,
  pl as plLocale,
} from "date-fns/locale";
import { languagesOptions } from "constants/languages";
import productionConfig from "config/production";
import stagingConfig from "config/staging";
import { formatWithLocale } from "utils/formatWithLocale";
import { documentsWithIcon } from "constants/fileTypes";
import { Me, OfferItem } from "types/user";
import CustomToast from "components/CustomToast/CustomToast";
import { EmptyDocIcon } from "components/Icons";
import { timeZones } from "constants/timeZones";
import * as CryptoJS from "crypto-js";
import { utcToTimeZone, formatTimezoneString } from "utils/timeZoneUtils";
import { addMinutes, addDays } from "date-fns";
import { LinkIcon } from "@heroicons/react/solid";
import Cookies from "js-cookie";

export const TEN_SECONDS = 10000;
export const THIRTY_SECONDS = 30000;
export const FIVE_SECONDS = 5000;
export const ONE_SECOND = 1000;
export const ONE_DAY = 24;
export const MOBILE_WIDTH = 767;

export const getFullName = (
  firstName: string,
  lastName: string,
  fallback: string = ""
): string =>
  firstName || lastName ? `${firstName || ""} ${lastName || ""}` : fallback;

export const toast = (
  content:
    | string
    | { title: string; body?: string; icon?: "info" | "error" }
    | undefined,
  props: { type: "success" | "error" | "custom" } = { type: "success" },
  options?: {
    duration?: number;
    id: string;
    className?: string;
    isButton?: boolean;
  }
) => {
  const withDismissOnClick = (content, toastFunc, options) => {
    const toastId = toastFunc(
      <div className="cursor-pointer" onClick={() => t.dismiss(toastId)}>
        {content}
      </div>,
      options
    );
    return toastId;
  };

  if (!content) {
    return;
  }

  switch (props.type) {
    case "success": {
      if (typeof content === "string") {
        return withDismissOnClick(content, t.success, options);
      }
      break;
    }
    case "error": {
      if (typeof content === "string") {
        return withDismissOnClick(content, t.error, options);
      }
      break;
    }
    case "custom": {
      return (
        typeof content === "object" &&
        t.custom(
          (toast) => (
            <>
              <CustomToast
                t={toast}
                content={content}
                icon={content.icon}
                isButton={options?.isButton}
              />
            </>
          ),
          options
        )
      );
    }
  }
};

export const getStatusColor = (
  status:
    | "confirmed"
    | "requested"
    | "canceled"
    | "approved"
    | "requires_action"
    | "succeeded"
    | "failed"
    | "admin"
    | "subscribed"
    | "trial"
    | "unsubscribed"
    | "external"
    | "refunded"
    | "processing"
    | "pending"
    | "requires_onboarding"
    | "requires_payout"
    | "requires_transfer"
    | "paid"
    | string
): string => {
  switch (status) {
    case "approved":
    case "confirmed":
    case "succeeded":
    case "paid":
      return "bg-green-100 text-green-800";
    case "requested":
    case "requires_action":
    case "processing":
    case "requires_payout":
    case "pending":
      return "bg-yellow-100 text-yellow-800";
    case "canceled":
    case "failed":
    case "refunded":
      return "bg-red-100 text-red-800";
    case "admin":
      return "text-slate-50 bg-slate-500";
    case "subscribed":
      return "bg-green-100 text-green-800";
    case "trial":
      return "text-blue-50 bg-blue-500";
    case "unsubscribed":
      return "text-orange-100 bg-red-500";
    case "requires_onboarding":
    case "requires_transfer":
    case "created":
      return "bg-gray-100";
    case "null":
      return "text-gray-100 bg-gray-800";
    default:
      return "bg-gray-100";
  }
};

export const formatDate = (
  date: string | undefined,
  dateFormat = "dd MMMM yyyy"
) =>
  date
    ? formatWithLocale(new Date(date), dateFormat, { locale: getLocale() })
    : null;

export const formatTime = (date: string | undefined) =>
  date ? formatWithLocale(new Date(date), "HH:mm") : null;

export const formatDateTime = (
  date: string | undefined,
  format?: string | undefined
) =>
  date
    ? format && format === "12h"
      ? formatWithLocale(new Date(date), "MMMM dd, yyyy hh:mma")
      : formatWithLocale(new Date(date), "MMMM dd, yyyy HH:mm")
    : null;

const get = (o: typeof en, s: string): any => {
  try {
    s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
    s = s.replace(/^\./, ""); // strip a leading dot
    let a = s.split(".");
    for (let i = 0, n = a.length; i < n; ++i) {
      let k = a[i];
      if (k in o) {
        // @ts-ignore
        o = o[k];
      } else {
        return;
      }
    }
  } catch (error) {
    return;
  }
  return o;
};

const i18n =
  (s: typeof en) =>
  (key: string): string => {
    return get(s, key) ? get(s, key) : key;
  };

export const UKRAINIAN_TIMEZONES = [
  "Europe/Kiev",
  "Europe/Kyiv",
  "Europe/Simferopol",
  "Europe/Uzhgorod",
  "Europe/Zaporozhye",
  "Europe/Zaporizhzhia",
];

export const ITALIAN_TIMEZONES = ["Europe/Rome"];

export const PORTUGUESE_TIMEZONES = [
  "Europe/Lisbon",
  "Atlantic/Madeira",
  "Atlantic/Azores",
  "America/Araguaina",
  "America/Bahia",
  "America/Belem",
  "America/Boa_Vista",
  "America/Campo_Grande",
  "America/Cuiaba",
  "America/Eirunepe",
  "America/Fortaleza",
  "America/Maceio",
  "America/Manaus",
  "America/Noronha",
  "America/Porto_Acre",
  "America/Porto_Velho",
  "America/Recife",
  "America/Rio_Branco",
  "America/Santarem",
  "America/Sao_Paulo",
  "Brazil/Acre",
  "Brazil/DeNoronha",
  "Brazil/East",
  "Brazil/West",
];

export const HINDI_TIMEZONES = ["Asia/Calcutta", "Asia/Kolkata"];

export const FRENCH_TIMEZONES = [
  "Europe/Brussels",
  "Europe/Paris",
  "Europe/Monaco",
];

export const CZECH_TIMEZONES = ["Europe/Prague"];

export const GERMAN_TIMEZONES = [
  "Europe/Vienna",
  "Europe/Zurich",
  "Europe/Berlin",
  "Europe/Busingen",
  "Europe/Luxembourg",
  "Europe/Vaduz",
];

export const POLISH_TIMEZONES = ["Europe/Warsaw"];

export const SPANISH_TIMEZONES = [
  "America/Argentina/Jujuy",
  "America/Argentina/La_Rioja",
  "America/Argentina/Mendoza",
  "America/Argentina/Rio_Gallegos",
  "America/Argentina/Salta",
  "America/Argentina/San_Juan",
  "America/Argentina/San_Luis",
  "America/Argentina/Tucuman",
  "America/Argentina/Ushuaia",
  "America/Argentina/ComodRivadavia",
  "America/Argentina/Cordoba",
  "America/Argentina/Catamarca",
  "America/Argentina/Chubut",
  "America/Argentina/Buenos_Aires",
  "America/Buenos_Aires",
  "America/Catamarca",
  "America/Cordoba",
  "America/Jujuy",
  "America/Mendoza",
  "America/Rosario",
  "America/La_Paz",
  "America/Santiago",
  "America/Bogota",
  "America/Havana",
  "America/Costa_Rica",
  "America/Dominica",
  "America/Guayaquil",
  "Atlantic/Canary",
  "Africa/Ceuta",
  "Europe/Madrid",
  "Mexico/BajaNorte",
  "Mexico/BajaSur",
  "Mexico/General",
  "America/Guatemala",
  "America/Tegucigalpa",
  "America/Chihuahua",
  "America/Hermosillo",
  "America/Mazatlan",
  "America/Ojinaga",
  "America/Bahia_Banderas",
  "America/Matamoros",
  "America/Merida",
  "America/Mexico_City",
  "America/Monterrey",
  "America/Cancun",
  "America/Managua",
  "America/Panama",
  "America/Lima",
  "America/Puerto_Rico",
  "America/Asuncion",
  "America/El_Salvador",
  "America/Montevideo",
  "America/Caracas",
];

export const getLanguageCode = () => {
  const hasWindow = typeof window !== "undefined";

  const savedLanguage = Cookies.get("language");
  const browserLanguage = hasWindow && window.navigator.language;
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  var language = null;

  if (savedLanguage) {
    language = Cookies.get("language");
  } else if (browserLanguage) {
    language = browserLanguage.startsWith("uk")
      ? "UA"
      : browserLanguage.startsWith("es")
      ? "ES"
      : browserLanguage.startsWith("cs")
      ? "CS"
      : browserLanguage.startsWith("de")
      ? "DE"
      : browserLanguage.startsWith("pt")
      ? "PT"
      : browserLanguage.startsWith("it")
      ? "IT"
      : browserLanguage.startsWith("hi")
      ? "HI"
      : browserLanguage.startsWith("fr")
      ? "FR"
      : browserLanguage.startsWith("pl")
      ? "PL"
      : browserLanguage.startsWith("en")
      ? "EN"
      : null;
  } else {
    const UKRAINE = UKRAINIAN_TIMEZONES.includes(timeZone);
    const GERMAN = GERMAN_TIMEZONES.includes(timeZone);
    const CZECH = CZECH_TIMEZONES.includes(timeZone);
    const SPANISH = SPANISH_TIMEZONES.includes(timeZone);
    const PORTUGUESE = PORTUGUESE_TIMEZONES.includes(timeZone);
    const ITALIAN = ITALIAN_TIMEZONES.includes(timeZone);
    const HINDI = HINDI_TIMEZONES.includes(timeZone);
    const FRENCH = FRENCH_TIMEZONES.includes(timeZone);
    const POLISH = POLISH_TIMEZONES.includes(timeZone);

    if (UKRAINE) {
      language = "UA";
    } else if (SPANISH) {
      language = "ES";
    } else if (CZECH) {
      language = "CS";
    } else if (GERMAN) {
      language = "DE";
    } else if (PORTUGUESE) {
      language = "PT";
    } else if (ITALIAN) {
      language = "IT";
    } else if (HINDI) {
      language = "HI";
    } else if (FRENCH) {
      language = "FR";
    } else if (POLISH) {
      language = "PL";
    } else {
      language = "EN";
    }
  }

  return language?.toUpperCase();
};

// export const translate = (key: string) => {
//   var language = getLanguageCode();

//   switch (language) {
//     case "UA":
//     case "UK":
//     case "UK-UA":
//       return i18n(ua)(key);
//     case "ES":
//     case "ES-AR":
//     case "ES-BO":
//     case "ES-CL":
//     case "ES-CO":
//     case "ES-CR":
//     case "ES-DO":
//     case "ES-EC":
//     case "ES-ES":
//     case "ES-GT":
//     case "ES-HN":
//     case "ES-MX":
//     case "ES-NI":
//     case "ES-PA":
//     case "ES-PE":
//     case "ES-PR":
//     case "ES-PY":
//     case "ES-SV":
//     case "ES-US":
//     case "ES-UY":
//     case "ES-VE":
//       return i18n(es)(key);
//     case "CS":
//     case "CS-CZ":
//       return i18n(cs)(key);
//     case "PL":
//     case "PL-PL":
//       return i18n(pl)(key);
//     case "PT":
//     case "PT-PT":
//     case "PT-BR":
//       return i18n(pt)(key);
//     case "IT":
//     case "IT-IT":
//     case "IT-CH":
//       return i18n(it)(key);
//     case "HI":
//     case "HI-IN":
//       return i18n(hi)(key);
//     case "DE":
//     case "DE-AT":
//     case "DE-CH":
//     case "DE-DE":
//     case "DE-LI":
//     case "DE-LU":
//       return i18n(de)(key);
//     case "FR":
//     case "FR-FR":
//     case "FR-BE":
//     case "FR-CA":
//     case "FR-CH":
//     case "FR-MC":
//     case "FR-LU":
//       return i18n(fr)(key);
//     case "RU":
//     case "RU-MD":
//       // @ts-ignore
//       return i18n(ru)(key);
//     default:
//       return i18n(en)(key);
//   }
// };

interface TranslationParams {
  [key: string]: string | number;
}

export const translate = (key: string, params?: TranslationParams) => {
  let language = getLanguageCode();

  let translation;

  switch (language) {
    case "UA":
    case "UK":
    case "UK-UA":
      translation = i18n(ua)(key);
      break;
    case "ES":
    case "ES-AR":
    case "ES-BO":
    case "ES-CL":
    case "ES-CO":
    case "ES-CR":
    case "ES-DO":
    case "ES-EC":
    case "ES-ES":
    case "ES-GT":
    case "ES-HN":
    case "ES-MX":
    case "ES-NI":
    case "ES-PA":
    case "ES-PE":
    case "ES-PR":
    case "ES-PY":
    case "ES-SV":
    case "ES-US":
    case "ES-UY":
    case "ES-VE":
      translation = i18n(es)(key);
      break;
    case "CS":
    case "CS-CZ":
      translation = i18n(cs)(key);
      break;
    case "PL":
    case "PL-PL":
      translation = i18n(pl)(key);
      break;
    case "PT":
    case "PT-PT":
    case "PT-BR":
      translation = i18n(pt)(key);
      break;
    case "IT":
    case "IT-IT":
    case "IT-CH":
      translation = i18n(it)(key);
      break;
    case "HI":
    case "HI-IN":
      translation = i18n(hi)(key);
      break;
    case "DE":
    case "DE-AT":
    case "DE-CH":
    case "DE-DE":
    case "DE-LI":
    case "DE-LU":
      translation = i18n(de)(key);
      break;
    case "FR":
    case "FR-FR":
    case "FR-BE":
    case "FR-CA":
    case "FR-CH":
    case "FR-MC":
    case "FR-LU":
      translation = i18n(fr)(key);
      break;
    case "RU":
    case "RU-MD":
      // @ts-ignore
      translation = i18n(ru)(key);
      break;
    default:
      translation = i18n(en)(key);
  }
  if (params) {
    Object.keys(params).forEach((paramKey) => {
      const placeholder = `{{${paramKey}}}`;
      const paramValue = params[paramKey];
      if (paramValue !== undefined) {
        translation = translation.replace(placeholder, paramValue.toString());
      }
    });
  }
  return translation;
};

export const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export async function handlePromise(promise: any, ...args: any) {
  try {
    const data = await promise(...args);
    return [data, null];
  } catch (error: any) {
    if (error?.code === "PERMISSION_DENIED") {
      toast(
        translate("agoraDeniedError"),
        { type: "error" },
        { id: error.code }
      );
    }
    if (error?.code === "NOT_READABLE") {
      toast(
        translate("notReadableDevicesWarning"),
        { type: "error" },
        { id: error.code }
      );
    } else {
      toast(error.message, { type: "error" });
    }

    Sentry.captureException(error);
    return [null, error];
  }
}

export const isNotDefaultSchedule = (schedule = {}) => {
  try {
    return Object.values(schedule)?.filter(
      (day: any) =>
        day?.filter((item: any) => item.from !== "00:00" || item.to !== "24:00")
          .length
    ).length;
  } catch {
    return false;
  }
};

interface UserChecks {
  isExpert: boolean;
  hasSessions?: boolean;
  hasFondyCards?: boolean;
  hasStripeCards?: boolean;
  chargesEnabled?: boolean;
  payoutsEnabled?: boolean;
  paymentsEnabled?: boolean;
  isSubscribed?: boolean;
}

export const notificationRules = {
  clientProfile: (me: Me & UserChecks) =>
    !me.isExpert && (!me?.firstName || !me?.lastName),
  clientCards: (me: Me & UserChecks) =>
    !me?.hasFondyCards && !me.isExpert && !me?.hasStripeCards,
  clientTelegram: (me: Me & UserChecks) => !me?.isSubscribed && !me.isExpert,
  expertProfile: ({ isExpert, expert }: Me & UserChecks) =>
    isExpert &&
    ((expert?.firstName ? !expert?.firstName : !expert?.lastName) ||
      !expert?.about ||
      !expert?.tags?.length ||
      expert?.avatar?.includes("svg")),
  expertOffers: ({
    isExpert,
    offers,
  }: {
    isExpert: boolean;
    offers: OfferItem[];
  }) => isExpert && !offers?.length,
  expertCards: ({
    isExpert,
    expert,
    chargesEnabled,
    payoutsEnabled,
  }: Me & UserChecks) =>
    expert?.stripeAccountId === null || expert?.stripeAccountId === ""
      ? isExpert && expert?.country === "UA" && !expert?.isPaymentEnabled
      : isExpert && (!chargesEnabled || !payoutsEnabled),

  expertTelegram: ({ isExpert, isSubscribed }: Me & UserChecks) =>
    isExpert && !isSubscribed,
  expertSchedule: ({ isExpert, expert, hasSessions }: Me & UserChecks) =>
    isExpert && !hasSessions && !isNotDefaultSchedule(expert?.schedule),
};

export const getPriceType = (type?: string) => {
  switch (type?.toLocaleLowerCase()) {
    case "fixed":
      return translate("appointment");
    case "single":
      return translate("single");
    case "tutorial":
      return translate("tutorial");
    case "tip":
      return translate("tip");
    case "event":
      return translate("event");
    case "community":
      return translate("community");
    default:
      return translate("appointment");
  }
};

export const filterByFileSize = (file: File) => {
  const maxFileSize = 1_048_576_000;

  if (file.size > maxFileSize) {
    toast(translate("toastTooLargeFile"), { type: "error" });
    return false;
  }
  return true;
};

export const filterMediaMessages = (messages: IChatMessage[]) =>
  messages.filter((message) => message.media.length);

export const getLanguageLabel = (language: string) => {
  const foundedLanguage = languagesOptions.find((i) => i.value === language);
  return foundedLanguage?.label || language;
};

export const config =
  process.env.NEXT_PUBLIC_ENVIRONMENT === "production" &&
  //remove after adding staging env
  process.env.NEXT_PUBLIC_BASE_HOST !== "https://staging.waylight.me"
    ? productionConfig
    : stagingConfig;

export const formatHours = (minutes: number): string => {
  if (!minutes) {
    return `0${translate("h")}`;
  }

  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;

  if (mins === 0) {
    return `${hours}${translate("h")}`;
  } else {
    return `${hours}${translate("h")} ${mins}${translate("m")}`;
  }
};

export const getLocale = () => {
  const languageCode = getLanguageCode();

  switch (languageCode) {
    case "UA":
    case "UK":
    case "UK-UA":
      return uk;
    case "ES":
    case "ES-AR":
    case "ES-BO":
    case "ES-CL":
    case "ES-CO":
    case "ES-CR":
    case "ES-DO":
    case "ES-EC":
    case "ES-ES":
    case "ES-GT":
    case "ES-HN":
    case "ES-MX":
    case "ES-NI":
    case "ES-PA":
    case "ES-PE":
    case "ES-PR":
    case "ES-PY":
    case "ES-SV":
    case "ES-US":
    case "ES-UY":
    case "ES-VE":
      return esLocale;
    case "CS":
    case "CS-CZ":
      return csLocale;
    case "PL":
    case "PL-PL":
      return plLocale;
    case "PT":
    case "PT-PT":
    case "PT-BR":
      return ptLocale;
    case "IT":
    case "IT-IT":
    case "IT-CH":
      return itLocale;
    case "HI":
    case "HI-IN":
      return hiLocale;
    case "DE":
    case "DE-AT":
    case "DE-CH":
    case "DE-DE":
    case "DE-LI":
    case "DE-LU":
      return deLocale;
    case "FR":
    case "FR-FR":
    case "FR-BE":
    case "FR-CA":
    case "FR-CH":
    case "FR-MC":
    case "FR-LU":
      return frLocale;

    case "RU":
    case "RU-MD":
      return ruLocale;
    default:
      return enUS;
  }
};

export const localizeFormatDistanceToNow = (dateCreated: Date): string =>
  formatDistanceToNow(dateCreated, { addSuffix: true, locale: getLocale() });

export const convertRating = (rating: number): string => {
  return rating ? (rating / 100).toFixed(1) : "";
};

export const getCity = (timeZone: string): string => {
  const splittedTZ = timeZone.split("/");
  const city = splittedTZ[splittedTZ.length - 1].replace(/_/g, " ");

  if (city === "Kiev") {
    return "Kyiv";
  }

  return city;
};

export const getFormattedTimeZone = (
  timeZone: string | undefined,
  date: Date = new Date()
): string => {
  if (timeZone === "Europe/Kyiv") {
    return formatTZ(date, "('GMT'XXX) zzzz", {
      timeZone: "Europe/Kiev",
    }).replace("'", "");
  }

  return timeZone
    ? formatTZ(date, "('GMT'XXX) zzzz", {
        timeZone,
      }).replace("'", "")
    : "";
};

export const getSameOffsetTZ = (
  timeZone: string,
  date?: Date
): { city: string; timeZone: string; offset: string }[] => {
  const formattedTZ = getFormattedTimeZone(timeZone, date);
  const offset = formattedTZ.match(/\((.*)\)/)[0];
  return timeZones.filter((el) => el.offset === offset);
};

export const localizeFormatDistanceStrict = (lastSessionDate: string) =>
  formatDistanceStrict(new Date(), new Date(lastSessionDate), {
    locale: getLocale(),
  });

// export const handleClipboard = ({
//   text,
//   toastText,
//   isLink = true,
// }: {
//   text: string;
//   toastText?: string;
//   isLink?: boolean;
// }) => {
//   try {
// window.navigator.clipboard.writeText(
//   isLink ? `${window.location.origin}/${text}` : text
// );
// toast(toastText ? translate(toastText) : translate("toastProfileCopied"), {
//   type: "success",
// });
//   } catch (e: any) {
//     console.log(e.message);
//   }
// };
import ClipboardJS from "clipboard";

let clipboard = null;

export const handleClipboard = ({
  text,
  toastText,
  isLink = true,
  referral,
}: {
  text: string;
  toastText?: string;
  isLink?: boolean;
  referral?: boolean;
}) => {
  const clipboardText =
    isLink && !referral
      ? `${window.location.origin}/${text}`
      : referral
      ? `${window.location.origin}${text}`
      : text;

  if (clipboard) {
    clipboard.destroy();
  }

  clipboard = new ClipboardJS(".clipboard", {
    text: function (trigger) {
      return clipboardText;
    },
  });

  clipboard.on("success", function (e) {
    e.clearSelection();
    toast(toastText ? translate(toastText) : translate("toastProfileCopied"), {
      type: "success",
    });
  });

  clipboard.on("error", function (e) {
    console.error("Action:", e.action);
    console.error("Trigger:", e.trigger);
  });

  return clipboard;
};

export const filterTags = (tags: string[]) => Array.from(new Set(tags));

export const getFileIcon = (fileName = "") =>
  documentsWithIcon.find(({ type, icon }) => {
    const fileNameArr = fileName.split(".");
    const fileType = fileNameArr[fileNameArr.length - 1];
    return fileType === type && icon;
  })?.icon || <EmptyDocIcon />;

export const getFileIconWithColor = (fileName = "") =>
  documentsWithIcon.find(({ type }) => {
    const fileNameArr = fileName.split(".");
    const fileType = fileNameArr[fileNameArr.length - 1];
    return fileType === type;
  }) || { icon: <EmptyDocIcon />, color: "#9CA3AF" };

export const getDefaultAvatar = async (email = "") => {
  try {
    const svg = createAvatar(style, {
      seed: email,
      dataUri: true,
      scale: 75,
    });
    const res = await fetch(svg);

    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const getDefaultAvatarFile = async (email = "") => {
  try {
    const svg = createAvatar(style, {
      seed: email,
      dataUri: true,
      scale: 75,
    });
    const res = await fetch(svg);
    const blob = await res.blob();

    return new File([blob], "avatar.svg", {
      type: "image/svg+xml",
      lastModified: Number(new Date()),
    });
  } catch (e) {
    console.log(e.message);
  }
};

export const formatTag = (tag: string): string => {
  return tag
    .split(/\s+/)
    .map((word) => {
      return word === "" ? word : word[0]?.toUpperCase() + word?.substring(1);
    })
    .join(" ");
};

export function uuidToBase64(uuid_) {
  if (!uuid_) {
    return;
  }

  const bytes = parse(uuid_) as any;
  const base64 = Buffer.from(bytes).toString("base64");
  return base64.replace(/\+/g, "-").replace(/\//g, "_").substring(0, 22);
}

export const sortByDateDesc = (a: any, b: any) =>
  Number(new Date(b.created)) - Number(new Date(a.created));

export const removeSpecialCharacters = (str) => {
  const regex = /[^a-z0-9-_.!~*‘()]+/gi;
  return str?.replace(regex, "");
};

const BLOB_TYPE_BY_FILE_EXTENSION = "ATTACHMENT";
const BLOB_TYPE_BY_FILE_TYPE = {
  image: "PHOTO",
  video: "VIDEO",
  audio: "ATTACHMENT",
  application: "ATTACHMENT",
};

export const getBlobType = (file: File) => {
  const fileType = file.type.split("/")[0];
  return BLOB_TYPE_BY_FILE_TYPE[fileType] || BLOB_TYPE_BY_FILE_EXTENSION;
};

export const formatSeconds = (date: string, timeZone: string) => {
  const seconds = parseInt(
    formatDistanceStrict(
      utcToZonedTime(date, timeZone),
      utcToZonedTime(new Date().toISOString(), timeZone),
      {
        unit: "second",
      }
    )
  );

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const sec = Math.floor(seconds % 60);

  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${sec.toString().padStart(2, "0")}`;
};
export const validateExternalVideoLink = (url) => {
  const regExp =
    /^(http:\/\/|https:\/\/)(vimeo\.com|youtu\.be|www\.youtube\.com|youtube\.com|m\.youtube\.com)\/([\w/]+)([?].*)?$/gim;

  if (url === "" || url === undefined || url === null) {
    return true;
  }

  const match = url.match(regExp);

  return !!match?.length;
};

export const getPrice = (price) =>
  price.toString().includes(",")
    ? price.toString().replace(",", ".")
    : price.toString();

export const getUpdatedPrice = (price) =>
  price?.split(".")?.[1] === "00" ? price.split(".")[0] : price;

export const feesArray = [
  {
    Currency: "AUD",
    Minimum_fee: 0.035,
    Maximum_fee: 0.055,
    Additional_minimum_fee: 0.3,
    Additional_maximum_fee: 0.3,
  },
  {
    Currency: "EUR",
    Minimum_fee: 0.0325,
    Maximum_fee: 0.0525,
    Additional_minimum_fee: 0.25,
    Additional_maximum_fee: 0.25,
  },
  {
    Currency: "BGN",
    Minimum_fee: 0.0325,
    Maximum_fee: 0.0525,
    Additional_minimum_fee: 0.5,
    Additional_maximum_fee: 0.5,
  },
  {
    Currency: "CAD",
    Minimum_fee: 0.029,
    Maximum_fee: 0.057,
    Additional_minimum_fee: 0.3,
    Additional_maximum_fee: 0.3,
  },
  {
    Currency: "CZK",
    Minimum_fee: 0.0325,
    Maximum_fee: 0.0525,
    Additional_minimum_fee: 6.5,
    Additional_maximum_fee: 6.5,
  },
  {
    Currency: "DKK",
    Minimum_fee: 0.0325,
    Maximum_fee: 0.0525,
    Additional_minimum_fee: 1.8,
    Additional_maximum_fee: 1.8,
  },
  {
    Currency: "HKD",
    Minimum_fee: 0.034,
    Maximum_fee: 0.059,
    Additional_minimum_fee: 2.35,
    Additional_maximum_fee: 2.35,
  },
  {
    Currency: "HUF",
    Minimum_fee: 0.0325,
    Maximum_fee: 0.0525,
    Additional_minimum_fee: 85,
    Additional_maximum_fee: 85,
  },
  {
    Currency: "INR",
    Minimum_fee: 0.043,
    Maximum_fee: 0.063,
    Additional_minimum_fee: 0,
    Additional_maximum_fee: 0,
  },
  {
    Currency: "CHF",
    Minimum_fee: 0.0325,
    Maximum_fee: 0.0525,
    Additional_minimum_fee: 0.3,
    Additional_maximum_fee: 0.3,
  },
  {
    Currency: "MXN",
    Minimum_fee: 0.036,
    Maximum_fee: 0.061,
    Additional_minimum_fee: 3,
    Additional_maximum_fee: 3,
  },
  {
    Currency: "NZD",
    Minimum_fee: 0.037,
    Maximum_fee: 0.057,
    Additional_minimum_fee: 0.3,
    Additional_maximum_fee: 0.3,
  },
  {
    Currency: "NOK",
    Minimum_fee: 0.0325,
    Maximum_fee: 0.0525,
    Additional_minimum_fee: 2,
    Additional_maximum_fee: 2,
  },
  {
    Currency: "PLN",
    Minimum_fee: 0.0325,
    Maximum_fee: 0.0525,
    Additional_minimum_fee: 1,
    Additional_maximum_fee: 1,
  },
  {
    Currency: "RON",
    Minimum_fee: 0.0325,
    Maximum_fee: 0.0525,
    Additional_minimum_fee: 1,
    Additional_maximum_fee: 1,
  },
  {
    Currency: "SGD",
    Minimum_fee: 0.034,
    Maximum_fee: 0.059,
    Additional_minimum_fee: 0.5,
    Additional_maximum_fee: 0.5,
  },
  {
    Currency: "SEK",
    Minimum_fee: 0.0325,
    Maximum_fee: 0.0525,
    Additional_minimum_fee: 1.8,
    Additional_maximum_fee: 1.8,
  },
  {
    Currency: "THB",
    Minimum_fee: 0.0475,
    Maximum_fee: 0.0675,
    Additional_minimum_fee: 10,
    Additional_maximum_fee: 10,
  },
  {
    Currency: "GBP",
    Minimum_fee: 0.0325,
    Maximum_fee: 0.0525,
    Additional_minimum_fee: 0.2,
    Additional_maximum_fee: 0.2,
  },
  {
    Currency: "USD",
    Minimum_fee: 0.029,
    Maximum_fee: 0.054,
    Additional_minimum_fee: 0.3,
    Additional_maximum_fee: 0.3,
  },
  {
    Currency: "UAH",
    Minimum_fee: 0.029,
    Maximum_fee: 0.054,
    Additional_minimum_fee: 0.3,
    Additional_maximum_fee: 0.3,
  },
];
export function roundTo(num, decimalPlaces) {
  const factor = Math.pow(10, decimalPlaces);
  return Math.round(num * factor) / factor;
}

export const getOfferFee = ({
  amount,
  countryCard,
  isClientPayingFees,
  isFondy,
  country,
  currency,
  waylightFee,
}: {
  amount: number;
  countryCard: string;
  isFondy: boolean;
  isClientPayingFees: boolean;
  country?: string;
  currency?: string;
  waylightFee?: number;
}) => {
  let rawFee;

  if (!isClientPayingFees) {
    return 0;
  }

  if (isFondy) {
    rawFee = amount * 0.022 + amount * 0.022 * 0.022;

    return Math.ceil(rawFee * 100) / 100;
  }
  const Minimum_fee = feesArray.find(
    (fee) => fee.Currency === currency
  )?.Minimum_fee;
  const Maximum_fee = feesArray.find(
    (fee) => fee.Currency === currency
  )?.Maximum_fee;

  const Additional_minimum_fee = feesArray.find(
    (fee) => fee.Currency === currency
  )?.Additional_minimum_fee;
  const Additional_maximum_fee = feesArray.find(
    (fee) => fee.Currency === currency
  )?.Additional_maximum_fee;

  if (countryCard === "US") {
    rawFee =
      amount * Minimum_fee +
      Additional_minimum_fee +
      (amount * Minimum_fee + Additional_minimum_fee) * Minimum_fee +
      (waylightFee + waylightFee * Minimum_fee);
  } else {
    rawFee =
      amount * Maximum_fee +
      Additional_maximum_fee +
      (amount * Maximum_fee + Additional_maximum_fee) * Maximum_fee +
      (waylightFee + waylightFee * Maximum_fee);
  }

  return roundTo(rawFee, 2);
};

export const getTopLevelDomain = (url) => {
  const { hostname } = new URL(url);
  const splitHostname = hostname.split(".");
  const topLevelDomain = splitHostname[splitHostname.length - 2];

  if (topLevelDomain === "facebook") {
    return "facebookAlt";
  }
  if (topLevelDomain === "stackoverflow") {
    return "stackOverflow";
  }

  if (
    [
      "angelist",
      "behance",
      "blogger",
      "codepen",
      "deviantart",
      "discord",
      "dribbble",
      "github",
      "gofundme",
      "goodreads",
      "instagram",
      "kickstarter",
      "linkedin",
      "medium",
      "meetup",
      "patreon",
      "pinterest",
      "productHunt",
      "reddit",
      "skillshare",
      "slack",
      "slackAlt",
      "snapchat",
      "spotify",
      "steam",
      "tiktok",
      "tumblr",
      "twitch",
      "twitter",
      "vimeo",
      "weibo",
      "wordpress",
      "www",
      "yelp",
      "youtube",
    ].includes(topLevelDomain)
  ) {
    return topLevelDomain;
  }

  return "default";
};

const iconComponents = {
  "x.com": (
    <span className="icon-[ri--twitter-x-fill] text-gray-400 w-6 h-6 mt-1" />
  ),
  "telegram.org": (
    <span className="icon-[ic--baseline-telegram] text-gray-400 w-6 h-6 mt-1" />
  ),

  "waylight.me": (
    <img
      src="/waylight-icon-gray1.svg"
      className=" rounded-full text-gray-400 w-8 h-8"
    />
  ),
  "t.me": (
    <span className="icon-[ic--baseline-telegram] text-gray-400 w-6 h-6 mt-1" />
  ),
  "wa.me": (
    <span className="icon-[ic--baseline-whatsapp] text-gray-400 w-6 h-6 mt-1 shrink-0" />
  ),
  "whatsapp.com": (
    <span className="icon-[ic--baseline-whatsapp] text-gray-400 w-6 h-6 mt-1 shrink-0" />
  ),
  "music.apple.com": (
    <span className="icon-[simple-icons--applemusic] text-gray-400 w-5 h-5 mt-1 shrink-0" />
  ),
  "itunes.apple.com": (
    <span className="icon-[simple-icons--applemusic] text-gray-400 w-5 h-5 mt-1 shrink-0" />
  ),
  "music.youtube.com": (
    <span className="icon-[simple-icons--youtubemusic] text-gray-400 w-5 h-5 mt-1 shrink-0" />
  ),
  "viber.com": (
    <span className="icon-[basil--viber-outline] text-gray-400 w-5 h-5 mt-1 shrink-0" />
  ),
  "invite.viber.com": (
    <span className="icon-[basil--viber-outline] text-gray-400 w-5 h-5 mt-1 shrink-0" />
  ),
  "snapchat.com": (
    <span className="icon-[fa-brands--snapchat] text-gray-400 w-5 h-5 mt-1 shrink-0" />
  ),
  "quora.com": (
    <span className="icon-[jam--quora-circle] text-gray-400 w-6 h-6 mt-1 shrink-0" />
  ),
  "substack.com": (
    <span className="icon-[bi--substack] text-gray-400 w-5 h-5 mt-1 shrink-0" />
  ),
  "deezer.com": (
    <span className="icon-[arcticons--deezer] text-gray-400 w-5 h-5 mt-1 shrink-0" />
  ),
  "soundcloud.com": (
    <span className="icon-[mdi--soundcloud] text-gray-400 w-6 h-6 mt-1 shrink-0" />
  ),
  "shazam.com": (
    <span className="icon-[simple-icons--shazam] text-gray-400 w-5 h-5 mt-1 shrink-0" />
  ),
  "play.google.com": (
    <span className="icon-[simple-icons--googleplay] text-gray-400 w-5 h-5 mt-1 shrink-0" />
  ),
  "amazon.com": (
    <span className="icon-[bi--amazon] text-gray-400 w-5 h-5 mt-1.5 shrink-0" />
  ),
  "etsy.com": (
    <span className="icon-[fa6-brands--etsy] text-gray-400 w-5 h-5 mt-1.5 shrink-0" />
  ),
  "spreadshirt.com": (
    <span className="icon-[simple-icons--spreadshirt] text-gray-400 w-5 h-5 mt-1.5 shrink-0" />
  ),
  "linktr.ee": (
    <span className="icon-[simple-icons--linktree] text-gray-400 w-5 h-5 mt-1.5 shrink-0" />
  ),
  "shopify.com": (
    <span className="icon-[ic--baseline-shopify] text-gray-400 w-6 h-6 mt-1.5 shrink-0" />
  ),
};

export const getIconSocialMedia = (url) => {
  const { hostname } = new URL(url);
  const parts = hostname.split(".");
  let socialMedia = parts[0] === "www" ? parts.slice(1).join(".") : hostname;

  for (let domain in iconComponents) {
    if (socialMedia.includes(domain)) {
      return iconComponents[domain];
    }
  }

  return <LinkIcon className="w-6 h-6 text-gray-400 animated-grow" />;
};

export const truncateString = (str: string, characters: number) => {
  if (!str || !characters) {
    return "";
  }

  return str.length <= characters ? str : str.slice(0, characters) + "...";
};

export const getDecodedLocation = () => {
  if (typeof window === "undefined") {
    return;
  }
  const decodedUrl = decodeURIComponent(window.location.href);

  return new URL(decodedUrl);
};

export const divideArray = (array) => {
  const firstArray = [];
  const secondArray = [];

  array.forEach((element, index) => {
    if (index === 0 || index % 2 === 0) {
      firstArray.push(element);
    } else {
      secondArray.push(element);
    }
  });

  return { firstArray, secondArray };
};

export const getIsDelayed = (hours, dateString, timeZone) => {
  const date = parseISO(dateString);
  const delayedDate = subHours(date, hours);

  return isAfter(
    utcToZonedTime(delayedDate, timeZone),
    utcToZonedTime(new Date(), timeZone)
  );
};

export function getLinkFromString(str: string) {
  if (!str) {
    return "";
  }

  const regex = /(https?:\/\/[^\s]*)/g;
  return str?.replace(regex, "<a href='$1' data-is-link='true'>$1</a>");
}

export function makeUrlsClickable(text) {
  if (!text) {
    return null;
  }

  const urlRegex =
    /(\bhttps?:\/\/\S+\b|\bwww\.[-a-z0-9]+(\.[-a-z0-9]+)*\.[a-z]{2,5}\b)/gi;
  const parts = text.split(urlRegex);

  return (
    <span className="w-full">
      {parts.map((part, index) => {
        if (part && part.match && part.match(urlRegex)) {
          return (
            <a
              className="text-blue-500 font-medium whitespace-normal break-words w-full"
              key={index}
              href={part}
              target="_blank"
              rel="noopener noreferrer"
            >
              {part}
            </a>
          );
        } else {
          return part;
        }
      })}
    </span>
  );
}

export function getCurrentTime(
  timeFormat: string,
  timezone: string,
  increase: number = 0
) {
  const currentDate = new Date();
  const currentUtcDateTime = utcToZonedTime(currentDate, timezone);
  let hours24 = currentUtcDateTime.getHours() + increase;
  const minutes = currentUtcDateTime.getMinutes();
  const nearestHalfHour = Math.round(minutes / 30) * 30;
  let paddedMinutes = String(nearestHalfHour).padStart(2, "0");

  if (paddedMinutes === "60") {
    hours24 += 1;
    paddedMinutes = "00";
  }
  if (hours24 >= 24) {
    hours24 = hours24 % 24;
  }

  if (timeFormat === "24h") {
    const hours = String(hours24).padStart(2, "0");
    return `${hours}:${paddedMinutes}`;
  } else {
    const hours12 = hours24 % 12 || 12;
    const period = hours24 >= 12 ? "PM" : "AM";
    const hours = String(hours12).padStart(2, "0");
    return `${hours}:${paddedMinutes} ${period}`;
  }
}

export const isFacebookSupportedImage = (filename?: string | null): boolean => {
  if (!filename) {
    return false;
  }

  const fileExtensions = [".jpg", ".jpeg", ".png", ".gif", ".webp"];
  const ext = filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);

  return fileExtensions.includes("." + ext.toLowerCase());
};

export async function splitFileIntoChunks(file) {
  const fileSize = file.size;
  let minChunkSize;
  let numChunks;
  if (5 * 1024 * 1024 < fileSize && fileSize < 45 * 1024 * 1024) {
    numChunks = Math.ceil(fileSize / 1024 / 1024 / 5);
  } else {
    numChunks = 10;
  }
  if (fileSize <= 50 * 1024 * 1024) {
    minChunkSize = Math.floor(5 * 1024 * 1024);
  } else {
    minChunkSize = Math.floor(fileSize / numChunks);
  }

  const chunks = [];

  for (let i = 0; i < numChunks; i++) {
    let chunkSize;
    if (i < numChunks - 1) {
      const startByte = i * minChunkSize;
      const nextStartByte = (i + 1) * minChunkSize;
      chunkSize = nextStartByte - startByte;
    } else {
      chunkSize = fileSize - i * minChunkSize;
    }

    const startByte = i * minChunkSize;
    const endByte = Math.min(fileSize, startByte + chunkSize);

    const chunkData = file.slice(startByte, endByte, file.type);

    // Calculate checksum for each chunk
    const reader = new FileReader();
    await new Promise((resolve) => {
      reader.onload = async (event) => {
        const binary = event.target.result as ArrayBuffer;
        const hashArray = CryptoJS.SHA256(
          CryptoJS.lib.WordArray.create(binary)
        );
        const hashBase64 = CryptoJS.enc.Base64.stringify(hashArray);
        resolve(hashBase64);
      };
      reader.readAsArrayBuffer(chunkData);
    }).then((hash) => {
      chunks.push({ partNumber: i + 1, data: chunkData, checksum: hash });
    });
  }

  return chunks;
}
export const Distance = (lastSessionDate: string, timeZone) =>
  formatDistanceStrict(
    utcToZonedTime(new Date(), timeZone),
    new Date(lastSessionDate),
    {
      locale: getLocale(),
    }
  );
export function getRemainingTime(offerDateStr, timeZone) {
  const regex = /[+-]\d{2}:\d{2}/;
  const match = formatTimezoneString(timeZone, offerDateStr).match(regex);
  const start = new Date(offerDateStr);
  if (match) {
    const regexTime = /^([+-])(\d{2}):(\d{2})$/;
    const matchTime = match[0].match(regexTime);
    const [_, sign, hours, minutes] = matchTime;
    const signMultiplier = sign === "-" ? -1 : 1;
    const parsedHours = parseInt(hours);
    const parsedMinutes = parseInt(minutes);
    const totalMinutes = signMultiplier * (parsedHours * 60 + parsedMinutes);
    const startTime = addMinutes(start, totalMinutes);
    return `${translate("timeToEvent")}: ${Distance(
      startTime.toString(),
      timeZone
    )}`;
  }
}

export function isCurrentTimeInRange(eventDateStr, eventDateEndStr, timeZone) {
  if (!eventDateStr || !eventDateEndStr || !timeZone) {
    return false;
  } else {
    const today = utcToZonedTime(new Date(), timeZone);
    const eventStartDate = utcToTimeZone(`${eventDateStr}.000Z`, timeZone);
    const eventEndDate = utcToTimeZone(`${eventDateEndStr}.000Z`, timeZone);

    return today >= eventStartDate && today <= eventEndDate;
  }
}

export function compareDates(offerDateStr, timeZone) {
  if (!offerDateStr || !timeZone) {
    return false;
  } else {
    const today = utcToZonedTime(new Date(), timeZone);
    const utcDate = utcToTimeZone(`${offerDateStr}.000Z`, timeZone);
    return today > utcDate;
  }
}

export const convertDate = (dateString, timeZone) => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    timeZone,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  };
  const formatter = new Intl.DateTimeFormat("en-US", options);
  const parts = formatter.formatToParts(date);
  const year = parts.find((part) => part.type === "year").value;
  const month = parts.find((part) => part.type === "month").value;
  const day = parts.find((part) => part.type === "day").value;
  const hour = parts.find((part) => part.type === "hour").value;
  const minute = parts.find((part) => part.type === "minute").value;
  const second = parts.find((part) => part.type === "second").value;
  return `${year}-${month}-${day}T${hour}:${minute}:${second}Z`;
};

export function isImage(url) {
  if (!url) {
    return false;
  }
  let extension = url.split(".").pop().toLowerCase();
  switch (extension) {
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
      return true;
    default:
      return false;
  }
}

declare let fbq: any;
declare let gtag: any;
declare global {
  interface Window {
    gtag: any;
  }
}

/* eslint-disable */

export function trackPageView(metapixelid, offerSlug, name) {
  try {
    if (
      typeof fbq === "function" &&
      fbq.queue &&
      typeof fbq.queue.push === "function"
    ) {
      fbq("track", "PageView");
      console.log(
        "%cMETA PageView tracked :%c" +
          metapixelid +
          " | " +
          offerSlug +
          " | " +
          name,
        "font-weight:bold",
        "font-weight:normal"
      );
    }
  } catch (error) {
    console.error("Error tracking PageView:", error);
  }
}

export function trackViewContent(
  content_ids,
  content_type,
  content_name,
  num_items,
  value,
  currency,
  item_brand,
  metapixelid,
  gtmid
) {
  try {
    if (
      typeof fbq === "function" &&
      fbq.queue &&
      typeof fbq.queue.push === "function"
    ) {
      fbq("track", "ViewContent", {
        content_ids: content_ids,
        content_type: content_type,
        content_name: content_name,
        num_items: num_items,
        value: value,
        currency: getUpdatedPrice(currency),
      });
      console.log(
        "%cMeta View Content:%c" +
          "metapixelid : " +
          metapixelid +
          " | " +
          "ID : " +
          content_ids +
          " | type : " +
          content_type +
          " | name: " +
          content_name +
          " | num_items: " +
          num_items +
          " | value : " +
          value +
          " | currency: " +
          currency,
        "font-weight:bold",
        "font-weight:normal"
      );
    } else {
      console.error("Error tracking view content");
    }
    if (typeof window !== "undefined" && window.gtag) {
      console.log(
        "%cGtmid View Item:%c" +
          "gtmid : " +
          gtmid +
          " | " +
          "ID : " +
          content_ids +
          " | type : " +
          content_type +
          " | name: " +
          content_name +
          " | num_items: " +
          num_items +
          " | value : " +
          value +
          " | currency: " +
          currency,
        "font-weight:bold",
        "font-weight:normal"
      );

      window.gtag("event", "view_item", {
        currency: currency,
        value: value,
        items: [
          {
            item_id: content_ids,
            item_name: content_name,
            item_brand,
            item_category: content_type,
            price: value,
            quantity: num_items,
          },
        ],
      });
    }
  } catch (error) {
    console.error("Error tracking view content:", error);
  }
}

export function trackInitiateCheckout(ticketInfo) {
  try {
    if (
      typeof fbq === "function" &&
      fbq.queue &&
      typeof fbq.queue.push === "function"
    ) {
      const content_ids = ticketInfo.map((ticket) => ticket.offerSlug);
      const content_names = ticketInfo.map((ticket) => ticket.eventName);
      const content_type = ticketInfo.map((ticket) => ticket.levelName);
      const content_category = ticketInfo.map((ticket) => ticket.type);
      const currency = ticketInfo[0].currency;
      const value = ticketInfo.reduce(
        (total, ticket) => total + parseFloat(ticket.totalPrice),
        0
      );
      const order_quantity = ticketInfo.map((ticket) => ticket.ticketsCount);

      fbq("track", "InitiateCheckout", {
        content_ids: content_ids,
        content_names: content_names,
        content_type: content_type,
        content_category: content_category,
        currency: getUpdatedPrice(currency),
        value: value,
        order_quantity: order_quantity,
      });

      console.log(
        "%cMeta Initiate Checkout:%c metapixelid : " +
          ticketInfo[0].metapixelid,
        "font-weight:bold",
        "font-weight:normal",
        {
          content_ids: content_ids,
          content_names: content_names,
          content_type: content_type,
          content_category: content_category,
          currency: getUpdatedPrice(currency),
          value: value,
          order_quantity: order_quantity,
        }
      );
    } else {
      console.error("Facebook Pixel (fbq) is not loaded");
    }

    if (typeof window !== "undefined" && window.gtag) {
      const items = ticketInfo.map((ticket) => ({
        item_id: ticket.offerSlug,
        item_name:
          ticket.levelName !== "-"
            ? ticket.eventName + "-" + ticket.levelName
            : ticket.eventName,
        item_brand: ticket.expertId,
        item_category: ticket.type,
        price: ticket?.price ? ticket?.price : parseFloat(ticket.totalPrice),
        quantity: ticket.ticketsCount,
      }));
      const value = ticketInfo.reduce(
        (total, ticket) => total + parseFloat(ticket.totalPrice),
        0
      );

      window.gtag("event", "begin_checkout", {
        currency: ticketInfo[0].currency,
        value: value,
        items: items,
      });

      console.log(
        "%cGTM Begin Checkout:%c gtmid : " + ticketInfo[0].gtmid,
        "font-weight:bold",
        "font-weight:normal",
        {
          currency: ticketInfo[0].currency,
          value: value,
          items: items,
        }
      );
    }
  } catch (error) {
    console.error("Error tracking initiate checkout:", error);
  }
}

export function trackPurchase(ticketInfo, transaction_id) {
  try {
    if (
      typeof fbq === "function" &&
      fbq.queue &&
      typeof fbq.queue.push === "function"
    ) {
      const content_ids = ticketInfo.map((ticket) => ticket.offerSlug);
      const content_names = ticketInfo.map((ticket) => ticket.eventName);
      const content_type = ticketInfo.map((ticket) => ticket.levelName);
      const num_items = ticketInfo.reduce(
        (total, ticket) => total + ticket.ticketsCount,
        0
      );
      const value = ticketInfo.reduce(
        (total, ticket) => total + parseFloat(ticket.totalPrice),
        0
      );
      const currency = ticketInfo[0].currency;

      fbq("track", "Purchase", {
        content_ids: content_ids,
        content_type: content_type,
        content_name: content_names,
        num_items: num_items,
        value: value,
        currency: getUpdatedPrice(currency),
      });

      console.log(
        "%cMeta Purchase:%c metapixelid : " + ticketInfo[0].metapixelid,
        "font-weight:bold",
        "font-weight:normal",
        ticketInfo
      );
    } else {
      console.error("Facebook Pixel (fbq) is not loaded");
    }

    if (typeof window !== "undefined" && window.gtag) {
      const value = ticketInfo.reduce(
        (total, ticket) => total + parseFloat(ticket.totalPrice),
        0
      );
      const items = ticketInfo.map((ticket) => ({
        item_id: ticket.offerSlug,
        item_name:
          ticket.levelName !== "-"
            ? ticket.eventName + "-" + ticket.levelName
            : ticket.eventName,
        item_brand: ticket.expertId,
        item_category: ticket.type,
        price: ticket?.price ? ticket?.price : parseFloat(ticket.totalPrice),
        quantity: ticket.ticketsCount,
      }));

      window.gtag("event", "purchase", {
        transaction_id: transaction_id,
        value: value,
        currency: ticketInfo[0].currency,
        items: items,
      });

      console.log(
        "%cGTM Purchase:%c gtmid : " + ticketInfo[0].gtmid,
        "font-weight:bold",
        "font-weight:normal",
        {
          transaction_id: transaction_id,
          currency: ticketInfo[0].currency,
          value: value,
          items: items,
        }
      );
    }
  } catch (error) {
    console.error("Error tracking purchase:", error);
  }
}

/* eslint-enable */

export function mapLocaleCode(localeCode) {
  const localeMap = {
    en: "en-US",
    ua: "uk-UA",
    ru: "ru-RU",
    es: "es-ES",
    cs: "cs-CZ",
    de: "de-DE",
    pt: "pt-PT",
    fr: "fr-FR",
    it: "it-IT",
    hi: "hi-IN",
    pl: "pl-PL",
  };

  return localeMap[localeCode] || localeCode;
}
const { format } = require("date-fns-tz");

export function isDateInPast(offerDateStr, timeZone) {
  if (!offerDateStr || !timeZone) {
    return false;
  }
  const today = utcToZonedTime(new Date(), timeZone);
  const utcDate = utcToTimeZone(`${offerDateStr.split("+")[0]}.000Z`, timeZone);

  return today > utcDate;
}
import ArsIcon from "components/Icons/Currency/ars";
import AudIcon from "components/Icons/Currency/aud";
import EurIcon from "components/Icons/Currency/eur";
import BgnIcon from "components/Icons/Currency/bgn";
import CadIcon from "components/Icons/Currency/cad";
import ClpIcon from "components/Icons/Currency/clp";
import CopIcon from "components/Icons/Currency/cop";
import HrkIcon from "components/Icons/Currency/hrk";
import CzkIcon from "components/Icons/Currency/czk";
import DkkIcon from "components/Icons/Currency/dkk";
import HkdIcon from "components/Icons/Currency/hkd";
import HufIcon from "components/Icons/Currency/huf";
import InrIcon from "components/Icons/Currency/inr";
import IdrIcon from "components/Icons/Currency/idr";
import KesIcon from "components/Icons/Currency/kes";
import MxnIcon from "components/Icons/Currency/mxn";
import NzdIcon from "components/Icons/Currency/nzd";
import NokIcon from "components/Icons/Currency/nok";
import PenIcon from "components/Icons/Currency/pen";
import PhpIcon from "components/Icons/Currency/php";
import PlnIcon from "components/Icons/Currency/pln";
import SdgIcon from "components/Icons/Currency/sdg";
import ZarIcon from "components/Icons/Currency/zar";
import SekIcon from "components/Icons/Currency/sek";
import TryIcon from "components/Icons/Currency/try";
import GbpIcon from "components/Icons/Currency/gbp";
import UahIcon from "components/Icons/Currency/uah";
import UsdIcon from "components/Icons/Currency/usd";
import BobIcon from "components/Icons/Currency/bob";
import CrcIcon from "components/Icons/Currency/crc";
import DopIcon from "components/Icons/Currency/dop";
import IlsIcon from "components/Icons/Currency/ils";
import PygIcon from "components/Icons/Currency/pyg";
import RonIcon from "components/Icons/Currency/ron";
import SarIcon from "components/Icons/Currency/sar";
import UyuIcon from "components/Icons/Currency/uyu";
import ChfIcon from "components/Icons/Currency/chf";
import ThbIcon from "components/Icons/Currency/thb";
import TtdIcon from "components/Icons/Currency/ttd";

async function fetchCurrencies() {
  try {
    if (typeof window === "undefined") {
      return [];
    }
    const response = await fetch("/api/offers/v1/currencies?bases=USD", {
      method: "GET",
      credentials: "include",
    });

    if (!response.ok) {
      console.warn("Response not OK. Status:", response.status);
    }

    const contentType = response.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
      const currencies = await response.json();
      return currencies;
    }
  } catch (error) {
    console.error("Error in fetchCurrencies:", error);
    throw error;
  }
}

export const countries = [
  {
    label: "Argentina",
    code: "AR",
    currency: "ARS",
    symbol: "ARS$",
    icon: ArsIcon,
    priceLimit: "priceLimitARS",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitARS",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 1879.31,
      MAX_PRICE: 9396554.46,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Australia",
    code: "AU",
    currency: "AUD",
    symbol: "A$",
    icon: AudIcon,
    priceLimit: "priceLimitAUD",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitAUD",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 3.01,
      MAX_PRICE: 15097.1,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Austria",
    code: "AT",
    currency: "EUR",
    symbol: "€",
    icon: EurIcon,
    priceLimit: "priceLimitEUR",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitEUR",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 1.81,
      MAX_PRICE: 9070,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Belgium",
    code: "BE",
    currency: "EUR",
    symbol: "€",
    icon: EurIcon,
    priceLimit: "priceLimitEUR",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitEUR",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 1.81,
      MAX_PRICE: 9070,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Bolivia",
    code: "BO",
    currency: "BOB",
    symbol: "Bs.",
    icon: BobIcon,
    priceLimit: "priceLimitBOB",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitBOB",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 13.81,
      MAX_PRICE: 69119.48,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Bulgaria",
    code: "BG",
    currency: "BGN",
    symbol: "лв",
    icon: BgnIcon,
    priceLimit: "priceLimitBGN",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitBGN",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 3.55,
      MAX_PRICE: 17750.81,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Canada",
    code: "CA",
    currency: "CAD",
    symbol: "CA$",
    icon: CadIcon,
    priceLimit: "priceLimitCAD",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitCAD",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 2.74,
      MAX_PRICE: 13718.98,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Chile",
    code: "CL",
    currency: "CLP",
    symbol: "CL$",
    icon: ClpIcon,
    priceLimit: "priceLimitCLP",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitCLP",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 1864.56,
      MAX_PRICE: 9321644.2,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Colombia",
    code: "CO",
    currency: "COP",
    symbol: "COL$",
    icon: CopIcon,
    priceLimit: "priceLimitCOP",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitCOP",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 8072.11,
      MAX_PRICE: 40360576.84,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Costa Rica",
    code: "CR",
    currency: "CRC",
    symbol: "₡",
    icon: CrcIcon,
    priceLimit: "priceLimitCRC",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitCRC",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 1055.02,
      MAX_PRICE: 5277582.56,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Croatia",
    code: "HR",
    currency: "HRK",
    symbol: "kn",
    icon: HrkIcon,
    priceLimit: "priceLimitHRK",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitHRK",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 13.68,
      MAX_PRICE: 68404.65,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Cyprus",
    code: "CY",
    currency: "EUR",
    symbol: "€",
    icon: EurIcon,
    priceLimit: "priceLimitEUR",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitEUR",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 1.81,
      MAX_PRICE: 9070,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Czech Republic",
    code: "CZ",
    currency: "CZK",
    symbol: "Kč",
    icon: CzkIcon,
    priceLimit: "priceLimitCZK",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitCZK",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 45.71,
      MAX_PRICE: 228558,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Denmark",
    code: "DK",
    currency: "DKK",
    symbol: "kr",
    icon: DkkIcon,
    priceLimit: "priceLimitDKK",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitDKK",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 13.54,
      MAX_PRICE: 67719,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Dominican Republic",
    code: "DO",
    currency: "DOP",
    symbol: "RD$",
    icon: DopIcon,
    priceLimit: "priceLimitDOP",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitDOP",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 119,
      MAX_PRICE: 596437,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Egypt",
    code: "EG",
    currency: "EGP",
    symbol: "EGP",
    icon: GbpIcon,
    priceLimit: "priceLimitEGP",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitEGP",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 98,
      MAX_PRICE: 491000,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Estonia",
    code: "EE",
    currency: "EUR",
    symbol: "€",
    icon: EurIcon,
    priceLimit: "priceLimitEUR",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitEUR",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 1.81,
      MAX_PRICE: 9070,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Finland",
    code: "FI",
    currency: "EUR",
    symbol: "€",
    icon: EurIcon,
    priceLimit: "priceLimitEUR",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitEUR",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 1.81,
      MAX_PRICE: 9070,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "France",
    code: "FR",
    currency: "EUR",
    symbol: "€",
    icon: EurIcon,
    priceLimit: "priceLimitEUR",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitEUR",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 1.81,
      MAX_PRICE: 9070,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Germany",
    code: "DE",
    currency: "EUR",
    symbol: "€",
    icon: EurIcon,
    priceLimit: "priceLimitEUR",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitEUR",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 1.81,
      MAX_PRICE: 9070,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Greece",
    code: "GR",
    currency: "EUR",
    symbol: "€",
    icon: EurIcon,
    priceLimit: "priceLimitEUR",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitEUR",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 1.81,
      MAX_PRICE: 9070,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Hong Kong SAR China",
    code: "HK",
    currency: "HKD",
    symbol: "HK$",
    icon: HkdIcon,
    priceLimit: "priceLimitHKD",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitHKD",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 15.58,
      MAX_PRICE: 77915,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Hungary",
    code: "HU",
    currency: "HUF",
    symbol: "Ft",
    icon: HufIcon,
    priceLimit: "priceLimitHUF",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitHUF",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 716,
      MAX_PRICE: 3579217,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Iceland",
    code: "IS",
    currency: "ISK",
    symbol: "kr",
    icon: SekIcon,
    priceLimit: "priceLimitISK",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitISK",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 276,
      MAX_PRICE: 1380800,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "India",
    code: "IN",
    currency: "INR",
    symbol: "₹",
    icon: InrIcon,
    priceLimit: "priceLimitINR",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitINR",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 167.89,
      MAX_PRICE: 839435,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Indonesia",
    code: "ID",
    currency: "IDR",
    symbol: "Rp",
    icon: IdrIcon,
    priceLimit: "priceLimitIDR",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitIDR",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 31264,
      MAX_PRICE: 156324742,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Ireland",
    code: "IE",
    currency: "EUR",
    symbol: "€",
    icon: EurIcon,
    priceLimit: "priceLimitEUR",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitEUR",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 1.81,
      MAX_PRICE: 9070,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Israel",
    code: "IL",
    currency: "ILS",
    symbol: "₪",
    icon: IlsIcon,
    priceLimit: "priceLimitILS",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitILS",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 7.46,
      MAX_PRICE: 37319,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Italy",
    code: "IT",
    currency: "EUR",
    symbol: "€",
    icon: EurIcon,
    priceLimit: "priceLimitEUR",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitEUR",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 1.81,
      MAX_PRICE: 9070,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Kenya",
    code: "KE",
    currency: "KES",
    symbol: "KSh",
    icon: KesIcon,
    priceLimit: "priceLimitKES",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitKES",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 258,
      MAX_PRICE: 1290000,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Latvia",
    code: "LV",
    currency: "EUR",
    symbol: "€",
    icon: EurIcon,
    priceLimit: "priceLimitEUR",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitEUR",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 1.81,
      MAX_PRICE: 9070,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Liechtenstein",
    code: "LI",
    currency: "CHF",
    symbol: "CHF",
    icon: ChfIcon,
    priceLimit: "priceLimitCHF",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitCHF",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 1.73,
      MAX_PRICE: 8647,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Lithuania",
    code: "LT",
    currency: "EUR",
    symbol: "€",
    icon: EurIcon,
    priceLimit: "priceLimitEUR",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitEUR",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 1.81,
      MAX_PRICE: 9070,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Luxembourg",
    code: "LU",
    currency: "EUR",
    symbol: "€",
    icon: EurIcon,
    priceLimit: "priceLimitEUR",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitEUR",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 1.81,
      MAX_PRICE: 9070,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Malta",
    code: "MT",
    currency: "EUR",
    symbol: "€",
    icon: EurIcon,
    priceLimit: "priceLimitEUR",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitEUR",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 1.81,
      MAX_PRICE: 9070,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Mexico",
    code: "MX",
    currency: "MXN",
    symbol: "MX$",
    icon: MxnIcon,
    priceLimit: "priceLimitMXN",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitMXN",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 37.92,
      MAX_PRICE: 189595,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Netherlands",
    code: "NL",
    currency: "EUR",
    symbol: "€",
    icon: EurIcon,
    priceLimit: "priceLimitEUR",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitEUR",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 1.81,
      MAX_PRICE: 9070,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "New Zealand",
    code: "NZ",
    currency: "NZD",
    symbol: "NZ$",
    icon: NzdIcon,
    priceLimit: "priceLimitNZD",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitNZD",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 3.33,
      MAX_PRICE: 16646,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Norway",
    code: "NO",
    currency: "NOK",
    symbol: "kr",
    icon: NokIcon,
    priceLimit: "priceLimitNOK",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitNOK",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 21.38,
      MAX_PRICE: 106903,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Paraguay",
    code: "PY",
    currency: "PYG",
    symbol: "₲",
    icon: PygIcon,
    priceLimit: "priceLimitPYG",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitPYG",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 15132,
      MAX_PRICE: 75664199,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Peru",
    code: "PE",
    currency: "PEN",
    symbol: "S/.",
    icon: PenIcon,
    priceLimit: "priceLimitPEN",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitPEN",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 7.49,
      MAX_PRICE: 37451,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Philippines",
    code: "PH",
    currency: "PHP",
    symbol: "₱",
    icon: PhpIcon,
    priceLimit: "priceLimitPHP",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitPHP",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 113.98,
      MAX_PRICE: 569898,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Poland",
    code: "PL",
    currency: "PLN",
    symbol: "zł",
    icon: PlnIcon,
    priceLimit: "priceLimitPLN",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitPLN",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 7.8,
      MAX_PRICE: 39008.41,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Portugal",
    code: "PT",
    currency: "EUR",
    symbol: "€",
    icon: EurIcon,
    priceLimit: "priceLimitEUR",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitEUR",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 1.81,
      MAX_PRICE: 9070,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Romania",
    code: "RO",
    currency: "RON",
    symbol: "lei",
    icon: RonIcon,
    priceLimit: "priceLimitRON",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitRON",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 9.04,
      MAX_PRICE: 45196.94,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Saudi Arabia",
    code: "SA",
    currency: "SAR",
    symbol: "SAR",
    icon: SarIcon,
    priceLimit: "priceLimitSAR",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitSAR",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 7.51,
      MAX_PRICE: 37532.04,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Singapore",
    code: "SG",
    currency: "SGD",
    symbol: "SGD$",
    icon: SdgIcon,
    priceLimit: "priceLimitSGD",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitSGD",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 2.63,
      MAX_PRICE: 13146.69,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Slovakia",
    code: "SK",
    currency: "EUR",
    symbol: "€",
    icon: EurIcon,
    priceLimit: "priceLimitEUR",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitEUR",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 1.81,
      MAX_PRICE: 9070,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Slovenia",
    code: "SI",
    currency: "EUR",
    symbol: "€",
    icon: EurIcon,
    priceLimit: "priceLimitEUR",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitEUR",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 1.81,
      MAX_PRICE: 9070,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "South Africa",
    code: "ZA",
    currency: "ZAR",
    symbol: "R",
    icon: ZarIcon,
    priceLimit: "priceLimitZAR",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitZAR",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 36.11,
      MAX_PRICE: 180573.86,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Spain",
    code: "ES",
    currency: "EUR",
    symbol: "€",
    icon: EurIcon,
    priceLimit: "priceLimitEUR",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitEUR",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 1.81,
      MAX_PRICE: 9070,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Sweden",
    code: "SE",
    currency: "SEK",
    symbol: "kr",
    icon: SekIcon,
    priceLimit: "priceLimitSEK",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitSEK",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 20.82,
      MAX_PRICE: 104092.39,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Switzerland",
    code: "CH",
    currency: "CHF",
    symbol: "CHF",
    icon: ChfIcon,
    priceLimit: "priceLimitCHF",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitCHF",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 1.73,
      MAX_PRICE: 8635.38,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Thailand",
    code: "TH",
    currency: "THB",
    symbol: "฿",
    icon: ThbIcon,
    priceLimit: "priceLimitTHB",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitTHB",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 69.82,
      MAX_PRICE: 349109.6,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Trinidad & Tobago",
    code: "TT",
    currency: "TTD",
    symbol: "TT$",
    icon: TtdIcon,
    priceLimit: "priceLimitTTD",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitTTD",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 13.58,
      MAX_PRICE: 67917.76,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Turkey",
    code: "TR",
    currency: "TRY",
    symbol: "₺",
    icon: TryIcon,
    priceLimit: "priceLimitTRY",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitTRY",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 67.06,
      MAX_PRICE: 335289.19,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "United Kingdom",
    code: "GB",
    currency: "GBP",
    symbol: "£",
    icon: GbpIcon,
    priceLimit: "priceLimitGBP",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitGBP",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 1.56,
      MAX_PRICE: 7779.95,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "United States",
    code: "US",
    currency: "USD",
    symbol: "$",
    icon: UsdIcon,
    priceLimit: "priceLimit",
    tipOrCommunityPriceLimit: "tipPriceLimit",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 2,
      MAX_PRICE: 10000,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Ukraine",
    code: "UA",
    currency: "UAH",
    symbol: "₴",
    icon: UahIcon,
    priceLimit: "priceLimitForUA",
    tipOrCommunityPriceLimit: "tipPriceLimitForUA",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 83,
      MAX_PRICE: 412496,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
  {
    label: "Uruguay",
    code: "UY",
    currency: "UYU",
    symbol: "$U",
    icon: UyuIcon,
    priceLimit: "priceLimitUYU",
    tipOrCommunityPriceLimit: "tipOrCommunitypriceLimitUYU",
    offerPrices: {
      FREE_PRICE: 0,
      MIN_PRICE: 81,
      MAX_PRICE: 402925,
      VALIDATION_CHARS: /^[.,0-9]+$/,
    },
  },
];

fetchCurrencies()
  .then((data) => {
    if (data && data[0].currencies) {
      countries.forEach((country) => {
        const currencyCode = country.currency;
        const currencyInfo = data[0].currencies[currencyCode];
        if (currencyInfo) {
          const rate = currencyInfo.value;
          country.offerPrices.MIN_PRICE = +(2 * rate).toFixed(2);
          country.offerPrices.MAX_PRICE = +(10000 * rate).toFixed(2);
        }
      });
    } else {
      console.error("Currencies data is not available.");
    }
  })
  .catch((error) => {
    console.error("Failed to fetch currencies:", error);
  });

export const filteredCountries = countries
  .map((country) => {
    if (
      [
        "AUD",
        "EUR",
        "BGN",
        "CAD",
        "CZK",
        "DKK",
        "HKD",
        "HUF",
        "INR",
        "CHF",
        "MXN",
        "NZD",
        "NOK",
        "PLN",
        "RON",
        "SGD",
        "SEK",
        "THB",
        "GBP",
        "USD",
        "UAH",
      ].includes(country.currency)
    ) {
      return country;
    } else {
      return {
        ...country,
        currency: "USD",
        symbol: "$",
        icon: UsdIcon,
        priceLimit: "priceLimit",
        tipOrCommunityPriceLimit: "tipPriceLimit",
        offerPrices: {
          FREE_PRICE: 0,
          MIN_PRICE: 2,
          MAX_PRICE: 10000,
          VALIDATION_CHARS: /^[.,0-9]+$/,
        },
      };
    }
  })
  .filter((country) => country !== null);

// export const filteredCountriesWithoutUA = filteredCountries.filter(
//   (country) => country.currency !== "UAH"
// );
export const filteredCountriesWithoutUA = filteredCountries.filter(
  (country) => country.currency
);

export function findCountryByCode(countryCode) {
  return filteredCountries.find((country) => country.currency === countryCode);
}

export const getUniqueCurrencyCodes = (countries) => {
  const uniqueCurrencies = {};
  const result = [];

  for (const country of countries) {
    if (!uniqueCurrencies[country.currency]) {
      uniqueCurrencies[country.currency] = true;
      result.push(country.currency);
    }
  }

  return result.sort();
};

export function getTimeFormat(country: string | null): string {
  const twelveHourFormatCountries = [
    "US",
    "CA",
    "AU",
    "IN",
    "EG",
    "SA",
    "CO",
    "PK",
    "PH",
  ];

  if (country === null) {
    return "24h";
  } else if (twelveHourFormatCountries.includes(country)) {
    return "12h";
  } else {
    return "24h";
  }
}
export const findCountryByLabel = (code, countries) => {
  for (const country of countries) {
    if (country.code === code) {
      return country.label;
    }
  }
  return null;
};

export function getUserLanguage(timeZone, acceptLanguageHeader) {
  const UKRAINE = UKRAINIAN_TIMEZONES.includes(timeZone);
  const SPANISH = SPANISH_TIMEZONES.includes(timeZone);
  const CZECH = CZECH_TIMEZONES.includes(timeZone);
  const GERMAN = GERMAN_TIMEZONES.includes(timeZone);
  const PORTUGUESE = PORTUGUESE_TIMEZONES.includes(timeZone);
  const ITALIAN = ITALIAN_TIMEZONES.includes(timeZone);
  const HINDI = HINDI_TIMEZONES.includes(timeZone);
  const FRENCH = FRENCH_TIMEZONES.includes(timeZone);
  const POLISH = POLISH_TIMEZONES.includes(timeZone);
  let userLanguage;
  const regex = /^([a-z]{2})/i;

  const getLanguageCode = (header) => {
    if (typeof header === "object" && header.acceptLanguageHeader) {
      header = header.acceptLanguageHeader;
    }
    const match = header.match(regex);
    return match ? match[1] : null;
  };
  switch (getLanguageCode(acceptLanguageHeader)) {
    case "uk":
      userLanguage = "ua";
      break;
    case "es":
      userLanguage = "es";
      break;
    case "cs":
      userLanguage = "cs";
      break;
    case "de":
      userLanguage = "de";
      break;
    case "pt":
      userLanguage = "pt";
      break;
    case "it":
      userLanguage = "it";
      break;
    case "hi":
      userLanguage = "hi";
      break;
    case "fr":
      userLanguage = "fr";
      break;
    case "pl":
      userLanguage = "pl";
      break;
    case "en":
      userLanguage = "en";
      break;
    default:
      userLanguage = false;
  }
  if (userLanguage) {
    return userLanguage;
  } else {
    switch (true) {
      case UKRAINE:
        return "ua";
      case SPANISH:
        return "es";
      case CZECH:
        return "cs";
      case GERMAN:
        return "de";
      case PORTUGUESE:
        return "pt";
      case ITALIAN:
        return "it";
      case HINDI:
        return "hi";
      case FRENCH:
        return "fr";
      case POLISH:
        return "pl";
      default:
        return "en";
    }
  }
}

export function getLanguageName(Language) {
  let userLanguage;
  switch (Language) {
    case "uk":
      userLanguage = "Українська";
      break;
    case "ua":
      userLanguage = "Українська";
      break;
    case "es":
      userLanguage = "Español";
      break;
    case "cs":
      userLanguage = "Čeština";
      break;
    case "de":
      userLanguage = "Deutsch";
      break;
    case "pt":
      userLanguage = "Português";
      break;
    case "it":
      userLanguage = "Italiano";
      break;
    case "hi":
      userLanguage = "हिन्दी";
      break;
    case "fr":
      userLanguage = "Français";
      break;
    case "pl":
      userLanguage = "Polski";
      break;
    case "en":
      userLanguage = "English";
      break;
    case "ru":
      userLanguage = "Русский";
      break;
    default:
      userLanguage = "English";
  }

  return userLanguage;
}

export const cloudfront =
  process.env.NEXT_PUBLIC_ENVIRONMENT === "production"
    ? "https://d1kvnmzll4ky5b.cloudfront.net/"
    : "https://d29uj4pwpnn2zw.cloudfront.net/";

export const values = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 21, 28, 30, 60, 90, 120,
  150, 180, 210, 240, 270, 300, 330, 365,
];

export function formatDateWithTimeZone(daysToAdd, timeZone, timeFormat) {
  const nowUtc = new Date();
  // const newDateUtc = addDays(nowUtc, daysToAdd);
  const newDateInYourTimeZone = utcToZonedTime(nowUtc, timeZone);
  const formatString =
    timeFormat === "24" ? "d MMMM, yyyy H:mm" : "d MMMM, yyyy h:mm a";
  const formattedDate = format(newDateInYourTimeZone, formatString, {
    locale: getLocale(),
    timeZone,
  });

  return formattedDate;
}

export function DateWithTimeZoneAddDays(daysToAdd, timeZone, timeFormat) {
  const nowUtc = new Date();
  const newDateUtc = addDays(nowUtc, daysToAdd);
  const newDateInYourTimeZone = utcToZonedTime(newDateUtc, timeZone);
  const formatString = "d MMMM, yyyy";
  // timeFormat === "24" ? "d MMMM, yyyy H:mm" : "d MMMM, yyyy h:mm a";
  const formattedDate = format(newDateInYourTimeZone, formatString, {
    locale: getLocale(),
    timeZone,
  });

  return formattedDate;
}
export const domains = [
  { country: "Afghanistan", tlds: [".af"] },
  { country: "Åland", tlds: [".ax"] },
  { country: "Albania", tlds: [".al"] },
  { country: "Algeria", tlds: [".dz"] },
  { country: "American Samoa", tlds: [".as"] },
  { country: "Andorra", tlds: [".ad"] },
  { country: "Angola", tlds: [".ao"] },
  { country: "Anguilla", tlds: [".ai"] },
  { country: "Antarctica", tlds: [".aq"] },
  { country: "Antigua and Barbuda", tlds: [".ag"] },
  { country: "Argentina", tlds: [".ar"] },
  { country: "Armenia", tlds: [".am"] },
  { country: "Aruba", tlds: [".aw"] },
  { country: "Ascension Island", tlds: [".ac"] },
  { country: "Australia", tlds: [".au"] },
  { country: "Austria", tlds: [".at"] },
  { country: "Azerbaijan", tlds: [".az"] },
  { country: "Bahamas", tlds: [".bs"] },
  { country: "Bahrain", tlds: [".bh"] },
  { country: "Bangladesh", tlds: [".bd"] },
  { country: "Barbados", tlds: [".bb"] },
  { country: "Basque Country", tlds: [".eus"] },
  { country: "Belarus", tlds: [".by"] },
  { country: "Belgium", tlds: [".be"] },
  { country: "Belize", tlds: [".bz"] },
  { country: "Benin", tlds: [".bj"] },
  { country: "Bermuda", tlds: [".bm"] },
  { country: "Bhutan", tlds: [".bt"] },
  { country: "Bolivia", tlds: [".bo"] },
  { country: "Bonaire", tlds: [".bq", ".an", ".nl"] },
  { country: "Bosnia and Herzegovina", tlds: [".ba"] },
  { country: "Botswana", tlds: [".bw"] },
  { country: "Bouvet Island", tlds: [".bv"] },
  { country: "Brazil", tlds: [".br"] },
  { country: "British Indian Ocean Territory", tlds: [".io"] },
  { country: "British Virgin Islands", tlds: [".vg"] },
  { country: "Brunei", tlds: [".bn"] },
  { country: "Bulgaria", tlds: [".bg"] },
  { country: "Burkina Faso", tlds: [".bf"] },
  { country: "Burma (officially: Myanmar)", tlds: [".mm"] },
  { country: "Burundi", tlds: [".bi"] },
  { country: "Cambodia", tlds: [".kh"] },
  { country: "Cameroon", tlds: [".cm"] },
  { country: "Canada", tlds: [".ca"] },
  { country: "Cape Verde (in Portuguese: Cabo Verde)", tlds: [".cv"] },
  { country: "Catalonia", tlds: [".cat"] },
  { country: "Cayman Islands", tlds: [".ky"] },
  { country: "Central African Republic", tlds: [".cf"] },
  { country: "Chad", tlds: [".td"] },
  { country: "Chile", tlds: [".cl"] },
  { country: "China, People’s Republic of", tlds: [".cn"] },
  { country: "Christmas Island", tlds: [".cx"] },
  { country: "Cocos (Keeling) Islands", tlds: [".cc"] },
  { country: "Colombia", tlds: [".co"] },
  { country: "Comoros", tlds: [".km"] },
  {
    country: "Congo, Democratic Republic of the (Congo-Kinshasa)",
    tlds: [".cd"],
  },
  { country: "Congo, Republic of the (Congo-Brazzaville)", tlds: [".cg"] },
  { country: "Cook Islands", tlds: [".ck"] },
  { country: "Costa Rica", tlds: [".cr"] },
  { country: "Côte d’Ivoire (Ivory Coast)", tlds: [".ci"] },
  { country: "Croatia", tlds: [".hr"] },
  { country: "Cuba", tlds: [".cu"] },
  { country: "Curaçao", tlds: [".cw"] },
  { country: "Cyprus", tlds: [".cy"] },
  { country: "Czechia (Czech Republic)", tlds: [".cz"] },
  { country: "Denmark", tlds: [".dk"] },
  { country: "Djibouti", tlds: [".dj"] },
  { country: "Dominica", tlds: [".dm"] },
  { country: "Dominican Republic", tlds: [".do"] },
  { country: "East Timor (Timor-Leste)", tlds: [".tl", ".tp"] },
  { country: "Ecuador", tlds: [".ec"] },
  { country: "Egypt", tlds: [".eg"] },
  { country: "El Salvador", tlds: [".sv"] },
  { country: "Equatorial Guinea", tlds: [".gq"] },
  { country: "Eritrea", tlds: [".er"] },
  { country: "Estonia", tlds: [".ee"] },
  { country: "Ethiopia", tlds: [".et"] },
  { country: "European Union", tlds: [".eu"] },
  { country: "Falkland Islands", tlds: [".fk"] },
  { country: "Faeroe Islands", tlds: [".fo"] },
  { country: "Federated States of Micronesia", tlds: [".fm"] },
  { country: "Fiji", tlds: [".fj"] },
  { country: "Finland", tlds: [".fi"] },
  { country: "France", tlds: [".fr"] },
  { country: "French Guiana", tlds: [".gf"] },
  { country: "French Polynesia", tlds: [".pf"] },
  { country: "French Southern and Antarctic Lands", tlds: [".tf"] },
  { country: "Gabon (officially: Gabonese Republic)", tlds: [".ga"] },
  { country: "Galicia", tlds: [".gal"] },
  { country: "Gambia", tlds: [".gm"] },
  { country: "Gaza Strip (Gaza)", tlds: [".ps"] },
  { country: "Georgia", tlds: [".ge"] },
  { country: "Germany", tlds: [".de"] },
  { country: "Ghana", tlds: [".gh"] },
  { country: "Gibraltar", tlds: [".gi"] },
  { country: "Greece", tlds: [".gr"] },
  { country: "Greenland", tlds: [".gl"] },
  { country: "Grenada", tlds: [".gd"] },
  { country: "Guadeloupe", tlds: [".gp"] },
  { country: "Guam", tlds: [".gu"] },
  { country: "Guatemala", tlds: [".gt"] },
  { country: "Guernsey", tlds: [".gg"] },
  { country: "Guinea", tlds: [".gn"] },
  { country: "Guinea-Bissau", tlds: [".gw"] },
  { country: "Guyana", tlds: [".gy"] },
  { country: "Haiti", tlds: [".ht"] },
  { country: "Heard Island and McDonald Islands", tlds: [".hm"] },
  { country: "Honduras", tlds: [".hn"] },
  { country: "Hong Kong", tlds: [".hk"] },
  { country: "Hungary", tlds: [".hu"] },
  { country: "Iceland", tlds: [".is"] },
  { country: "India", tlds: [".in"] },
  { country: "Indonesia", tlds: [".id"] },
  { country: "Iran", tlds: [".ir"] },
  { country: "Iraq", tlds: [".iq"] },
  { country: "Ireland", tlds: [".ie"] },
  { country: "Isle of Man", tlds: [".im"] },
  { country: "Israel", tlds: [".il"] },
  { country: "Italy", tlds: [".it"] },
  { country: "Jamaica", tlds: [".jm"] },
  { country: "Japan", tlds: [".jp"] },
  { country: "Jersey", tlds: [".je"] },
  { country: "Jordan", tlds: [".jo"] },
  { country: "Kazakhstan", tlds: [".kz"] },
  { country: "Kenya", tlds: [".ke"] },
  { country: "Kiribati", tlds: [".ki"] },
  { country: "Kuwait", tlds: [".kw"] },
  { country: "Kyrgyzstan", tlds: [".kg"] },
  { country: "Laos", tlds: [".la"] },
  { country: "Latvia", tlds: [".lv"] },
  { country: "Lebanon", tlds: [".lb"] },
  { country: "Lesotho", tlds: [".ls"] },
  { country: "Liberia", tlds: [".lr"] },
  { country: "Libya", tlds: [".ly"] },
  { country: "Liechtenstein", tlds: [".li"] },
  { country: "Lithuania", tlds: [".lt"] },
  { country: "Luxembourg", tlds: [".lu"] },
  { country: "Macau", tlds: [".mo"] },
  {
    country:
      "Macedonia, Republic of (the former Yugoslav Republic of Macedonia, FYROM)",
    tlds: [".mk"],
  },
  { country: "Madagascar", tlds: [".mg"] },
  { country: "Malawi", tlds: [".mw"] },
  { country: "Malaysia", tlds: [".my"] },
  { country: "Maldives", tlds: [".mv"] },
  { country: "Mali", tlds: [".ml"] },
  { country: "Malta", tlds: [".mt"] },
  { country: "Marshall Islands", tlds: [".mh"] },
  { country: "Martinique", tlds: [".mq"] },
  { country: "Mauritania", tlds: [".mr"] },
  { country: "Mauritius", tlds: [".mu"] },
  { country: "Mayotte", tlds: [".yt"] },
  { country: "Mexico", tlds: [".mx"] },
  { country: "Moldova", tlds: [".md"] },
  { country: "Monaco", tlds: [".mc"] },
  { country: "Mongolia", tlds: [".mn"] },
  { country: "Montenegro", tlds: [".me"] },
  { country: "Montserrat", tlds: [".ms"] },
  { country: "Morocco", tlds: [".ma"] },
  { country: "Mozambique", tlds: [".mz"] },
  { country: "Myanmar", tlds: [".mm"] },
  { country: "Namibia", tlds: [".na"] },
  { country: "Nauru", tlds: [".nr"] },
  { country: "Nepal", tlds: [".np"] },
  { country: "Netherlands", tlds: [".nl"] },
  { country: "New Caledonia", tlds: [".nc"] },
  { country: "New Zealand", tlds: [".nz"] },
  { country: "Nicaragua", tlds: [".ni"] },
  { country: "Niger", tlds: [".ne"] },
  { country: "Nigeria", tlds: [".ng"] },
  { country: "Niue", tlds: [".nu"] },
  { country: "Norfolk Island", tlds: [".nf"] },
  {
    country: "North Cyprus (unrecognised, self-declared state)",
    tlds: [".nc", ".tr"],
  },
  { country: "North Korea", tlds: [".kp"] },
  { country: "Northern Mariana Islands", tlds: [".mp"] },
  { country: "Norway", tlds: [".no"] },
  { country: "Oman", tlds: [".om"] },
  { country: "Pakistan", tlds: [".pk"] },
  { country: "Palau", tlds: [".pw"] },
  { country: "Palestine", tlds: [".ps"] },
  { country: "Panama", tlds: [".pa"] },
  { country: "Papua New Guinea", tlds: [".pg"] },
  { country: "Paraguay", tlds: [".py"] },
  { country: "Peru", tlds: [".pe"] },
  { country: "Philippines", tlds: [".ph"] },
  { country: "Pitcairn Islands", tlds: [".pn"] },
  { country: "Poland", tlds: [".pl"] },
  { country: "Portugal", tlds: [".pt"] },
  { country: "Puerto Rico", tlds: [".pr"] },
  { country: "Qatar", tlds: [".qa"] },
  { country: "Romania", tlds: [".ro"] },
  { country: "Russia", tlds: [".ru"] },
  { country: "Rwanda", tlds: [".rw"] },
  { country: "Réunion Island", tlds: [".re"] },
  { country: "Saba", tlds: [".bq", ".an"] },
  {
    country:
      "Saint Barthélemy (informally also referred to as Saint Barth’s or Saint Barts)",
    tlds: [".bl", ".gp", ".fr"],
  },
  { country: "Saint Helena", tlds: [".sh"] },
  { country: "Saint Kitts and Nevis", tlds: [".kn"] },
  { country: "Saint Lucia", tlds: [".lc"] },
  {
    country: "Saint Martin (officially the Collectivity of Saint Martin)",
    tlds: [".mf", ".gp", ".fr"],
  },
  { country: "Saint-Pierre and Miquelon", tlds: [".pm"] },
  { country: "Saint Vincent and the Grenadines", tlds: [".vc"] },
  { country: "Samoa", tlds: [".ws"] },
  { country: "San Marino", tlds: [".sm"] },
  { country: "São Tomé and Príncipe", tlds: [".st"] },
  { country: "Saudi Arabia", tlds: [".sa"] },
  { country: "Senegal", tlds: [".sn"] },
  { country: "Serbia", tlds: [".rs"] },
  { country: "Seychelles", tlds: [".sc"] },
  { country: "Sierra Leone", tlds: [".sl"] },
  { country: "Singapore", tlds: [".sg"] },
  { country: "Sint Eustatius", tlds: [".bq", ".an", ".nl"] },
  { country: "Sint Maarten", tlds: [".sx", ".an"] },
  { country: "Slovakia", tlds: [".sk"] },
  { country: "Slovenia", tlds: [".si"] },
  { country: "Solomon Islands", tlds: [".sb"] },
  { country: "Somalia", tlds: [".so"] },
  { country: "Somaliland", tlds: [".so"] },
  { country: "South Africa", tlds: [".za"] },
  { country: "South Georgia and the South Sandwich Islands", tlds: [".gs"] },
  { country: "South Korea", tlds: [".kr"] },
  { country: "South Sudan", tlds: [".ss"] },
  { country: "Spain", tlds: [".es"] },
  { country: "Sri Lanka", tlds: [".lk"] },
  { country: "Sudan", tlds: [".sd"] },
  { country: "Suriname", tlds: [".sr"] },
  { country: "Svalbard and Jan Mayen Islands", tlds: [".sj"] },
  { country: "Swaziland", tlds: [".sz"] },
  { country: "Sweden", tlds: [".se"] },
  { country: "Switzerland", tlds: [".ch"] },
  { country: "Syria", tlds: [".sy"] },
  { country: "Taiwan", tlds: [".tw"] },
  { country: "Tajikistan", tlds: [".tj"] },
  { country: "Tanzania", tlds: [".tz"] },
  { country: "Thailand", tlds: [".th"] },
  { country: "Togo", tlds: [".tg"] },
  { country: "Tokelau", tlds: [".tk"] },
  { country: "Tonga", tlds: [".to"] },
  { country: "Trinidad & Tobago", tlds: [".tt"] },
  { country: "Tunisia", tlds: [".tn"] },
  { country: "Turkey", tlds: [".tr"] },
  { country: "Turkmenistan", tlds: [".tm"] },
  { country: "Turks and Caicos Islands", tlds: [".tc"] },
  { country: "Tuvalu", tlds: [".tv"] },
  { country: "Uganda", tlds: [".ug"] },
  { country: "Ukraine", tlds: [".ua"] },
  { country: "United Arab Emirates (UAE)", tlds: [".ae"] },
  { country: "United Kingdom (UK)", tlds: [".uk"] },
  { country: "United States of America (USA)", tlds: [".us"] },
  { country: "United States Virgin Islands", tlds: [".vi"] },
  { country: "Uruguay", tlds: [".uy"] },
  { country: "Uzbekistan", tlds: [".uz"] },
  { country: "Vanuatu", tlds: [".vu"] },
  { country: "Vatican City", tlds: [".va"] },
  { country: "Venezuela", tlds: [".ve"] },
  { country: "Vietnam", tlds: [".vn"] },
  { country: "Wallis and Futuna", tlds: [".wf"] },
  { country: "Western Sahara", tlds: [".eh", ".ma"] },
  { country: "Yemen", tlds: [".ye"] },
  { country: "Zambia", tlds: [".zm"] },
  { country: "Zimbabwe", tlds: [".zw"] },
];

export const gradients = [
  {
    id: 1,
    gradient: "linear-gradient(90deg, #A855F7 0%, #EC4899 50%, #EAB308 100%)",
  },
  {
    id: 2,
    gradient: "linear-gradient(90deg, #6366F1 0%, #06B6D4 50%, #EC4899 100%)",
  },
  {
    id: 3,
    gradient: "linear-gradient(90deg, #F43F5E 0%, #F59E0B 50%, #10B981 100%)",
  },
  {
    id: 4,
    gradient: "linear-gradient(90deg, #14B8A6 0%, #06B6D4 50%, #6366F1 100%)",
  },
  {
    id: 5,
    gradient: "linear-gradient(90deg, #22C55E 0%, #84CC16 50%, #EAB308 100%)",
  },
  {
    id: 6,
    gradient: "linear-gradient(90deg, #EF4444 0%, #F97316 50%, #F59E0B 100%)",
  },
  {
    id: 7,
    gradient: "linear-gradient(90deg, #0EA5E9 0%, #14B8A6 50%, #10B981 100%)",
  },
  {
    id: 8,
    gradient: "linear-gradient(90deg, #D946EF 0%, #D946EF 50%, #6366F1 100%)",
  },
  {
    id: 9,
    gradient: "linear-gradient(90deg, #EC4899 0%, #8B5CF6 50%, #3B82F6 100%)",
  },
  {
    id: 10,
    gradient: "linear-gradient(90deg, #6B7280 0%, #3B82F6 50%, #06B6D4 100%)",
  },
  {
    id: 11,
    gradient: "linear-gradient(90deg, #64748B 0%, #F43F5E 50%, #F59E0B 100%)",
  },
  {
    id: 12,
    gradient: "linear-gradient(90deg, #3B82F6 0%, #22C55E 50%, #EF4444 100%)",
  },
];

export function getSubscriptionEndDate(
  daysLeft: number,
  timeZone: string
): string {
  const startDate = new Date();
  const zonedStartDate = utcToZonedTime(startDate, timeZone);
  const endDate = addDays(zonedStartDate, daysLeft);
  const zonedDate = utcToZonedTime(endDate, timeZone);
  const date = format(zonedDate, "MMMM d, yyyy", {
    locale: getLocale(),
  });

  return date.charAt(0).toUpperCase() + date.slice(1);
}

interface Transaction {
  payoutamount: number;
  offerType: string;
}

export function calculateOfferPercentages(transactions: Transaction[]) {
  const typeOrder = [
    "Communities",
    "Products",
    "Events",
    "Appointments",
    "Tips",
  ];
  const typeTotals: Record<string, number> = {};

  transactions.forEach((transaction) => {
    const { offerType, payoutamount } = transaction;

    const normalizedType =
      offerType === "Community"
        ? "Communities"
        : offerType.endsWith("s")
        ? offerType
        : `${offerType}s`;

    if (!typeTotals[normalizedType]) {
      typeTotals[normalizedType] = 0;
    }
    typeTotals[normalizedType] += payoutamount;
  });

  const totalAmount = Object.values(typeTotals).reduce(
    (sum, value) => sum + value,
    0
  );

  const percentages = typeOrder
    .filter((offerType) => typeTotals[offerType] > 0)
    .map((offerType) => {
      const amount = typeTotals[offerType];
      return {
        offerType,
        percentage:
          totalAmount > 0 ? ((amount / totalAmount) * 100).toFixed(2) : "0.00",
        total: amount,
      };
    });

  return { percentages, totalAmount };
}

export function formatDateWithTimezone(
  dateString: string,
  timeZone: string
): string {
  const zonedDate = utcToZonedTime(dateString, timeZone);
  const formattedDate = format(zonedDate, "MMMM dd, yyyy", {
    locale: getLocale(),
  });

  return formattedDate;
}
