import { Fragment, useState, useEffect } from "react";
import {
  DeviceMobileIcon,
  HeartIcon,
  LocationMarkerIcon,
  MailIcon,
  XIcon,
} from "@heroicons/react/solid";
import {
  TrendingUpIcon,
  ArrowNarrowRightIcon,
  ChevronRightIcon,
  CollectionIcon,
  LightningBoltIcon,
  FireIcon,
  DownloadIcon,
  TicketIcon,
  ShoppingBagIcon,
  VideoCameraIcon,
  ThumbUpIcon,
  CalendarIcon,
  CheckIcon,
} from "@heroicons/react/outline";
import { Dialog, Transition } from "@headlessui/react";
import Image from "next/image";
import Head from "next/head";
import cx from "classnames";
import { getFullName, findCountryByCode } from "utils";
import Accordion from "components/Accordion/Accordion";
import LoggedPublicNav from "components/LoggedPublicNav/LoggedPublicNav";
import Link from "next/link";
import ClientNav from "components/ClientNav/ClientNav";
import React, { useRef } from "react";
import useDraggableScroll from "use-draggable-scroll";
import Script from "next/script";
import StepRangeSlider from "libs/react-step-range-slider/StepRangeSlider";
import { useRouter } from "next/router";
import useLocalStorage from "hooks/useLocalStorage";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import Loader from "components/Loader/Loader";
// import { useQuery } from "@tanstack/react-query";
// import { api } from "utils/api";
const timeRange = [
  { value: 1, step: 1 }, // acts as min value
  { value: 10, step: 5 },
  { value: 100, step: 100 },
  { value: 1000 }, // acts as max value
];
const currencyRange = [
  { value: 5, step: 5 }, // acts as min value
  { value: 100, step: 10 },
  { value: 200, step: 50 },
  { value: 500, step: 100 },
  { value: 1000 }, // acts as max value
];

export const BusinessPage = ({
  user,
  acceptLanguageHeader,
  lang,
  subscriptionOffer,
}) => {
  const [showPopupVideo, setShowPopupVideo] = useState(false);
  const [clientNumber, setClientNumber] = useState(0);
  const [chargeVideo, setChargeVideo] = useState(0);
  const [monthlyIncome, setMonthlyIncome] = useState(0);
  const isLogged = user?.id;
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(0);
  const [isMonthlyBilling, setIsMonthlyBilling] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [isProductOpen, setIsProductOpen] = useState(false);
  const [currentPlanPrices, setCurrentPlanPrices] = useState({
    subscription_plans: [
      {
        id: "792ab243-5041-4aa7-b433-c74e55d0ff15",
        name: "free",
        price: 0,
        duration: 0,
        type: "cashback",
        currency: "usd",
        comission: 5.0,
      },
      {
        id: "4eebae8e-4176-403b-9535-91a4ec2d413a",
        name: "basic",
        price: 14.99,
        duration: 30,
        type: "cashback",
        currency: "usd",
        original_price: 14.99,
        waylight_discount: 0.0,
        arp_discount: 0.0,
      },
      {
        id: "d16a1cb1-7db0-4b53-9c03-e3c404d649d7",
        name: "pro",
        price: 99,
        duration: 30,
        type: "cashback",
        currency: "usd",
        original_price: 99.0,
        waylight_discount: 0.0,
        arp_discount: 0.0,
      },
      {
        id: "69b12ba8-2196-4d6b-8450-c5fab68072b0",
        name: "pro",
        price: 59.9,
        duration: 365,
        type: "cashback",
        currency: "usd",
        original_price: 99.0,
        waylight_discount: 39.5,
        arp_discount: 0.0,
      },
    ],
    type: "cashback",
  });
  const [isLoadingPrice, setLoadingPrice] = useState(true);
  const { t } = useTranslation();

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // const [isExpertFromUA, setIsExpertFromUA] = useState(
  //   UKRAINIAN_TIMEZONES.includes(timeZone) || acceptLanguageHeader === "uk"
  // );
  const [isExpertFromUA, setIsExpertFromUA] = useState(false);
  const [, setR] = useLocalStorage(`r`, null);
  const [, setPlan] = useLocalStorage(`plan`, null);

  const accordionItems = [
    {
      label: t("accordionv2label0"),
      text: t("accordionv2text0"),
    },
    {
      label: t("accordionv2label1"),
      text: t("accordionv2text1"),
    },
    {
      label: t("accordionv2label2"),
      text: t("accordionv2text2"),
    },
    {
      label: t("accordionv2label3"),
      text: t("accordionv2text3"),
    },
    {
      label: t("accordionv2label4"),
      text: t("accordionv2text4"),
    },
    {
      label: t("accordionv2label5"),
      text: t("accordionv2text5"),
    },
  ];

  const handleImagesLoad = () => {
    setImagesLoaded(true);
  };

  const handleMonthlyClick = () => {
    setIsMonthlyBilling(true);
  };

  const handleAnnuallyClick = () => {
    setIsMonthlyBilling(false);
  };

  const links = [
    { name: "Zoe Reynolds", role: "Creator", image: "/img/expert-1.jpg" },
    { name: "Mia Clarke", role: "Expert", image: "/img/expert-2.jpg" },
    { name: "Evan Tate", role: "Freelancer", image: "/img/expert-3.jpg" },
    { name: "Ava Brooks", role: "Tutor", image: "/img/expert-4.jpg" },
    { name: "Luna Ellis", role: "Coach", image: "/img/expert-5.jpg" },
    { name: "Leo Grant", role: "Influencer", image: "/img/expert-6.jpg" },
  ];

  const ref = useRef(null);
  const [startX, setStartX] = useState(0);

  const { onMouseDown } = useDraggableScroll(ref, { direction: "horizontal" });
  const [scrolled, setScrolled] = useState<boolean>(false);

  const onMyMouseDown = (e) => {
    setScrolled(false);
    setStartX(e.clientX);
    onMouseDown(e);
  };

  const onMyMouseUp = (e) => {
    const diffX = Math.abs(e.clientX - startX);
    setScrolled(diffX > 20);
  };

  useEffect(() => {
    setMonthlyIncome(clientNumber * chargeVideo);
  }, [clientNumber, chargeVideo]);

  const fullName = user?.expert
    ? getFullName(
        user.expert.firstName,
        user.expert.lastName,
        user.expert.publicId
      )
    : getFullName(user?.firstName, user?.lastName, user?.localpart);
  const createAccountText = isLogged ? fullName : t("createWaylight");

  const router = useRouter();
  const r = router.query.r;

  const path = !isLogged
    ? `/sign-up${r ? `?r=${r}` : ""}`
    : user?.expert
    ? "/my/dashboard"
    : "/my/purchased";

  const savePath = () => {
    isLogged ? null : Cookies.set("path", path);
  };

  const handlePurchaseSubscription = (subscriptionPlan) => {
    const redirectUrl = encodeURIComponent("/my/settings/businessBilling");

    if (user && user?.expert !== null) {
      setPlan(null);
      r && setR(r);
      return router.push("/my/settings/businessBilling");
    }

    router.push(
      `/sign-up?redirect=${redirectUrl}&plan=${subscriptionPlan}${
        user && user?.expert === null ? "&create=true" : ""
      }${r ? `&r=${r}` : ""}`
    );
  };

  // const { data: subscriptionOffer, refetch } = useQuery(
  //   ["subscription-offer"],
  //   () =>
  //     api.get(
  //       `/offers/v1/subscription-offer${
  //         !r ? (isExpertFromUA ? "?currency=UAH" : "?currency=USD") : ""
  //       }${
  //         r
  //           ? `?r=${r}`
  //           : user?.expert?.referralCode
  //           ? `&r=${user?.expert?.referralCode}`
  //           : ""
  //       }`
  //     ),
  //   { cacheTime: 0 }
  // );
  // const { data: subscriptionOffer, refetch } = useQuery(
  //   ["subscription-offer"],
  //   () =>
  //     api.get(
  //       `/offers/v1/subscription-offer${!r ? "?currency=USD" : ""}${
  //         r
  //           ? `?r=${r}`
  //           : user?.expert?.referralCode
  //           ? `&r=${user?.expert?.referralCode}`
  //           : ""
  //       }`
  //     ),
  //   { cacheTime: 0 }
  // );

  const currency =
    currentPlanPrices.subscription_plans[3]?.currency === "uah"
      ? findCountryByCode("UAH")
      : findCountryByCode("USD");

  // const languageUpper = i18n.language.toUpperCase();

  // useEffect(() => {
  //   setIsExpertFromUA(
  //     user
  //       ? user?.expert?.country === "UA"
  //       : UKRAINIAN_TIMEZONES.includes(timeZone) || languageUpper === "UA"
  //   );
  //   refetch();
  // }, [languageUpper, user, timeZone]);

  // useEffect(() => {
  //   refetch();
  // }, [r, isExpertFromUA, user, timeZone]);

  useEffect(() => {
    if (subscriptionOffer) {
      setCurrentPlanPrices(subscriptionOffer);
      setLoadingPrice(false);
    }
  }, []);

  const isExpert = !!user?.expert;

  return (
    <>
      <div
        className={
          isProductOpen
            ? "overflow-hidden h-40"
            : "bg-landing-gray scroll-smooth landing-font-family font-poppins"
        }
      >
        {isLogged ? (
          <LoggedPublicNav
            imagesLoaded={imagesLoaded}
            language={user?.language}
            loggedUser={{
              isLoggedIn: true,
              avatar: user?.avatar,
              expertAvatar: user?.expert?.avatar,
              isExpert: !!user?.expert,
              fullName,
            }}
          />
        ) : (
          <ClientNav
            imagesLoaded={imagesLoaded}
            setProductMenu={setIsProductOpen}
            Language={lang}
            acceptLanguageHeader={acceptLanguageHeader}
            timeZone={timeZone}
          />
        )}

        <div className="relative -mt-px" id="page-1">
          <Transition appear show={showPopupVideo}>
            <Dialog
              as="div"
              open={showPopupVideo}
              className="fixed inset-0 z-10 flex items-center justify-center h-screen overflow-y-auto"
              onClose={() => setShowPopupVideo(false)}
            >
              <div className="px-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0 bg-slate-900 opacity-80" />
                </Transition.Child>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <div className="relative inline-block my-8 text-left align-middle border-4 border-black transition-all transform">
                    <XIcon
                      className="absolute hidden -right-8 w-4 h-4 text-white cursor-pointer sm:block"
                      onClick={() => setShowPopupVideo(false)}
                    />
                    <iframe
                      className="relative sm:w-168 sm:h-96 lazyload"
                      data-src="https://www.youtube.com/embed/w5zjak_iW94"
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition>

          <section className="bg-slate-900 text-white">
            <div className="flex flex-col max-w-7xl w-full m-auto px-5 relative md:flex-row lg:justify-between">
              <div className="max-w-xl mt-16 sm:mt-20 md:mt-0 lg:mt-8">
                <h1
                  className="lg:mt-48 md:mt-56 text-primary font-extrabold tracking-tight leading-tight text-[32px] md:text-[44px]"
                  data-testid="main-heading"
                >
                  {t("linkInBIOtool")}
                </h1>
                <span
                  className="block mt-0 font-extrabold tracking-tight leading-tight text-[32px] md:text-[44px] text-landing-red"
                  data-testid="sub-heading"
                >
                  {t("thatHelpsYouEarn")}
                </span>
                <div
                  className="flex mt-16 md:mt-32 lg:mt-32"
                  data-testid="cta-container"
                >
                  <a
                    href={path}
                    onClick={savePath}
                    className="bg-landing-red hover:bg-hover-landing-red text-white items-center rounded-xl shadow-sm w-[270px] h-[52px] flex justify-between text-base font-semibold focus:outline-none"
                    data-testid="cta-button"
                  >
                    <span className="truncate max-w-[175px] mx-auto pl-4 flex justify-center items-center">
                      <div
                        className={
                          !isLogged
                            ? "hidden"
                            : "h-9 w-9 bg-slate-50 rounded-full mr-2 shrink-0"
                        }
                        data-testid="avatar-container"
                      >
                        <img
                          alt="image avatar"
                          src={isExpert ? user?.expert?.avatar : user?.avatar}
                          className="h-9 w-9 rounded-full bg-avatar-gradient"
                          data-testid="avatar-image"
                        />
                      </div>
                      <div className="truncate" data-testid="cta-text">
                        {createAccountText}
                      </div>
                    </span>
                    <ChevronRightIcon
                      className="w-6 h-6 mr-4"
                      data-testid="cta-icon"
                    />
                  </a>
                </div>
              </div>
              <div
                className="max-w-3xl w-full mt-20 mb-16 md:mt-36 md:mb-36 lg:mt-36 lg:mb-36 relative md:ml-10 lg:w-[483px] lg:h-[473px]"
                data-testid="image-container"
              >
                <Image
                  src="/img/linkinbio.svg"
                  alt={t("businessTitle")}
                  width={480}
                  height={473}
                  layout="responsive"
                  quality={100}
                  loading="eager"
                  data-testid="image-linkinbio"
                />
              </div>
            </div>
          </section>

          <section
            className="pt-14 px-5 md:pt-28 max-w-7xl m-auto"
            data-testid="earn-section"
          >
            <div className="max-w-7xl space-y-5 m-auto">
              <div className="relative lg:mb-20 md:mb-20 flex flex-col-reverse items-center justify-center gap-14 lg:flex-row">
                <span className="w-10/12" data-testid="coins-image-container">
                  <Image
                    src="/img/coins.svg"
                    alt={t("descriptionEarn")}
                    width={520}
                    height={520}
                    layout="responsive"
                    quality={100}
                    loading="lazy"
                    data-testid="coins-image"
                  />
                </span>
                <div
                  className="relative w-full space-y-8"
                  data-testid="earn-content"
                >
                  <div>
                    <h2
                      className="text-[32px] text-landing-red font-extrabold tracking-tight leading-tight"
                      data-testid="earn-heading"
                    >
                      {t("earnMore")}{" "}
                      <span
                        className="text-[32px] text-slate-900 font-extrabold tracking-tight leading-tight"
                        data-testid="waylight-heading"
                      >
                        {t("withWaylight")}
                      </span>
                    </h2>
                    <p
                      className="mt-2 text-base font-medium text-gray-500"
                      data-testid="description-earn"
                    >
                      {t("descriptionEarn")}
                    </p>
                    <p
                      className="mt-2 text-base font-medium text-gray-500"
                      data-testid="description-earn-part"
                    >
                      {t("descriptionEarnPart")}
                    </p>
                  </div>
                  <ul
                    className="space-y-8 mt-5 text-left list-none"
                    data-testid="earn-list"
                  >
                    <li
                      className="flex text-gray-500"
                      data-testid="earn-item-communities"
                    >
                      <span className="flex items-center justify-center w-9 h-9 bg-landing-red rounded-t-full rounded-l-full flex-shrink-0 relative">
                        <FireIcon className="w-5 h-5 text-white" />
                      </span>
                      <div className="ml-4">
                        <h3 className="font-semibold text-slate-900 text-[20px]">
                          {t("earnOnPaidCommunities")}
                        </h3>
                        {t("createAndManage")}
                      </div>
                    </li>
                    <li
                      className="flex text-gray-500"
                      data-testid="earn-item-digital"
                    >
                      <span className="flex items-center justify-center w-9 h-9 bg-landing-red rounded-t-full rounded-l-full flex-shrink-0 relative">
                        <DownloadIcon className="w-5 h-5 text-white" />
                      </span>
                      <div className="ml-4">
                        <h3 className="font-semibold text-slate-900 text-[20px]">
                          {t("EarnPaidDigital")}
                        </h3>
                        {t("hostAndManage")}
                      </div>
                    </li>
                    <li
                      className="flex text-gray-500"
                      data-testid="earn-item-tickets"
                    >
                      <span className="flex items-center justify-center w-9 h-9 bg-landing-red rounded-t-full rounded-l-full flex-shrink-0 relative">
                        <TicketIcon className="w-5 h-5 text-white" />
                      </span>
                      <div className="ml-4">
                        <h3 className="font-semibold text-slate-900 text-[20px]">
                          {t("earnOnline")}
                        </h3>
                        {t("sellTicketsAndManage")}
                      </div>
                    </li>
                    <li
                      className="flex text-gray-500"
                      data-testid="earn-item-services"
                    >
                      <span className="flex items-center justify-center w-9 h-9 bg-landing-red rounded-t-full rounded-l-full flex-shrink-0 relative">
                        <ShoppingBagIcon className="w-5 h-5 text-white" />
                      </span>
                      <div className="ml-4">
                        <h3 className="font-semibold text-slate-900 text-[20px]">
                          {t("EarnServices")}
                        </h3>
                        {t("sellManage")}
                      </div>
                    </li>
                    <li
                      className="flex text-gray-500"
                      data-testid="earn-item-video"
                    >
                      <span className="flex items-center justify-center w-9 h-9 bg-landing-red rounded-t-full rounded-l-full flex-shrink-0 relative">
                        <VideoCameraIcon className="w-5 h-5 text-white" />
                      </span>
                      <div className="ml-4">
                        <h3 className="font-semibold text-slate-900 text-[20px]">
                          {t("earnOnlineVideoSessions")}
                        </h3>
                        {t("ScheduleBookingsOnlineVideo")}
                      </div>
                    </li>
                    <li
                      className="flex text-gray-500"
                      data-testid="earn-item-appointments"
                    >
                      <span className="flex items-center justify-center w-9 h-9 bg-landing-red rounded-t-full rounded-l-full flex-shrink-0 relative">
                        <CalendarIcon className="w-5 h-5 text-white" />
                      </span>
                      <div className="ml-4">
                        <h3 className="font-semibold text-slate-900 text-[20px]">
                          {t("EarnAppointments")}
                        </h3>
                        {t("ScheduleSignups")}
                      </div>
                    </li>
                    <li
                      className="flex text-gray-500"
                      data-testid="earn-item-tipping"
                    >
                      <span className="flex items-center justify-center w-9 h-9 bg-landing-red rounded-t-full rounded-l-full flex-shrink-0 relative">
                        <ThumbUpIcon className="w-5 h-5 text-white" />
                      </span>
                      <div className="ml-4">
                        <h3 className="font-semibold text-slate-900 text-[20px]">
                          {t("earnTipping")}
                        </h3>
                        {t("getTippingSupport")}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="mt-16 md:mt-0 border border-t-1 border-slate-200 mb-14"
              data-testid="divider"
            />
            <div
              className="flex flex-col md:flex-row justify-center items-center gap-16"
              data-testid="statistics"
            >
              <div>
                <p className="text-center text-[30px] text-landing-red font-extrabold">
                  5,000+
                </p>
                <p className="text-[16px] font-medium text-gray-500">
                  {t("creatorsExperts")}
                </p>
              </div>
              <div>
                <p className="text-center text-[30px] text-landing-red font-extrabold">
                  3,000+
                </p>
                <p className="text-[16px] font-medium text-gray-500">
                  {t("interactionsMonthly")}
                </p>
              </div>
              <div>
                <p className="text-center text-[30px] text-landing-red font-extrabold">
                  100%+
                </p>
                <p className="text-16px] font-medium text-gray-500">
                  {t("monthOverMonth")}
                </p>
              </div>
            </div>
          </section>

          <section
            className="mt-20 bg-slate-900 text-center text-white px-5 relative"
            data-testid="features-section"
          >
            <div className="max-w-7xl py-16 m-auto relative md:py-28">
              <div className="md:px-5">
                <div className="text-left flex flex-col md:flex-row lg:flex-row gap-2">
                  <h2
                    className="text-[32px] text-landing-red font-extrabold tracking-tight leading-tight"
                    data-testid="features-heading"
                  >
                    {t("youWilGet")}
                  </h2>
                  <span
                    className="text-[32px] font-extrabold tracking-tight leading-tight"
                    data-testid="waylight-features-heading"
                  >
                    {t("withWaylight")}
                  </span>
                </div>

                <ul
                  className="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-3 gap-8 text-left mt-14 md:gap-14"
                  data-testid="features-list"
                >
                  <li data-testid="feature-tools">
                    <span className="flex items-center justify-center w-12 h-12 rounded-xl bg-yellow-400">
                      <CollectionIcon className="w-7 h-7 text-slate-900" />
                    </span>
                    <h3 className="mt-6 text-xl font-semibold">{t("tools")}</h3>
                  </li>
                  <li data-testid="feature-sales">
                    <span className="flex items-center justify-center w-12 h-12 rounded-xl bg-yellow-400">
                      <LightningBoltIcon className="w-7 h-7 text-slate-900" />
                    </span>
                    <h3 className="mt-6 text-xl font-semibold">{t("sales")}</h3>
                  </li>
                  <li data-testid="feature-clients">
                    <span className="flex items-center justify-center w-12 h-12 rounded-xl bg-yellow-400">
                      <TrendingUpIcon className="w-7 h-7 text-slate-900" />
                    </span>
                    <h3 className="mt-6 text-xl font-semibold">
                      {t("clientsGet")}
                    </h3>
                  </li>
                </ul>
              </div>
            </div>
          </section>

          <section
            className="max-w-7xl m-auto mt-10 sm:mt-20 px-6"
            data-testid="how-it-works-section"
          >
            <h3 className="text-[32px] text-landing-red font-extrabold tracking-tight leading-tight">
              {t("how")}{" "}
              <span
                className="text-[32px] text-slate-900 font-extrabold tracking-tight leading-tight"
                data-testid="it-works-text"
              >
                {t("itWorks")}:
              </span>
            </h3>
          </section>

          <section
            className="max-w-7xl m-auto mt-10 px-6"
            data-testid="business-flow-step-1-section"
          >
            <div className="flex flex-col md:flex-row gap-16 md:gap-32">
              <div className="flex flex-col gap-4 flex-1 justify-center">
                <div
                  className="flex items-center justify-center font-bold bg-slate-900 rounded-full text-white text-2xl w-12 h-12"
                  data-testid="step-number"
                >
                  1
                </div>
                <h4
                  className="text-2xl font-bold text-slate-900"
                  data-testid="step-title"
                >
                  {t("businessflowStep1Title")}
                </h4>
                <p className="text-gray-500" data-testid="step-description">
                  {t("businessflowStep1new")}
                </p>
              </div>
              <div>
                <Image
                  src="/img/illustration1.svg"
                  alt={t("businessflowStep1Title")}
                  width={480}
                  height={480}
                  layout="responsive"
                  quality={100}
                  loading="lazy"
                  data-testid="step-image"
                />
              </div>
            </div>
          </section>

          <section
            className="max-w-7xl m-auto mt-10 px-6"
            data-testid="business-flow-step-3-section"
          >
            <div className="flex flex-col-reverse md:flex-row gap-16 md:gap-32">
              <div className="flex-1">
                <Image
                  src="/img/illustration2.svg"
                  alt={t("businessflowStep3Title")}
                  width={500}
                  height={500}
                  layout="responsive"
                  quality={100}
                  loading="lazy"
                  data-testid="step-image"
                />
              </div>
              <div className="flex flex-col gap-4 flex-1 justify-center">
                <div
                  className="flex items-center justify-center font-bold bg-slate-900 rounded-full text-white text-2xl w-12 h-12"
                  data-testid="step-number"
                >
                  2
                </div>
                <h4
                  className="text-2xl font-bold text-slate-900"
                  data-testid="step-title"
                >
                  {t("businessflowStep3Title")}
                </h4>
                <p className="text-gray-500" data-testid="step-description">
                  {t("businessflowStep3new")}
                </p>
              </div>
            </div>
          </section>

          <section
            className="mb-20 lg:mb-0 max-w-7xl m-auto mt-10 px-6"
            data-testid="get-clients-section"
          >
            <div className="flex flex-col md:flex-row gap-16 md:gap-32">
              <div className="flex flex-col gap-4 flex-1 justify-center">
                <div
                  className="flex items-center justify-center font-bold bg-slate-900 rounded-full text-white text-2xl w-12 h-12"
                  data-testid="step-number"
                >
                  3
                </div>
                <h4
                  className="text-2xl font-bold text-slate-900"
                  data-testid="step-title"
                >
                  {t("GetClients")}
                </h4>
                <p className="text-gray-500" data-testid="step-description">
                  {t("businessflowStep2new")}
                </p>
              </div>
              <div className="flex-1">
                <Image
                  src="/img/getnewclients.svg"
                  alt={t("GetClients")}
                  width={570}
                  height={480}
                  layout="responsive"
                  quality={100}
                  loading="lazy"
                  onLoad={handleImagesLoad}
                  data-testid="step-image"
                />
              </div>
            </div>
          </section>

          <div
            className="hidden lg:block md:block"
            data-testid="bio-illustration-section"
          >
            <div className="flex justify-between items-start relative overflow-hidden h-1/2">
              <img
                loading="lazy"
                src="/img/coinsright.svg"
                alt="bio illustration"
                className="hidden md:block lg:block transform h-[256px] translate-y-24"
                data-testid="bio-illustration-image-1"
              />
              <img
                loading="lazy"
                src="/img/coinsright.svg"
                alt="bio illustration"
                className="hidden md:block lg:block transform h-[256px]  translate-y-24"
                data-testid="bio-illustration-image-2"
              />
            </div>
          </div>

          <section
            className="bg-slate-100"
            data-testid="business-calculator-section"
          >
            <div className="relative z-10 max-w-7xl w-full py-10 px-4 m-auto lg:py-20">
              <h3
                className="text-slate-900 text-[32px] font-extrabold tracking-tight leading-tight lg:text-center"
                data-testid="business-calculator-title"
              >
                {t("businessCalculatorNew")}
              </h3>
              <p
                className="mt-4 text-xl leading-7 font-normal lg:text-xl lg:text-center text-slate-900"
                data-testid="business-calculator-description"
              >
                {t("howMuchMoneyNew")}
              </p>
              <div
                className="text-slate-900 flex flex-col rounded-lg mt-12 shadow-lg bg-white overflow-hidden lg:flex-row"
                data-testid="calculator-box"
              >
                <div className="w-auto mx-3 lg:w-full lg:mx-12">
                  <div className="pt-8 px-11 pb-4 lg:p-11">
                    <h3
                      className="text-slate-900 font-bold lg:text-2xl"
                      style={{
                        position: "relative",
                        left: "-44px",
                        width: "calc(100% + 88px)",
                      }}
                      data-testid="estimate-number-title"
                    >
                      {t("estimateNumberNew")}
                    </h3>
                    <div className="mt-10" />
                    <div>
                      <StepRangeSlider
                        value={clientNumber}
                        range={timeRange}
                        onChange={(value) => setClientNumber(value)}
                      />
                    </div>
                    <div
                      className="relative flex justify-between mt-5"
                      style={{
                        position: "relative",
                        left: "-44px",
                        width: "calc(100% + 88px)",
                      }}
                      data-testid="client-number-scale"
                    >
                      <div className="mx-2">1</div>
                      <div>10</div>
                      <div>50</div>
                      <div>100</div>
                      <div>1000</div>
                    </div>
                  </div>
                  <div className="w-full pt-4 px-11 pb-8 lg:p-11">
                    <h3
                      className="text-slate-900 font-bold lg:text-2xl"
                      style={{
                        position: "relative",
                        left: "-44px",
                        width: "calc(100% + 88px)",
                      }}
                      data-testid="charge-amount-title"
                    >
                      {t("howMuchYouChargeNew")}
                    </h3>
                    <div className="mt-10" />
                    <div>
                      <StepRangeSlider
                        value={chargeVideo}
                        range={currencyRange}
                        onChange={(value) => setChargeVideo(value)}
                      />
                    </div>
                    <div
                      className="relative flex justify-between mt-5"
                      style={{
                        position: "relative",
                        left: "-44px",
                        width: "calc(100% + 88px)",
                      }}
                      data-testid="charge-amount-scale"
                    >
                      <div className="mx-2">$5</div>
                      <div>$50</div>
                      <div>$100</div>
                      <div>$200</div>
                      <div>$1000</div>
                    </div>
                  </div>
                </div>
                <div
                  className="flex flex-col justify-center flex-shrink-0 w-full py-6 px-8 bg-gray-50 lg:w-80 lg:py-0"
                  data-testid="monthly-income-box"
                >
                  <h6
                    className="text-lg leading-6 font-medium text-center"
                    data-testid="monthly-income-title"
                  >
                    {t("monthlyIncome")}
                  </h6>
                  <div className="flex items-center justify-center gap-3 mt-4">
                    <p
                      className="text-5xl leading-none font-extrabold"
                      data-testid="monthly-income-amount"
                    >
                      ${monthlyIncome}
                    </p>
                    <p
                      className="text-xl leading-7 font-medium text-gray-500"
                      data-testid="monthly-income-currency"
                    >
                      USD
                    </p>
                  </div>
                  <a
                    href={path}
                    onClick={savePath}
                    className="bg-landing-red hover:bg-hover-landing-red mt-6 text-white items-center rounded-xl shadow-sm w-[270px] h-[52px] flex justify-between text-base font-medium focus:outline-none"
                    data-testid="sign-up-button"
                  >
                    <span className="truncate max-w-[175px] mx-auto flex items-center justify-center">
                      <div
                        className={
                          !isLogged
                            ? "hidden"
                            : "h-9 w-9 bg-slate-50 rounded-full mr-2 shrink-0"
                        }
                        data-testid="user-avatar-container"
                      >
                        <img
                          alt="image avatar"
                          src={isExpert ? user?.expert?.avatar : user?.avatar}
                          className="h-9 w-9 rounded-full bg-avatar-gradient"
                          data-testid="user-avatar"
                        />
                      </div>
                      <div className="truncate" data-testid="sign-up-text">
                        {createAccountText}
                      </div>
                    </span>
                    <ChevronRightIcon className="w-6 h-6 mr-2" />
                  </a>
                </div>
              </div>
            </div>
          </section>

          <section
            className="lg:hidden mt-10 overflow-hidden overflow-y-hidden"
            data-testid="target-audience-section"
          >
            <h3
              className="ml-6 text-[32px] text-landing-red font-extrabold tracking-tight leading-tight"
              data-testid="target-audience-title"
            >
              {t("for")}{" "}
              <span className="text-[32px] text-slate-900 font-extrabold tracking-tight leading-tight">
                {t("whom")}:
              </span>
            </h3>
            <div
              className="h-[700px] overflow-y-hidden overflow-hidden relative hide-scrollbar md:left-1/2 transform -t-x-2/4"
              data-testid="target-audience-container"
            >
              <div className="relative before:bg-gradient-to-l before:z-10 before:absolute before:w-16 before:h-[702px] before:-left-0.5 before:top-0 before:from-transparent before:via-transparent before:to-slate-50 before:pointer-events-none after:hide-scrollbar after:block after:bg-gradient-to-r after:absolute after:w-16 after:h-[702px] after:z-10 after:-right-[4px] after:top-4 after:from-transparent after:via-transparent after:to-slate-50 after:pointer-events-none">
                <div
                  ref={ref}
                  onMouseDown={onMyMouseDown}
                  onMouseUp={onMyMouseUp}
                  className="flex pl-6 pr-8 h-[718px] hide-scrollbar overflow-y-hidden overflow-x-scroll py-[18px] gap-4 w-full"
                  data-testid="target-audience-list"
                >
                  {links.map((tag, index) => (
                    <React.Fragment key={index}>
                      <div
                        className="relative min-w-[18rem] h-full"
                        data-testid={`target-audience-item-${index}`}
                      >
                        <Image
                          src={tag.image}
                          alt={tag.role}
                          layout="fill"
                          quality={100}
                          loading="lazy"
                          className="object-cover rounded-xl h-full"
                          data-mouse-scrolled={scrolled}
                          data-testid={`target-audience-image-${index}`}
                        />
                        <div
                          className="absolute p-2 text-center top-4 left-4"
                          data-testid={`target-audience-text-${index}`}
                        >
                          <p className="text-landing-red text-3xl font-bold text-left">
                            {t(tag.role)}
                          </p>
                          <p className="text-white text-xl text-left font-semibold">
                            {tag.name}
                          </p>
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
          </section>

          <section
            className="max-w-7xl m-auto hidden lg:block"
            data-testid="target-audience-section-desktop"
          >
            <h3
              className="mt-20 text-[32px] text-landing-red font-extrabold tracking-tight leading-tight"
              data-testid="target-audience-title-desktop"
            >
              {t("for")}{" "}
              <span className="text-[32px] text-slate-900 font-extrabold tracking-tight leading-tight">
                {t("whom")}:
              </span>
            </h3>
            <div
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              className="max-w-7xl flex mt-20 justify-center gap-8 ml-2 overflow-hidden h-[700px]"
              data-testid="target-audience-container-desktop"
            >
              {[
                "/img/expert-1.jpg",
                "/img/expert-2.jpg",
                "/img/expert-3.jpg",
                "/img/expert-4.jpg",
                "/img/expert-5.jpg",
                "/img/expert-6.jpg",
              ].map((image, index) => (
                <div
                  key={index}
                  className={`relative h-[700px] transition-all duration-500 ease-in-out ${
                    hoveredIndex === index ||
                    (hoveredIndex === -1 && index === 0)
                      ? "w-[480px]"
                      : "w-[130px] object-cover"
                  }`}
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(-1)}
                  data-testid={`target-audience-item-desktop-${index}`}
                >
                  <Image
                    src={image}
                    alt={t(links[index].role)}
                    className="object-cover rounded-2xl h-full"
                    layout="fill"
                    quality={100}
                    loading="lazy"
                    data-testid={`target-audience-image-desktop-${index}`}
                  />
                  <div
                    className={`absolute p-2 top-4 h-28 left-4 text-white text-center bottom-0 transition-opacity duration-500 ${
                      hoveredIndex === index ||
                      (hoveredIndex === -1 && index === 0)
                        ? "opacity-100"
                        : "opacity-0"
                    }`}
                    data-testid={`target-audience-text-desktop-${index}`}
                  >
                    <p className="text-3xl text-landing-red font-bold text-left">
                      {t(links[index].role)}
                    </p>
                    <p className="text-xl text-left font-semibold">
                      {links[index].name}
                    </p>
                  </div>
                </div>
              ))}
              <div
                className="flex-grow"
                onMouseEnter={() => setIsHovered(false)}
              />
            </div>
          </section>

          <section
            id="pricing"
            className="bg-black m-auto mt-32"
            data-testid="pricing-section"
          >
            <h1
              data-testid="desktop-pricing-title"
              className="hidden max-w-7xl m-auto lg:block text-[32px] font-extrabold tracking-tight leading-tight pt-16 text-white mb-4"
            >
              {t("pricing")}
            </h1>
            <div className="max-w-7xl m-auto flex flex-col items-center lg:items-start w-full mb-16 px-4 lg:flex-row lg:mb-20">
              <div
                data-testid="pricing-container"
                className="text-white m-auto bg-black mx-1 lg:mx-auto lg:p-6"
              >
                <h1
                  data-testid="mobile-pricing-title"
                  className="lg:hidden block text-3xl font-extrabold pt-16 mb-8 text-white "
                >
                  {t("pricing")}
                </h1>
                <h2 className="lg:text-center text-2xl font-extrabold mb-8 lg:mb-4">
                  {t("chooseYourPlan")}
                </h2>
                <p
                  data-testid="save-annually-note"
                  className="mb-8 text-slate-50 text-xl text-center hidden lg:block"
                >
                  {t("saveWhenChoosingYearlyPlan")}
                </p>
                <div
                  data-testid="billing-toggle"
                  className="lg:flex hidden justify-end mb-6"
                >
                  <div className="inline-flex py-1 px-2 bg-zinc-800 rounded-md">
                    <button
                      data-testid="monthly-billing-btn"
                      className={`${
                        isMonthlyBilling ? "bg-zinc-900" : "bg-zinc-800"
                      } px-4 py-2 rounded-md text-[14px] font-medium`}
                      onClick={handleMonthlyClick}
                    >
                      {t("monthlyBilling")}
                    </button>
                    <button
                      data-testid="annually-billing-btn"
                      className={`${
                        isMonthlyBilling ? "bg-zinc-800" : "bg-zinc-900"
                      } px-4 py-2 rounded-md text-[14px] font-medium`}
                      onClick={handleAnnuallyClick}
                    >
                      {t("annuallyBilling")}
                    </button>
                  </div>
                </div>
                {!isLoadingPrice ? (
                  <div
                    data-testid="plans-container"
                    className="flex flex-wrap flex-col items-right lg:justify-center lg:flex-row lg:gap-4 lg:mb-16"
                  >
                    <div
                      data-testid="free-plan"
                      className="bg-zinc-800 mb-6 lg:mb-0 w-[350px] lg:h-[625px] rounded"
                    >
                      <div className="p-6">
                        <h3
                          data-testid="free-plan-title"
                          className="text-2xl font-bold mb-6"
                        >
                          {t("freePlan")}
                        </h3>
                        <p
                          data-testid="free-plan-description"
                          className="text-sm mb-6 text-zinc-400"
                        >
                          {t("startLowestCostsNew")}
                        </p>
                        <p className="mb-6" data-testid="free-plan-price">
                          <span className="text-4xl font-extrabold">
                            {currency.symbol}
                            {currentPlanPrices.subscription_plans[0].price}
                          </span>
                          {t("mo")}
                        </p>

                        <button
                          data-testid="free-plan-button"
                          onClick={() => handlePurchaseSubscription("Free")}
                          className="bg-zinc-700 py-2 w-[300px] lg:w-[270px] block m-auto rounded-md"
                        >
                          {user
                            ? user.expert === null
                              ? t("becomeAnExpert")
                              : t("notificationGoToBilling")
                            : t("signUp")}
                        </button>
                      </div>

                      <div className="border border-zinc-700 my-6" />
                      <div
                        data-testid="free-plan-feature-1"
                        className="flex gap-2 pl-6 mb-4"
                      >
                        <CheckIcon className="w-5 h-5 text-green-600" />
                        <p className="text-blue-400 text-sm">
                          {t("impressionMo", {
                            comission:
                              currentPlanPrices.subscription_plans[0]
                                ?.comission,
                          })}
                        </p>
                      </div>
                      <div
                        data-testid="free-plan-feature-2"
                        className="flex gap-2 pl-6 mb-6"
                      >
                        <CheckIcon className="w-5 h-5 text-green-600" />
                        <p className="text-gray-500 text-sm">
                          {t("basicToolsIncluded")}
                        </p>
                      </div>
                    </div>

                    <div
                      className="bg-zinc-800 mb-6 lg:mb-0 w-[350px] lg:h-[625px] rounded"
                      data-testid="basic-plan"
                    >
                      <div className="p-6 relative">
                        <div className="flex justify-end">
                          <p
                            data-testid="basic-plan-badge"
                            className={cx(
                              "absolute top-2 right-2 text-slate-900 px-6 py-1 text-xs inline-flex rounded-2xl",
                              {
                                "bg-blue-300":
                                  currentPlanPrices.type === "affiliate",
                                "bg-slate-50":
                                  currentPlanPrices.type === "cashback",
                                hidden:
                                  currentPlanPrices.subscription_plans[1]
                                    ?.arp_discount === 0,
                              }
                            )}
                          >
                            {t("saveBadge", {
                              percent:
                                currentPlanPrices.subscription_plans[1]
                                  ?.waylight_discount +
                                currentPlanPrices.subscription_plans[1]
                                  ?.arp_discount,
                            })}
                          </p>
                        </div>

                        <h3
                          data-testid="basic-plan-title"
                          className="text-2xl font-bold mb-6"
                        >
                          {t("basic")}
                        </h3>
                        <p
                          className="text-sm mb-6 text-zinc-400"
                          data-testid="basic-plan-description"
                        >
                          {t("getEverythingBasicNew")}
                        </p>
                        <p className="mb-6" data-testid="basic-plan-price">
                          <p
                            className={`${
                              currentPlanPrices.subscription_plans[1].price ===
                              currentPlanPrices.subscription_plans[1]
                                .original_price
                                ? "hidden"
                                : "line-through text-4xl text-slate-300 opacity-75 inline-block font-extrabold mr-2"
                            }`}
                          >
                            {currency.symbol}
                            {currentPlanPrices.subscription_plans[1]
                              .currency === "uah"
                              ? Math.round(
                                  currentPlanPrices.subscription_plans[1]
                                    .original_price
                                )
                              : currentPlanPrices.subscription_plans[1]
                                  .original_price}
                          </p>

                          <span className="text-4xl font-extrabold">
                            {currency.symbol}
                            {currentPlanPrices.subscription_plans[1]
                              .currency === "uah"
                              ? Math.round(
                                  currentPlanPrices.subscription_plans[1].price
                                )
                              : currentPlanPrices.subscription_plans[1].price}
                          </span>
                          {t("mo")}
                        </p>

                        <button
                          onClick={() => handlePurchaseSubscription("Basic")}
                          className="bg-zinc-700 w-[300px] lg:w-[270px] py-2 block m-auto rounded-md"
                        >
                          {user
                            ? user.expert === null
                              ? t("becomeAnExpert")
                              : t("notificationGoToBilling")
                            : t("signUp")}
                        </button>
                      </div>

                      <div className="border border-zinc-700 my-6" />
                      <div className="flex gap-2 pl-6 mb-4">
                        <CheckIcon className="w-5 h-5 text-green-600" />
                        <p className="text-blue-400 text-sm">
                          {t("impressionMo", {
                            comission:
                              currentPlanPrices.subscription_plans[1]
                                ?.comission,
                          })}
                        </p>
                      </div>
                      <div className="flex gap-2 pl-6 mb-4">
                        <CheckIcon className="w-5 h-5 text-green-600" />
                        <p className="text-gray-500 text-sm">
                          {t("basicToolsIncluded")}
                        </p>
                      </div>
                      <div className="flex gap-2 pl-6 mb-6">
                        <CheckIcon className="w-5 h-5 text-green-600" />
                        <p className="text-gray-500 text-sm">
                          {t("publishingMarketplace")}
                        </p>
                      </div>
                    </div>

                    <div className="flex justify-end mb-6 lg:hidden">
                      <div className="inline-flex bg-zinc-800 py-1 px-2 rounded-md">
                        <button
                          className={`${
                            isMonthlyBilling ? "bg-zinc-900" : "bg-zinc-800"
                          } px-4 py-2 rounded-md font-medium text-[14px]`}
                          onClick={handleMonthlyClick}
                        >
                          {t("monthlyBilling")}
                        </button>
                        <button
                          className={`${
                            isMonthlyBilling ? "bg-zinc-800" : "bg-zinc-900"
                          } px-4 py-2 rounded-md font-medium text-[14px]`}
                          onClick={handleAnnuallyClick}
                        >
                          {t("annuallyBilling")}
                        </button>
                      </div>
                    </div>

                    <div
                      data-testid="pro-plan"
                      className="bg-zinc-800 mb-28 lg:mb-0 w-[350px] h-[625px] rounded"
                    >
                      <div className="p-6 relative">
                        <div className="flex justify-end">
                          <p
                            className={cx(
                              "absolute top-2 right-2 text-slate-900 px-6 py-1 text-xs inline-flex rounded-2xl",
                              {
                                "bg-blue-300":
                                  currentPlanPrices.type === "affiliate",
                                "bg-slate-50":
                                  currentPlanPrices.type === "cashback",
                              }
                            )}
                          >
                            {isMonthlyBilling
                              ? currentPlanPrices.subscription_plans[2]
                                  .original_price ===
                                currentPlanPrices.subscription_plans[2].price
                                ? t("bestValue")
                                : t("saveBadge", {
                                    percent:
                                      currentPlanPrices.subscription_plans[2]
                                        ?.waylight_discount +
                                      currentPlanPrices.subscription_plans[2]
                                        ?.arp_discount,
                                  })
                              : currentPlanPrices.subscription_plans[3]
                                  .original_price ===
                                currentPlanPrices.subscription_plans[3].price
                              ? t("bestValue")
                              : t("saveBadge", {
                                  percent:
                                    currentPlanPrices.subscription_plans[3]
                                      ?.waylight_discount +
                                    currentPlanPrices.subscription_plans[3]
                                      ?.arp_discount,
                                })}
                          </p>
                        </div>

                        <h3 className="text-2xl font-bold mb-6">Pro</h3>
                        <p className="text-sm mb-6 text-zinc-400">
                          {t("GetMoreSalesNew")}
                        </p>
                        <p
                          className={`${
                            isMonthlyBilling &&
                            currentPlanPrices.subscription_plans[2]
                              .original_price ===
                              currentPlanPrices.subscription_plans[2].price
                              ? "hidden"
                              : "mb-4 line-through text-4xl text-slate-300 opacity-75 inline-block font-extrabold mr-2"
                          }`}
                        >
                          {currency.symbol}
                          {isExpertFromUA ||
                          currentPlanPrices.subscription_plans[2].currency ===
                            "uah"
                            ? Math.round(
                                currentPlanPrices.subscription_plans[2]
                                  .original_price
                              )
                            : currentPlanPrices.subscription_plans[2]
                                .original_price}
                        </p>
                        <p className="mb-6 inline-block">
                          <span className="text-4xl font-extrabold">
                            {currency.symbol}
                            {isMonthlyBilling
                              ? currentPlanPrices.subscription_plans[2].price
                              : currentPlanPrices.subscription_plans[3].price}
                          </span>
                          {t("mo")}
                        </p>

                        <button
                          onClick={() =>
                            handlePurchaseSubscription(
                              isMonthlyBilling ? "Pro" : "proYear"
                            )
                          }
                          className="bg-landing-red hover:bg-hover-landing-red w-[300px] lg:w-[270px] py-2 rounded-md block m-auto"
                        >
                          {user
                            ? user.expert === null
                              ? t("becomeAnExpert")
                              : t("notificationGoToBilling")
                            : t("signUp")}
                        </button>
                      </div>

                      <div className="border border-zinc-700 my-6" />
                      <div className="flex gap-2 pl-6 mb-4">
                        <CheckIcon className="w-5 h-5 text-green-600" />
                        <p className="text-blue-400 text-sm">
                          {t("ziroTransactionFees")}
                        </p>
                      </div>
                      <div className="flex gap-2 pl-6 mb-4">
                        <CheckIcon className="w-5 h-5 text-green-600" />
                        <p className="text-gray-500 text-sm">
                          {t("proToolsIncluded")}
                        </p>
                      </div>
                      <div className="flex gap-2 pl-6 mb-4">
                        <CheckIcon className="w-5 h-5 text-green-600" />
                        <p className="text-gray-500 text-sm">
                          {t("publishingMarketplace")}
                        </p>
                      </div>
                      <div className="flex gap-2 pl-6 mb-4">
                        <CheckIcon className="w-5 h-5 text-green-600" />
                        <p className="text-gray-500 text-sm">
                          {t("customizations")}
                        </p>
                      </div>
                      <div className="flex gap-2 pl-6 mb-4">
                        <CheckIcon className="w-5 h-5 text-green-600" />
                        <p className="text-gray-500 text-sm">
                          {t("verifiedSign")}
                        </p>
                      </div>
                      <div className="flex gap-2 pl-6 mb-4">
                        <CheckIcon className="w-5 h-5 text-green-600" />
                        <p className="text-gray-500 text-sm">
                          {t("prioritySupport")}
                        </p>
                      </div>
                      <div className="flex gap-2 pl-6 mb-6">
                        <CheckIcon className="w-5 h-5 text-green-600" />
                        <p className="text-gray-500 text-sm">
                          {t("launchExpert")}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex justify-center items-center h-[625px]">
                    <Loader fullScreen={false} />
                  </div>
                )}
              </div>
            </div>
          </section>

          <section className="px-5" data-testid="questions-section">
            <div className="max-w-7xl mx-auto md:px-6">
              <h2
                className="text-slate-900 text-[32px] font-extrabold tracking-tight leading-tight mb-10"
                data-testid="questions-header"
              >
                <span className="text-landing-red">{t("ask")}</span>{" "}
                <span>{t("anyQuestions")}</span>
              </h2>
              <div
                className="text-slate-900 flex flex-col xl:justify-between lg:justify-between items-start xl:items-center lg:items-center lg:flex-row lg:gap-8 mb-4 lg:mb-16"
                data-testid="contact-options"
              >
                <a
                  href="https://t.me/waylightme"
                  aria-label="Link to Waylight Telegram account"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mb-8 xl:mb-0 lg:mb-0 bg-gray-300 text-slate-900 items-center rounded-xl shadow-sm w-[343px] h-[77px] xl:w-[365px] xl:h-[82px] flex justify-between font-medium focus:outline-none"
                  data-testid="telegram-link"
                >
                  <img
                    className="mx-4 w-10 h-10 xl:w-[43px] xl:h-[43px]"
                    src="/img/telegram-questions.png"
                    alt="telegram"
                    data-testid="telegram-icon"
                  />
                  <span
                    className="text-xl xl:text-2xl mx-auto"
                    data-testid="telegram-text"
                  >
                    {t("askTelegram")}
                  </span>
                  <ChevronRightIcon className="w-6 h-6 mr-2" />
                </a>

                <a
                  href="https://api.whatsapp.com/send/?phone=13024672761"
                  aria-label="Link to Waylight Whatsapp account"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mb-8 xl:mb-0 lg:mb-0 bg-gray-300 text-slate-900 items-center rounded-xl shadow-sm w-[343px] h-[77px] xl:w-[365px] xl:h-[82px] flex justify-between font-medium focus:outline-none"
                  data-testid="whatsapp-link"
                >
                  <img
                    className="mx-4 w-10 h-10 xl:w-[43px] xl:h-[43px]"
                    src="/img/whatsApp-questions.png"
                    alt="whatsapp"
                    data-testid="whatsapp-icon"
                  />
                  <span
                    className="mx-auto text-xl xl:text-2xl"
                    data-testid="whatsapp-text"
                  >
                    {t("askWhatsApp")}
                  </span>
                  <ChevronRightIcon className="w-6 h-6 mr-2" />
                </a>

                <a
                  aria-label="Email support at support@waylight.me"
                  href="mailto:support@waylight.me"
                  className="mb-8 xl:mb-0 lg:mb-0 bg-gray-300 text-slate-900 items-center rounded-xl shadow-sm w-[343px] h-[77px] xl:w-[365px] xl:h-[82px] flex justify-between font-medium focus:outline-none"
                  data-testid="email-link"
                >
                  <img
                    className="mx-4 w-10 h-10 xl:w-[43px] xl:h-[43px]"
                    src="/img/mail-questions.png"
                    alt="email"
                    data-testid="email-icon"
                  />
                  <span
                    className="mx-auto text-xl xl:text-2xl"
                    data-testid="email-text"
                  >
                    {t("askEmail")}
                  </span>
                  <ChevronRightIcon className="w-6 h-6 mr-2" />
                </a>
              </div>
              <h2
                className="text-slate-900 text-[32px] font-extrabold tracking-tight leading-tight"
                data-testid="faq-header"
              >
                {t("frequentlyAskedQuestions")}:
              </h2>
              <div
                className="mt-8 md:mt-10 text-slate-900"
                data-testid="faq-accordion"
              >
                <Accordion items={accordionItems} />
              </div>
              <div
                className="flex md:mt-16 lg:hidden mt-12 flex-col items-center justify-center"
                data-testid="launch-info"
              >
                <p className="text-slate-900 text-center text-[32px] font-extrabold tracking-tight leading-tight pb-4">
                  <span className="text-landing-red">{t("MakeTenX")}</span>{" "}
                  {t("onYourSocialMediaNew")}
                </p>
                <p className="text-slate-900 text-center text-[32px] font-extrabold tracking-tight leading-tight">
                  {t("launchJust")}{" "}
                  <span className="text-landing-red">{t("twoMinutes")}</span>
                </p>
              </div>
            </div>
          </section>

          <div
            className="flex justify-between items-start relative overflow-hidden h-40 lg:h-1/2"
            data-testid="bio-illustration-section-coins"
          >
            <img
              loading="lazy"
              src="/img/coinsleft.svg"
              alt="bio illustration"
              className="md:hidden transform w-48 md:w-auto -translate-x-8 translate-y-24"
              data-testid="coins-left-small"
            />
            <img
              loading="lazy"
              src="/img/coinsright.svg"
              alt="bio illustration"
              className="hidden md:block transform h-[256px] lg:translate-y-24"
              data-testid="coins-right-large"
            />
            <div
              className="hidden lg:flex absolute inset-0 flex-col items-center justify-center"
              data-testid="centered-text"
            >
              <p className="text-slate-900 text-center text-3xl font-extrabold pb-4">
                <span className="text-landing-red">{t("MakeTenX")}</span>{" "}
                {t("onYourSocialMediaNew")}
              </p>
              <p className="text-slate-900 text-center text-3xl font-extrabold">
                {t("launchJust")}{" "}
                <span className="text-landing-red">{t("twoMinutes")}</span>
              </p>
            </div>
            <img
              loading="lazy"
              src="/img/coinsright.svg"
              alt="bio illustration"
              className="hidden md:block transform md:w-auto md:h-[256px] lg:translate-y-24"
              data-testid="coins-right-large-2"
            />
            <img
              loading="lazy"
              src="/img/coinsleft.svg"
              alt="bio illustration"
              className="md:hidden transform w-48 md:w-auto translate-x-6 translate-y-24"
              data-testid="coins-left-small-2"
            />
          </div>

          <footer className="bg-slate-900 text-white px-5" data-testid="footer">
            <div
              className="xs:max-w-sm pt-11 m-auto divide-y divide-gray-800 md:max-w-7xl md:pt-14 md:px-5"
              data-testid="footer-content"
            >
              <div className="flex flex-col justify-between md:flex-row md:items-top md:flex-wrap md:gap-x-1.5 lg:gap-x-3">
                <div className="mb-8" data-testid="footer-love-section">
                  <div className="flex flex-col gap-1 text-xl font-semibold lg:text-3xl md:flex-row">
                    {t("love")}
                    <div className="flex gap-1">
                      <HeartIcon className="w-6 h-6 lg:w-8 lg:h-8 flex-shrink-0 inline-block text-red-500" />
                      <span>🇺🇸</span>
                    </div>
                  </div>
                  <>
                    <div
                      className="flex flex-wrap gap-3 mt-10 text-sm text-slate-400 lg:text-base"
                      data-testid="footer-links-section"
                    >
                      <span
                        className="flex items-center"
                        data-testid="footer-terms"
                      >
                        {t("termsOfService")}
                        <ArrowNarrowRightIcon className="w-5 h-5 ml-2" />
                        <a
                          className="ml-2 text-xl"
                          href="/docs/Waylight-TermsOfService.pdf"
                          target="_blank"
                          aria-label="Open Terms of Service PDF"
                          data-testid="footer-terms-en"
                        >
                          🇺🇸
                        </a>
                      </span>
                      <span
                        className="flex items-center"
                        data-testid="footer-privacy"
                      >
                        {t("privacyPolicy")}
                        <ArrowNarrowRightIcon className="w-5 h-5 ml-2" />
                        <a
                          className="ml-2 text-xl"
                          href="/docs/Waylight-PrivacyPolicy.pdf"
                          target="_blank"
                          aria-label="Open Privacy Policy PDF"
                          data-testid="footer-privacy-en"
                        >
                          🇺🇸
                        </a>
                      </span>
                    </div>

                    <Link
                      href={path}
                      passHref
                      className="mt-10 bg-landing-red hover:bg-hover-landing-red text-white items-center rounded-xl shadow-sm w-[270px] h-[52px] flex justify-between text-base font-medium focus:outline-none"
                      data-testid="footer-create-account"
                    >
                      <span className="truncate max-w-[175px] mx-auto">
                        {createAccountText}
                      </span>
                      <ChevronRightIcon className="w-6 h-6 mr-2" />
                    </Link>
                  </>
                </div>
                <div
                  className="w-full py-8 border-t border-gray-800 md:border-t-0 md:w-auto md:pt-0 order-last xl:order-none"
                  data-testid="footer-support-section"
                >
                  <h3 className="text-sm font-semibold text-gray-400 uppercase">
                    {t("support")}:
                  </h3>
                  <ul className="mt-4 space-y-3 text-gray-100 check-the-etag">
                    <li className="flex">
                      <MailIcon className="inline-block w-5 h-5 mr-3" />
                      <a
                        className="hover:underline"
                        href="mailto:support@waylight.me"
                        aria-label="Email support at support@waylight.me"
                        data-testid="footer-support-email"
                      >
                        support@waylight.me
                      </a>
                    </li>
                    <li className="flex">
                      <img
                        src="/img/telegram-support.svg"
                        className="inline-block w-5 h-5 mr-3"
                        alt="telegram-support"
                        data-testid="footer-support-telegram-icon"
                      />
                      <a
                        className="hover:underline"
                        href="https://t.me/waylightsupportbot"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Link to Waylight Support Bot on Telegram"
                        data-testid="footer-support-telegram"
                      >
                        @waylightsupportbot
                      </a>
                    </li>
                    <li className="flex">
                      <img
                        src="/img/whatsapp.svg"
                        className="inline-block w-5 h-5 mr-3"
                        alt="whatsapp"
                        data-testid="footer-support-whatsapp-icon"
                      />
                      <a
                        className="hover:underline"
                        href="https://api.whatsapp.com/send/?phone=13024672761"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Start WhatsApp chat with +1-302-467-2761"
                        data-testid="footer-support-whatsapp"
                      >
                        @waylight
                      </a>
                    </li>
                  </ul>
                </div>
                <div
                  className="w-full pt-8 border-t border-gray-800 md:border-t-0 md:w-auto md:pt-0"
                  data-testid="footer-contact-section"
                >
                  <h3 className="font-semibold text-gray-400 text-sm uppercase">
                    {t("contactUs")}
                  </h3>
                  <ul className="mt-4 space-y-3 text-gray-100 pb-8 md:pb-0">
                    <li className="flex">
                      <MailIcon className="inline-block w-5 h-5 mr-3" />
                      <a
                        className="hover:underline"
                        href="mailto:info@waylight.me"
                        aria-label="Email support at support@waylight.me"
                        data-testid="footer-contact-email"
                      >
                        info@waylight.me
                      </a>
                    </li>
                    <li className="flex">
                      <DeviceMobileIcon className="inline-block w-5 h-5 mr-3" />
                      <a
                        aria-label="Call number +1-302-467-2761"
                        className="hover:underline"
                        href="tel:1-302-467-2761"
                        data-testid="footer-contact-phone"
                      >
                        +1-302-467-2761
                      </a>
                    </li>
                    <li className="flex">
                      <LocationMarkerIcon className="inline-block w-5 h-5 mr-3" />
                      <a
                        className="hover:underline"
                        href="https://goo.gl/maps/kVpugA4aszXAgEyg6"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Google Maps link to 7209 Lancaster Pike, Suite 4-1282, Hockessin, DE, 19707, USA"
                        data-testid="footer-contact-address-us"
                      >
                        7209 Lancaster Pike, Suite 4-1282 <br />
                        Hockessin, DE <br />
                        19707 <br />
                        USA
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex flex-col justify-between py-8 md:flex-row md:items-end md:mt-7">
                <span
                  className="hidden mt-5 text-gray-400 md:flex flex-col md:mt-0"
                  data-testid="footer-rights-reserved"
                >
                  <span>{t("allRightsReserved")}</span>
                </span>

                <ul
                  className="flex gap-6 h-6 xl:mr-10"
                  data-testid="footer-social-links"
                >
                  <li className="w-20 relative">
                    <Image
                      loading="eager"
                      src="/img/client-landing/visa.svg"
                      layout="fill"
                      alt=""
                      data-testid="footer-payment-visa"
                    />
                  </li>
                  <li
                    data-testid="footer-social-telegram"
                    className="w-6 relative"
                  >
                    <a
                      href="https://t.me/waylightme"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Link to Waylight Telegram account"
                    >
                      <Image
                        loading="eager"
                        src="/img/social-links/telegram.svg"
                        layout="fill"
                        alt=""
                      />
                    </a>
                  </li>
                  <li
                    data-testid="footer-social-tiktok"
                    className="w-6 relative"
                  >
                    <a
                      href="https://www.tiktok.com/@waylightme"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Link to Waylight Tiktok account"
                    >
                      <Image
                        loading="eager"
                        src="/img/social-links/tiktok.svg"
                        layout="fill"
                        alt=""
                      />
                    </a>
                  </li>
                  <li
                    data-testid="footer-social-youtube"
                    className="w-6 relative"
                  >
                    <a
                      href="https://youtube.com/channel/UCgJWe3BMMsPWHeD8uIHZXaA"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Link to Waylight Youtube account"
                    >
                      <Image
                        loading="eager"
                        src="/img/client-landing/icons/youtube.svg"
                        layout="fill"
                        alt=""
                      />
                    </a>
                  </li>
                  <li
                    data-testid="footer-social-facebook"
                    className="w-6 relative"
                  >
                    <a
                      href="https://www.facebook.com/waylightme/"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Link to Waylight Facebook account"
                    >
                      <Image
                        loading="eager"
                        src="/img/client-landing/icons/facebook.svg"
                        layout="fill"
                        alt=""
                      />
                    </a>
                  </li>
                  <li
                    data-testid="footer-social-instagram"
                    className="w-6 relative"
                  >
                    <a
                      href="https://instagram.com/waylightme"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Link to Waylight Instagram account"
                    >
                      <Image
                        loading="eager"
                        src="/img/social-links/instagram.svg"
                        layout="fill"
                        alt=""
                      />
                    </a>
                  </li>
                  <li
                    data-testid="footer-social-linkedin"
                    className="w-6 relative"
                  >
                    <a
                      href="https://www.linkedin.com/company/waylightme"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Link to Waylight Linkedin account"
                    >
                      <Image
                        loading="eager"
                        src="/img/client-landing/icons/linkedin.svg"
                        layout="fill"
                        alt=""
                      />
                    </a>
                  </li>
                </ul>
                <span
                  className="mt-5 text-gray-400 md:hidden md:mt-0 flex flex-col"
                  data-testid="footer-rights-reserved-mobile"
                >
                  <span>{t("allRightsReserved")}</span>
                </span>
              </div>
            </div>
          </footer>

          <Head>
            <meta charSet="utf-8" />
            <title>{t("businessTitle")}</title>
            <meta name="description" content={t("businessDescription")} />
            <meta property="og:title" content={t("businessTitle")} />
            <meta
              property="og:description"
              content={t("businessDescription")}
            />
            <meta
              property="og:image"
              content="https://waylight.me/img/waylightlinkinbiofbhq.png"
            />
            <meta property="og:url" content="https://waylight.me" />
            <meta name="twitter:title" content={t("businessTitle")} />
            <meta
              name="twitter:description"
              content={t("businessDescription")}
            />
            <meta
              name="twitter:image"
              content="https://waylight.me/img/waylightlinkinbiotwitterhq.png"
            />
            <meta name="twitter:card" content="summary_large_image" />
          </Head>
          {process.env.NODE_ENV === "production" ? (
            <Script
              id="waylightgtm"
              dangerouslySetInnerHTML={{
                __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM}');`,
              }}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};
