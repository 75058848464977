import type { AppProps } from "next/app";
import "components/DateComponent/date-component.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "tippy.js/dist/tippy.css";
import "libs/react-step-range-slider/StepRangeSlider.css";
import "../index.css";
import "lazysizes";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import dynamic from "next/dynamic";
import { useEffect } from "react";
import useLocation from "hooks/useLocation";
import { useRouter } from "next/router";
import { ReactQueryProvider } from "react-query-provider";
import Head from "next/head";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const ToastContainer = dynamic(
  () => import("react-hot-toast").then((module) => module.Toaster),
  { ssr: false }
);

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const location = useLocation();
  const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_CLIENT_KEY!);

  useEffect(() => {
    if (
      location?.country === "RU" ||
      location?.country === "AF" ||
      location?.country === "IR" ||
      location?.country === "SY" ||
      location?.country === "VE" ||
      location?.country === "CU" ||
      location?.country === "KP"
    ) {
      router.push("/stop-war");
    }
  }, [location]);

  return (
    <ReactQueryProvider>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Head>
      <Elements stripe={stripePromise}>
        <Component {...pageProps} />
      </Elements>

      <ToastContainer
        position="bottom-right"
        toastOptions={{ duration: 3000 }}
      />
    </ReactQueryProvider>
  );
}

export default MyApp;
