export const it = {
  next: "Avanti",
  youWontBeCharged:
    "L'addebito non avverrà fino alla conferma dei dettagli della sessione.",
  home: "Casa",
  chats: "Chat",
  ratings: "Valutazioni",
  settings: "Impostazioni",
  moreSettings: "Altre impostazioni",
  settingsNotify: "Impostazioni | Waylight",
  logOut: "Disconnettersi",
  changePassword: "Modifica della password",
  receiveResetPassword:
    "Fare clic su 'Reimposta' per ricevere un link di reimpostazione della password.",
  enterEmailForResetPassword:
    "Inserite il vostro indirizzo email per ottenere un link per la reimpostazione della password.",
  successResetPassword: "Email di reset della password inviata!",
  failResetPassword: "Impossibile reimpostare la password",
  reset: "Reset",
  signOut: "Esci",
  availableBooking: "Disponibile per la prenotazione",
  unavailableBooking: "Non disponibile per la prenotazione",
  shareProfile: "Condividi il profilo",
  requests: "Richieste",
  hours: "Ore",

  totalEarned: "Totale guadagnato",
  totalHours: "Ore totali",

  runningNow: "è in esecuzione ora",

  offer: "Offerta",
  gallery: "Galleria",
  photo: "Foto",

  occupation: "Occupazione",
  skills: "Competenze",
  experts: "Esperti",
  noInformation: "Nessuna informazione",
  speaks: "Parla",
  socialLinks: "Collegamenti sociali",

  contacts: "Contatti",
  noOffersYet: "Nessuna offerta disponibile",
  all: "Tutti",
  requested: "Richiesto",
  confirmed: "Confermato",
  passed: "Passato",
  joined: "Iscritto",
  ongoing: "In corso",
  canceled: "Annullato",
  rejected: "Rifiutato",
  approved: "Approvato",
  calendar: "Calendario",
  favorites: "Seguito",
  removeAll: "Rimuovere tutti",
  viewProfile: "Visualizza il profilo",
  today: "Oggi",
  upcoming: "Prossimamente",
  attachments: "Allegati",

  prepare: "Preparare",

  prerequisites: "Prerequisiti",
  download: "Scaricare",
  past: "Il passato",
  decline: "Declino",
  accept: "Accettare",

  wantDeclinePaidSession:
    "Siete sicuri di voler rifiutare la richiesta? Il pagamento del cliente sarà rimborsato.",
  wantDeclineAfterApprove:
    "È sicuro di voler cancellare? Il pagamento del cliente sarà rimborsato.",
  videoCall: "Avvio del flusso",

  findExpert: "Trova un esperto",
  selectAnExpert: "selezionare un esperto",
  inviteClients: "invitate i vostri clienti",
  selectAChat: "Selezionare una chat per avviare la messaggistica",
  noChatsYet: "Ancora nessuna chat",
  notHaveMessages: "Non ci sono ancora messaggi",
  shareProfileToClient: "Condividere il profilo con il cliente",
  remove: "Rimuovere",
  time: "Tempo",
  timeSlots: "Fasce orarie",
  deleteOffer: "Cancellare l'offerta",
  myOffers: "Offerte",

  createNewOffer: "Creare una nuova offerta",
  editOffer: "Modifica offerta",
  successfulyBooked: "Sessione prenotata con successo",
  close: "Chiudere",
  account: "Conto",
  price: "Prezzo",
  priceAndLength: "Prezzo e durata",

  uploadAFile: "Caricare un file",
  uploadAFileError: "Il file non è stato caricato",
  confirm: "Confermare",
  IDLogin: "Il tuo indirizzo email",
  myProfile: "Profilo del cliente",
  transactions: "Transazioni",
  transactionHistory: "Storia delle transazioni",
  noTransactions: "Non ci sono ancora transazioni",

  typicalSchedule: "Orario tipico",
  amount: "Importo",
  status: "Stato",
  paid: "Pagato",
  action: "Azione",
  description: "Descrizione",
  billing: "Fatturazione",
  card: "Scheda",
  clients: "Clienti",

  payout: "Pagamenti",
  firstName: "Nome",
  lastName: "Cognome",
  skip: "Salto",
  connect: "Collegare",
  about: "Circa",
  title: "Titolo",
  languages: "Le lingue",
  currency: "Valuta",
  addYourOffer: "Aggiungi la tua offerta",
  join: "Unirsi",
  joinIn: "Partecipa",
  joinOn: "Partecipa a",
  message: "Messaggio",
  client: "Cliente",
  expert: "Esperto",
  Expert: "Esperto",
  noMoreOptions: "Non ci sono più opzioni",
  male: "Uomo",
  female: "Donna",
  custom: "Personalizzato",
  copyYourLink: "Copia il tuo link",
  done: "Fatto",
  search: "Ricerca",
  searchWaylight: "Trova il tuo Waylight",
  suggested: "Suggerito",

  startASession: "Sessione rapida",
  quickSession: "Sessione rapida",
  rate: "Tasso",
  perHour: "all'ora",
  packages: "Pacchetti",
  timezone: "Fuso orario",
  book: "Libro",
  favorite: "Preferito",
  lastSession: "Ultima interazione",
  ago: "Ago",
  gender: "Genere",
  loading: "Caricamento",
  min: "Min",
  hour: "Ora",
  online: "In linea",
  offline: "Non in linea",
  date: "Data",
  displayName: "Nome visualizzato",
  closePanel: "Chiudere il pannello",
  goToStripe: "Aprire il cruscotto dei pagamenti",
  completeStripeOnBoarding: "Completare l'onboarding di Stripe Express",
  stripeOnBoarding: "Onboarding con Stripe",
  completed: "completato",
  notCompleted: "non completato",
  myClients: "I miei clienti",
  findClients: "Non riusciamo a trovare clienti",
  clientDisplayedHere:
    "Quando si trovano dei clienti, questi vengono visualizzati qui",
  writeSomething: "Immettere qualcosa e premere invio per creare l'elemento",

  registerAs: "Registrati come:",
  addedCards: "Le vostre carte",
  cardType: "Tipo di carta",
  billingHistory: "Storia della fatturazione",
  deleteCard: "Cancellare la scheda",
  wantToDeleteCard: "Sei sicuro di voler cancellare la scheda?",
  selectPaymentMethod: "Selezionare il metodo di pagamento",
  addNewCard: "Aggiungi una nuova scheda",
  emailAddress: "Email pubblica",
  phoneNumber: "Numero di telefono",
  publicPhoneNumber: "Numero di telefono pubblico",
  username: "Nome utente",
  socialProfilesOrLinks: "Profili sociali / Altri link",
  newOffer: "Nuova offerta",
  myExperts: "I miei esperti",
  myClientProfile: "Il profilo del mio cliente",
  noExpertsFound: "Nessun esperto trovato",
  openMenu: "Aprire il menu",
  becomeAnExpert: "Unisciti a Waylight Business",
  switchToExpert: "Passare alla vista business",
  switchToClient: "Passare alla vista client",
  notHaveExperts: "Non ci sono ancora esperti",
  endCall: "Interrompere il flusso",
  endSharing: "Smettere di condividere",
  lastOnline: "Ultimo online",
  clientSince: "Cliente da",
  welcome: "Benvenuti",
  messageError: "Campo obbligatorio",
  support: "Supporto",
  profile: "Profilo",
  portfolio: "Portafoglio",
  publicId: "ID pubblico",
  files: "File",
  reason: "Motivo",
  declineReason: "Fate sapere al vostro cliente perché dovete rifiutare",
  rejectionReason: "Motivo del rifiuto",
  business: "Affari",
  passwordResetInvalidEmail: "L'email non è valida",
  logIn: "Accedi",
  logInSubtitle:
    "Per eseguire qualsiasi azione, è necessario effettuare l'accesso o la registrazione.",
  enterEmail: "Inserire l'email",
  enterPassword: "Password",
  capsLockIsOn: "Il blocco delle maiuscole è attivo",
  numLockIsOn: "Il blocco numerico è attivo",
  forgotPassword: "Avete dimenticato la password?",
  forgotPasswordEmail:
    "Inserite il vostro indirizzo email per ottenere un link per la reimpostazione della password.",
  requestPasswordReset: "Richiesta di reimpostazione della password",
  requestResetLink: "Reinvia il link",
  in: "In",
  sec: "Sec",
  passwordResetRequestReceived:
    "Richiesta di reimpostazione della password ricevuta",
  passwordResetIfAccountExist:
    "Se esiste un account, riceverete presto un'email con un link.",
  resetPassword: "Reimpostare la password",
  passwordChangeSuccessful: "La password è stata modificata con successo",
  passwordChangeSuccessfulSubtitle:
    "Ora è possibile utilizzare la nuova password per accedere al proprio account.",
  setNewPassword:
    "Impostare una nuova password per accedere al proprio account",
  passwordChange: "Modifica della password",
  passwordOld: "Vecchia password",
  passwordNew: "Nuova password",
  dontHaveAccount: "Non hai un account?",
  alreadyRegistered: "Avete già un account?",
  tokenExpired: "Gettone scaduto",
  tokenExpiredDescription:
    "Questo token è scaduto o è già stato utilizzato in precedenza.",
  validLength: "8 o più caratteri",
  upperCase: "Lettere maiuscole (A-Z)",
  lowerCase: "Lettere minuscole (a-z)",
  numbersOrSymbols: "Numeri o simboli (0-9!@#$%^&*)",
  joinFree: "Iscriviti gratuitamente",
  businessTitle: "Waylight | Strumento Link in BIO che ti aiuta a guadagnare!",
  businessDescription:
    "Guadagna 10 volte di più sulle tue entrate dai social media! Lanciati in soli 2 minuti!",

  spotlightYourExperience:
    "Strumenti completi per i vostri flussi di reddito da contenuti, esperti e comunità",
  monetizationPlatform: "Studio di Business Online",
  createAccount: "Creare un account",
  watchVideo: "Guarda il video",
  bestToolsAvailable:
    "Vogliamo che abbiate i migliori strumenti disponibili e non vi faremo pagare per usarli.",
  schedulingManagement: "Gestione della programmazione.",
  chooseConvenientTimeSlots:
    "Scegliete le fasce orarie più convenienti per il lavoro e ottenete le prenotazioni.",
  expertLandingPayouts: "Pagamenti.",
  simpleOnboarding: "Semplice onboarding dei vostri conti finanziari.",
  industryStandard:
    "Qualità e disponibilità delle chiamate standard del settore.",
  lead: "Piombo",
  engage: "Impegnarsi",
  frequentlyAskedQuestions: "Domande frequenti",
  contactUs: "Contattateci:",
  accordionLabel0: "Se il servizio è gratuito, come funziona Waylight?",
  accordionText0:
    "Prendiamo una commissione basata sul volume delle spese peritali e le chiamate di scoperta e di beneficenza sono a carico nostro!",
  accordionLabel1: "Supportate più tipi di offerta?",
  accordionText1:
    "Assolutamente sì, potete creare tutte le offerte che volete, specificando il prezzo, la durata e i dettagli di ogni offerta.",
  accordionLabel2: "Come vengo pagato?",
  accordionText2:
    "Utilizziamo Stripe e altri gateway per gestire i pagamenti. È necessario un semplice onboarding per collegare il conto bancario o il metodo di pagamento, che funziona in più di 55 Paesi. Sono in arrivo altri metodi di pagamento e impostazioni di pagamento flessibili.",
  accordionLabel3: "Posso usare Zoom, Meet o Skype?",
  accordionText3:
    "Abbiamo una soluzione video completamente integrata, costruita per garantire un flusso sicuro per entrambe le parti. L'utilizzo di altre soluzioni video con minore sicurezza ma maggiore flessibilità sarà un'opzione in futuro.",
  accordionLabel4: "Posso utilizzare Zapier, Calendly ecc.",
  accordionText4:
    "Stiamo lavorando attivamente a un backlog delle integrazioni più richieste dai nostri utenti. Saremo lieti di ascoltare le vostre idee in merito. Scrivete una breve email a support@waylight.me. Grazie!",
  freeAudience: "Noi sviluppiamo - voi create!",
  businessProfile: "Profilo aziendale.",
  onlineBusiness:
    "Create un business online in pochi minuti, mettendo in luce ciò che sapete fare meglio.",
  paidCalls: "Chiamate audio e video a pagamento.",
  paidChatsAndBlog: "Chat e blog a pagamento.",
  setUpSubscription: "Impostare l'abbonamento per la chat e il blog personale.",
  web3: "Web3 valutazioni e recensioni.",
  collectRealRatings:
    "Raccogliete valutazioni e recensioni reali per costruire il vostro marchio personale.",
  getStartedToday:
    "Tre semplici passi per ottenere un nuovo flusso di entrate:",
  createProfile: "1. Creare il profilo",
  fillTemplate:
    "Compilate un modello precostituito del vostro profilo, del calendario e delle offerte, impostate i prezzi per la chat e il blog a pagamento e in pochi minuti sarete pronti ad accettare ordini e abbonati.",
  shareIt: "2. Condividerlo con il mondo",
  linkYourProfile:
    "Collegate il vostro profilo a tutti i vostri canali: social media, annunci, email e altro ancora. Condividete informazioni sulla vostra attività online in post, storie, video, messaggi personali, ecc.",
  growYourBusiness: "3. Far crescere la propria attività",
  shareYourExpertise:
    "Condividete le vostre competenze e conoscenze, aggiornate il vostro blog, rispondete alle prenotazioni e alle chat, fate video-consulenze online e ottenete le vostre valutazioni e recensioni pubbliche su Web3.",
  businessflow: "Flusso",
  businessflowStep1Title: "Aggiungi link in BIO",
  businessflowStep1:
    "Aggiungete il link del vostro profilo Waylight Business nei social network BIO (Instagram, Facebook, YouTube, LinkedIn, Twitter, TikTok, ecc.) e condividete informazioni attraverso storie e post su di esso.",
  businessflowStep2Title: "Videochiamate 1:1, chat 1:1, blog personale",
  businessflowStep2:
    "Monetizzate attraverso videochiamate 1:1, chat 1:1 con i vostri abbonati e clienti. Creare contenuti riservati agli iscritti nel vostro blog personale con tutorial, articoli, video consigli, backstage, esperienze di vita personale, lezioni, musica, ecc.",
  businessflowStep3Title: "Essere pagati",
  businessflowStep3:
    "Fatevi pagare per le videochiamate, le chat e gli abbonamenti riservati ai membri nel vostro blog personale. Aumenta la tua monetizzazione condividendo più informazioni sul tuo Waylight!",
  bookOnboardingCall: "Prenotate una call di onboarding gratuita",
  talkToFounders: "Dite ai fondatori come iniziare a monetizzare con Waylight.",
  orScanQR: "Oppure scansiona il QR",
  yourFreeTools: "I vostri strumenti gratuiti",
  features: "Caratteristiche",
  personalLink: "URL personale",
  personalQR: "QR personale",
  scheduling: "Programmazione",
  bookings: "Prenotazioni",
  paidChats: "Chat 1:1 a pagamento",
  personalPaidBlog: "Blog personale a pagamento",
  soon: "Presto",
  subscriptions: "Abbonamenti",
  payments: "Pagamenti in oltre 100 paesi",
  payouts: "Pagamenti in oltre 58 paesi",
  cryptoPayments: "Pagamenti in criptovaluta",
  coinsAwarding: "Assegnazione di monete",
  web3Ratings: "Valutazioni e recensioni Web3",
  clientsManagement: "Gestione dei clienti",

  dashboard: "Cruscotto",
  businessCalculator: "Calcolatore del reddito d'impresa Waylight",
  howMuchMoney:
    "Scoprite quanto denaro gli abbonati porterebbero alla vostra attività.",
  estimateNumber: "1. Stima del numero di clienti e abbonati",
  howMuchYouCharge: "2. Quanto può costare una videochiamata o un abbonamento?",
  monthlyIncome: "Reddito mensile",
  newToday: "Nuovo oggi",

  atThisWeek: "Questa settimana",
  welcomeToWaylight: "Benvenuti a Waylight!",
  welcomeToWaylightBusiness: "Benvenuti in Waylight Business!",
  linkToVideo: "Link al video introduttivo",
  writeSentences: "Scrivete alcune frasi su di voi.",
  emptyExpertTitle: "Sembra che questa pagina non esista",
  emptyExpertText:
    "Il link potrebbe essere interrotto, oppure la pagina è stata disattivata o rimossa.",
  profileData: "Dati del profilo",
  headerImageTooltip:
    "Le dimensioni suggerite per l'immagine dell'intestazione sono circa 10:1. Si può provare con qualcosa come 1900x190px o 1280x128px.",
  emailTooltip:
    "Questo è un indirizzo email da mostrare al pubblico (non è il vostro login). È possibile specificare un'email a piacere o rimuoverla del tutto, è facoltativo!",
  timezoneTooltip:
    "Se non riuscite a trovare la vostra città, provate a cercare la zona, ad esempio 'ora legale del Pacifico' o 'ora dell'Europa orientale'.",
  linkToVideoPresentation: "Aggiungi un collegamento esterno",
  expertProfileVideoPresentation: "O carica un file",
  linkToVideoPresentationTooltip:
    "Impostate un link a un video esterno come parte dei vostri dettagli. La maggior parte dei servizi di hosting video esistenti sono supportati. Se si carica un video, questo avrà la priorità su questo link.",
  linkToVideoOfferTooltip:
    "Impostare un link a un video esterno come parte dei dettagli dell'offerta. Sono supportati la maggior parte dei servizi di hosting video esistenti. Se si carica un video, questo avrà la priorità su questo link.",
  phoneTooltip:
    "Si tratta di un numero di telefono da mostrare al pubblico. È facoltativo!",
  socialProfilesTooltip:
    "Aggiungete i link ai vostri profili e siti sociali, come Youtube, Facebook, Linkedin, Instagram o Tiktok.",
  videoPresentationTooltip:
    "Caricate un video da visualizzare nei vostri dettagli. Il video sostituirà il link precedente.",
  videoOfferTooltip:
    "Caricare un video da visualizzare nei dettagli dell'offerta. Il video sostituirà il link precedente.",
  occupationTooltip:
    "Comunicate al mondo la vostra occupazione, ad esempio 'Consulente AR' o 'Top Tiktok Influencer'.",
  skillsTooltip:
    "Aggiungete le vostre competenze in modo che i clienti sappiano in cosa siete bravi, ad esempio 'Imprenditorialità' o 'Genitorialità'.",
  aboutTooltip:
    "Aggiungete una descrizione personale affinché i vostri clienti si sentano accolti e apprezzino il vostro marchio personale.",
  speaksTooltip: "Specificate le lingue che parlate.",
  cropPhoto: "Ritagliare la foto",
  updatePhoto: "Aggiornare la foto del profilo",
  crop: "Coltura",

  videoAudioSettings: "Impostazioni video e audio",
  videoAudioSettingsDescription:
    "Utilizzare queste impostazioni per regolare il video e l'audio",
  cameras: "Macchina fotografica",
  microphones: "Microfono",
  output: "Altoparlanti",
  bgSettings: "Scegliere uno sfondo video",
  toStartAChat: "Per avviare una chat,",
  startAChat: "E avviare una chat.",
  messageToAnExpert: "Invia il tuo primo messaggio a un esperto",
  messageToAClient: "Inviare il primo messaggio a un cliente",
  chatWindowGetToKnow: "1. Conoscere i potenziali clienti e le loro esigenze",
  requestInformation: "2. Se necessario, richiedere ulteriori informazioni",
  notGettingAReply:
    "3. Se non si riceve risposta, si può provare a seguire cortesemente la situazione",

  chatWindowProvideABrief:
    "1. Fornire una breve descrizione di ciò che si sta cercando",
  chatWindowAddAttachments: "2. Aggiungere gli allegati, se necessario",
  describeYourIssue:
    "Descrivete la vostra richiesta e ciò che volete imparare o per cui avete bisogno di aiuto. Se necessario, allegare altri file.",
  getAConfirmation: "Riceverete a breve una conferma dall'esperto.",
  describeTheDetails:
    "Descrivete i dettagli, il prezzo e i prerequisiti della vostra offerta.",
  areYouSure: "Sei sicuro di voler cancellare questa offerta?",
  offerAvailable: "Offerta disponibile",
  offerUnavailable: "Offerta non disponibile",
  notHaveOffers: "Non ci sono ancora offerte",
  createOne: "Createne uno per iniziare a lavorare con i vostri clienti.",
  getPaid: "Si viene pagati al prezzo stabilito per un periodo di tempo fisso.",
  describeYourOffer: "Descrivete la vostra offerta in breve dettaglio.",
  priceLimit: "Il prezzo può essere $0 o da ${{minPrice}} a ${{maxPrice}}.",
  priceLimitForUA:
    "Il prezzo può essere ₴0 o da ₴{{minPrice}} a ₴{{maxPrice}}.",
  offerNameDescription:
    "Provate qualcosa come 'Discovery Call' o 'Basic Graphic Design', se è la vostra passione.",
  clickingConfirm: "Facendo clic su 'Conferma', accetto ...",
  termsAndConditions: "Termini e condizioni",

  paymentFeesMayAlter:
    "Le commissioni del sistema di pagamento possono variare.",
  notBeCharged: "Non sarai mai addebitato senza ulteriore conferma.",
  last: "Ultimo 4",
  lastDigits: "Ultime 4 cifre",
  billingPageName: "Nome",
  gateway: "Porta d'ingresso",
  prepareForTheMeeting: "Preparare la riunione",
  prepareForSession: "Prepararsi per",

  findAQuiet: "Trovate un posto tranquillo, dove nessuno vi disturba.",
  makeSure: "Assicuratevi di avere una buona connessione a Internet",
  weRecommendUsing: "Si consiglia di utilizzare le cuffie",
  prepareYourQuestions: "Preparare le domande",

  initialization: "Inizializzazione...",
  beginOnboardingAsExpert:
    "Iniziare l'onboarding come esperto di Waylight Business",
  connectStripeAccount:
    "Per ricevere denaro dai vostri clienti, dovete creare un metodo di pagamento.",
  provideABrief: "1. Fornire una breve descrizione di ciò che si sta cercando",
  addAttachments: "2. Aggiungere gli allegati, se necessario",
  gettingAReply:
    "3. Se non si riceve risposta, si può provare a seguire cortesemente la situazione",

  fileType: "Il tipo di file non è supportato",
  orDragAndDrop: "Oppure trascinare e rilasciare",
  upTo: "fino a 1 GB (contatta il supporto se hai dimensioni di file più grandi)",
  headerImage: "Immagine di testata",
  inviteClientsModalInviteClients: "Invitare i clienti",
  shareYourLink:
    "Condividete il vostro link personale per invitare i vostri clienti!",
  addDays: "Impostate il vostro programma regolare",
  publiclyInformation:
    "È possibile aggiungere più fasce orarie per ogni singolo giorno della settimana.",
  availableTimeslot: "Slot disponibile",
  AddTimeslot: "Aggiungere un timeslot",
  AddToSchedule: "Aggiungi al programma",
  signUpAs: "Iscriviti come",
  registerAsClient: "Voglio imparare qualcosa di nuovo",
  registerAsExpert: "Voglio condividere la mia esperienza",

  noTimeslots:
    "Non ci sono fasce orarie disponibili. Scegliete un'altra data o chiedete all'esperto gli orari.",
  toastSavedSuccessfully: "Salvato con successo",
  toastDeletedSuccessfully: "Eliminato con successo",
  toastEmailCopied: "Email copiata",
  toastProfileCopied: "Profilo copiato",
  toastBookingAvalable: "Ora siete disponibili per la prenotazione",
  toastBookingUnavalable: "Non siete disponibili per la prenotazione",
  toastQrCopied: "Codice QR copiato",
  toastPaymentMethodAdded: "Metodo di pagamento aggiunto con successo",
  toastPaymentMethodDeleted: "Metodo di pagamento cancellato con successo",
  toastUrlCopied: "Link personale copiato",
  toastOfferUrlCopied: "Link dell'offerta copiato",

  toastPayoutMethodAdded: "Metodo di pagamento aggiunto con successo",
  toastPayoutMethodDeleted: "Metodo di pagamento cancellato con successo",
  toastPrimaryCardError: "Impossibile impostare la scheda primaria",
  toastPrimaryCardAdded: "Scheda primaria aggiunta con successo",
  toastTooLargeFile: "Le dimensioni del file sono eccessive",
  toastOfferNotAdded: "L'offerta non è stata aggiunta",
  toastOfferNotEdited: "L'offerta non è stata modificata",
  toastSessionLinkCopied: "Collegamento alla sessione copiato",
  toastMaxParticipantsReached: "Numero massimo di partecipanti raggiunto",

  toastLinkExpired: "Questo link è già scaduto.",

  toastSessionExtendedSuccessfully: "La sessione è stata estesa con successo",
  toastTimeslotStartAndEnd:
    "Non è possibile aggiungere una fascia oraria che inizia e termina alla stessa ora.",
  toastTimeslotEndsInPast:
    "Non è possibile aggiungere una fascia oraria che termina nel passato",
  toastTimeslotDuplicate:
    "Non è possibile aggiungere una fascia oraria duplicata",
  toastSkillNameTooLong:
    "Il nome della vostra abilità è troppo lungo, provate a scegliere un nome più corto",
  buttonSave: "Risparmiare",
  buttonCancel: "Annullamento",
  buttonApprove: "Approvare",
  buttonDelete: "Cancellare",
  buttonChat: "Chat",
  buttonOpenChat: "Chat",
  buttonFiles: "File",
  buttonNotNow: "Non ora",
  buttonSubmit: "Invia",
  buttonNo: "No",
  buttonEnd: "Fine",
  buttonSignUp: "Iscriviti",
  buttonYes: "Sì",
  buttonEdit: "Modifica",
  buttonCopy: "Copia",
  buttonReply: "Risposta",
  buttonAdd: "Aggiungi",
  buttonSend: "Inviare",
  buttonRequest: "Richiesta di supporto",
  buttonSet: "Set",
  buttonEditPhoto: "Modifica foto",
  buttonStartAnyway: "Iniziare comunque",
  buttonWait: "Attendere",
  buttonCopyLink: "Copiare il link",
  buttonLeave: "Lasciare",
  buttonExtraMinutes: "10 minuti extra",
  buttonViewOffer: "Visualizza i dettagli",
  buttonMoreDetails: "Maggiori dettagli",
  buttonContinue: "Continua",
  buttonBookACall: "Prenota una chiamata",
  notificationSetup: "Impostazione",
  notificationFillProfile: "Profilo di riempimento",
  notificationHi: "Ciao!",
  notificationAddYourName:
    "Aggiungete il vostro nome e la vostra foto per ravvivare la comunicazione, se ne avete voglia. Nessuna pressione :)",
  notificationSetupBilling: "Impostazione della fatturazione",

  notificationGoToBilling: "Vai alla Fatturazione",
  notificationWeAreSureYouHaveALotToSay:
    "Siamo certi che avete molto da dire su di voi. Compila il tuo profilo e inizia a condividere!",
  notificationAddAnOffer: "Aggiungere un'offerta",
  notificationAddYourOffers: "Aggiungete le vostre offerte",

  notificationGoToMyOffers: "Vai alle offerte",
  notificationConnectStripe: "Impostazione dei pagamenti",
  notificationSetupPayouts: "Onboarding dei pagamenti",
  notificationInOrderToReceiveMoney:
    "Per ricevere denaro dai clienti, è necessario creare un metodo di pagamento.",
  notificationGoToPayouts: "Vai a Pagamenti",
  notificationSetYourSchedule: "Impostare il programma",
  notificationWorkAtConvenientHours: "Lavorare in orari comodi",

  accountOn: "Conto su",
  accountOff: "Account disattivato",

  paymentFailed: "Pagamento fallito",

  areYouSureCancelPaidSession:
    "Siete sicuri di voler cancellare? Il pagamento sarà rimborsato.",
  areYouSureCancelLessThanADay:
    "Siete sicuri di voler annullare l'iscrizione con meno di 24 ore di anticipo? Il pagamento NON sarà rimborsato.",
  link: "Collegamento",
  view: "Vista",
  requires_action: "Richiede un'azione",
  succeeded: "Riuscito",
  signUpTitle: "Iscriviti",
  profileTitleNotify: "Profilo | Waylight",
  dashboardTitleNotify: "Cruscotto | Waylight",
  chatTitleNotify: "Chat | Waylight",
  with: "con",
  expertsTitle: "Esperti",
  expertsTitleNotify: "Esplora | Waylight",
  signUpTitleNotify: "Iscriviti a Waylight",
  chatTitle: "Chat",
  clientsTitle: "Clienti",
  clientsTitleNotify: "Clienti | Waylight",
  clientTitle: "Cliente",
  fixedPrice: "Prezzo fisso",
  internetConnectionLost: "Perdita della connessione a Internet",
  fiveMinutesLeft: "5 minuti al termine",
  summarizeMeeting:
    "Riassumete il vostro incontro e condividete le vostre impressioni",
  oopsConnectionLost: "Ops... La connessione al partito è stata persa",
  partyTemporaryLostConnection:
    "Sembra che il vostro gruppo abbia temporaneamente perso la connessione alla riunione. Ci scusiamo per l'inconveniente.",
  leaveAReviewFor: "Lascia un commento per",
  leaveAReview: "Lascia una recensione",
  review: "Recensione",
  followers: "Seguaci",
  rating: "Valutazione",
  hitStarRate: "Valutate la sessione qui sotto!",
  reviewsEmpty: "Non ci sono ancora recensioni",
  reviews: "Recensioni",

  thankYouForLighting: "Grazie per aver illuminato il cammino di qualcuno!",
  sessionNotStarted: "La sessione non è stata avviata",
  devicePermissionTittle:
    "Consentire a Waylight di accedere al microfono e alla telecamera. Aggiornare la pagina se non si dispone di video.",
  networkConnection: "La qualità della connessione a Internet è",
  unknown: "Sconosciuto",
  low: "Basso",
  average: "Media",
  good: "Buono",
  expertDidNotCome:
    "Purtroppo il vostro esperto non si è presentato. Non le verrà addebitato nulla e riceverà un rimborso. Ci scusiamo per l'inconveniente.",
  noFilesAdded: "Nessun file aggiunto.",
  enterYourEmail: "Inserire l'indirizzo email",
  required: "Il campo è obbligatorio",
  emailOrPassword: "Email o password errata",
  emailInUse: "Questa email è già in uso",
  businessIsNotAvailableTitle:
    "Waylight Business non è disponibile nel vostro Paese. Siate i primi a sapere quando verrà lanciato.",
  beInTouch: "Rimanere in contatto",
  businessIsNotAvailableEmailAddress: "Indirizzo email",
  pleaseTryAgain:
    "Non siamo riusciti a salvare il suo indirizzo email. Si prega di riprovare.",
  thankYou:
    "Grazie, ci metteremo in contatto con voi non appena sarà disponibile.",

  yourCountry: "Il paese della vostra banca",
  open: "Aperto",
  offerName: "Nome dell'offerta",
  gatewayCountryWarning:
    "Attenzione: questo deve essere il Paese del vostro istituto bancario e NON può essere modificato in seguito, il che potrebbe influire sulla vostra capacità di ricevere i pagamenti.",
  addAttachment: "Aggiungi allegato",
  you: "Tu",
  explore: "Esplorare",
  stats: "Statistiche",
  more: "Vedi di più",
  noPrice: "Offerte gratuite",
  theyWillAppear: "Appariranno qui quando si inizia a interagire",
  noExpertFoundUsingFilter: "Nessun esperto trovato utilizzando questo filtro.",
  tryAnotherInput: "Provare con un altro tag o input.",
  noFavoriteExpertsFound: "Non sono stati trovati esperti preferiti.",
  tryAddingSomeone: "Provate ad aggiungere qualcuno ai preferiti.",
  agoraDeniedError:
    "Per utilizzare Waylight, abilitare l'accesso al microfono e alla fotocamera nelle impostazioni!",
  editCover: "Modifica della copertina",
  language: "Lingua",
  invalid: "Non valido",

  typeHere: "Digitare qui",
  schedule: "Programma",
  offers: "Offerte",
  qrSharing: "Scaricate e condividete il vostro codice QR.",
  qrSharingMobile: "Premete a lungo sul codice QR per copiarlo e condividerlo.",
  preview: "Anteprima",
  back: "Indietro",
  love: "Realizzato con amore da Waylight",
  allRightsReserved: "© 2024 Waylight Inc. Tutti i diritti riservati.",
  allRightsReservedSecond:
    "© 2022 Arceon Consulting OÜ. Tutti i diritti riservati",
  termsOfService: "Termini di servizio",
  privacyPolicy: "Informativa sulla privacy",
  sessionSharingpopupTitle:
    "Non è possibile avviare la condivisione dello schermo!",
  sessionSharingpopupDescription:
    "Verificate che il vostro browser abbia una versione recente e che disponga dei permessi di sistema per la condivisione dello schermo. Infine, potete provare ad aggiornare la pagina. Se il problema persiste, contattare l'assistenza!",
  sessionSharingpopupSafariDescription:
    "La condivisione dello schermo non è supportata dal browser in uso. Le ultime versioni dei browser più diffusi supportano questa funzione.",
  copy: "Copia",
  expertWithoutOffers:
    "Questo esperto non ha ancora aggiunto offerte a pagamento.",
  errorViewHeader: "Ops, qualcosa è andato storto!",
  errorViewDescription:
    "Si è verificato un errore. Il link potrebbe essere interrotto o non è possibile accedere a un elemento.",
  returnToHome: "Ritorno a casa",
  invalidURL:
    "URL non valido. Assicurarsi che inizi con 'https://' o 'http://'.",
  invalidVideoExternalURL:
    "URL video non valido. Aggiungere un link a Youtube o Vimeo.",
  repeatedURL: "Questo link è già stato aggiunto",
  tooLongUrl:
    "Questo link è troppo lungo. Si prega di non utilizzare link più lunghi di 128 caratteri.",
  edited: "Modificato",
  emptyCards: "Non hai ancora aggiunto nessuna carta",
  emptyBillingHistory: "Non ci sono ancora transazioni",

  addNewSkill: "Aggiungi una nuova abilità",
  confirmationAddSkill: "Siete sicuri di voler aggiungere una nuova abilità?",
  addPaymentMethod: "Aggiungi metodo di pagamento",
  stripeDescription: "Per transazioni con esperti di oltre 50 paesi",
  fondyDescription: "Per le transazioni con esperti dell'Ucraina",
  fondyAddCardDescription:
    "Aggiungere un metodo di pagamento per procedere. Verrà tentato un addebito di prova per conferma.",
  timeZoneChangedHeader: "Fuso orario modificato",
  timeZoneSChangedDescription:
    "Abbiamo rilevato che il suo fuso orario è cambiato. Vuoi aggiornare il fuso orario del tuo profilo?",
  updateButton: "Aggiornamento",
  and: "e",
  moreFiles: "altri file",
  addPayout: "Aggiungere almeno un metodo per ricevere i pagamenti",
  testChargeWarning:
    "Per la conferma, si tenterà di effettuare una carica di prova.",
  payoutMethods: "I vostri metodi di pagamento",
  identifier: "Identificatore",
  stopWar: "Fermare la guerra",
  yourAreOnlyOneHere: "Sei l'unico qui",
  yourAreOnlyOneHereSingle:
    "Sei l'unico qui. Condividi il link qui sotto per far sì che altri si uniscano",
  clientView: "Cliente",
  businessView: "Affari",
  mainPage: "Pagina principale",
  signUpCompleted: "Iscrizione completata",
  selectPaymentInfo:
    "I metodi di pagamento disponibili dipendono dal gateway del vostro esperto.",
  payment: "Pagamenti",
  paymentWasDeclined: "Il pagamento è stato rifiutato.",
  payoutWasDeclined: "Il pagamento è stato rifiutato.",
  primary: "Primario",
  notUsed: "Non utilizzato",
  releaseBannerTitle: "Waylight è stato aggiornato a una nuova versione.",
  refresh: "Aggiornare",
  country: "Paese",
  viewAll: "Visualizza tutti",
  videoPresentation: "Presentazione video",
  bookFor: "Libro per",
  bookForFree: "Prenota gratuitamente",
  follow: "Seguire",
  followed: "Seguito",
  emptyPortfolio: "Sembra che non ci siano file in questo portafoglio.",
  media: "Media",
  other: "File",
  emptyReview: "Sembra che non ci siano recensioni",
  newest: "Il più recente",
  oldest: "Il più vecchio",
  viewProfileInfo: "Visualizza le informazioni sul profilo",
  emptyTransactions: "Sembra che non ci siano transazioni",
  emptyOffers: "Purtroppo questo esperto non ha ancora offerte.",
  searchExperts: "Ricerca di esperti",
  modifySchedule: "Modificare il programma",
  editMode: "Modalità di modifica",
  finishEditing: "Terminare l'editing",
  editPersonalInfo: "Modifica delle informazioni personali",
  editPortfolio: "Modifica del portafoglio",

  profileId: "ID pubblico personale",
  profileSettings: "Impostazioni del profilo",
  editPublicID: "Modifica dell'ID pubblico",
  removeTimeslot: "Rimuovere il timeslot",
  areYouSureRemoveTimeslot: "Sei sicuro di voler rimuovere il timeslot?",
  qrCode: "Codice QR",
  communication: "Comunicazione",
  editCoverDescription: "Personalizzare l'immagine di copertina",
  editProfileData: "Modifica dei dati del profilo",
  editProfileDataDescription:
    "Aggiungete informazioni su di voi e sul vostro background",
  editPublicIDDescription:
    "Scegliete un ID pubblico unico per il vostro profilo",
  profilePhoto: "Foto del profilo",
  publicIDDescription: "Questo è un ID generato automaticamente!",
  publicIDWarning:
    "Non dimenticate di cambiarlo in qualcosa di bello in modalità di modifica, come 'supergirl'.",
  followWarning: "Il pulsante 'Segui' funzionerà per tutti gli utenti.",
  openChatWarning:
    "Non potrete fare una chiacchierata con voi stessi, ma con i vostri clienti sì.",
  bookingOfferWarning:
    "Gli utenti potranno acquistare un'offerta cliccando qui.",
  shareOffer: "Copiare il link",
  turnOffAccount: "Disattivare l'account",
  turnOnAccount: "Attivare il conto",
  areYouSureYouWantTurnOff:
    "Siete sicuri di voler disattivare completamente il vostro account?",
  areYouSureYouWantTurnOn: "Sei sicuro di voler riattivare il tuo account?",
  disableFreeChat: "Disattivare la chat gratuita",
  enableFreeChat: "Abilitare la chat gratuita",
  areYouSureYOuWantDisableFreeChat:
    "Sei sicuro di voler disabilitare la chat gratuita?",
  areYouSureYOuWantEnableFreeChat:
    "Siete sicuri di voler attivare la chat gratuita?",
  cancelEditing: "Annullamento della modifica",
  descriptionCancelEditing: "È sicuro di voler annullare la modifica?",
  takenPublicID: "Questo ID pubblico è già occupato",
  charactersMatch: "2-64 caratteri",
  charactersBetweenMatch: "Il campo deve contenere da 1 a 64 caratteri",
  charactersMaxMatch: "La lunghezza massima del campo è di 64 caratteri",
  deletePhoto: "Cancellare la foto",
  descriptionDeletePhoto: "Sei sicuro di voler cancellare la tua foto?",
  accountOnDescription:
    "Quando l'account è disattivato, non è disponibile e non è visibile in alcun modo (link diretti, risultati di ricerca, ecc.).",
  freechat: "Chat gratuita",
  freechatDescription:
    "Quando la chat gratuita è disattivata, gli utenti non potranno iniziare a comunicare con voi con questo mezzo. Tuttavia, potrete farlo con i vostri clienti.",

  editMessage: "Modifica del messaggio",
  replyTo: "Rispondi a",
  file: "File",

  backgroundWarning:
    "Attenzione: gli sfondi non sono pienamente supportati dai browser Safari e Firefox e potrebbero non funzionare.",
  single: "Veloce",

  connectIn: "Collegati in",
  connectOn: "Connettiti su",
  sessionDecisionTittle: "Il vostro partito non si è presentato.",

  cancelledSessionExpertDescription:
    "Il pagamento è stato trasferito sul suo conto. Ci scusiamo per l'inconveniente.",
  cancelledSessionClientDescription:
    "Non vi verrà addebitato nulla e riceverete un rimborso. Ci scusiamo per l'inconveniente.",

  addExtraMinutes: "Aggiungere altri 10 minuti",

  unableAddExtraMinutes: "Impossibile aggiungere 10 minuti supplementari",

  monday: "Lunedì",
  tuesday: "Martedì",
  wednesday: "Mercoledì",
  thursday: "Giovedì",
  friday: "Venerdì",
  saturday: "Sabato",
  sunday: "Domenica",
  h: "h",
  sharingSetting: "Impostazione di condivisione",
  call: "Chiamata",
  public: "Pubblico",
  byLinkOnly: "Solo tramite link",
  offerInfo: "Informazioni sull'offerta",
  coverVideo: "Copertina / video",
  imageVideo: "Immagine / video",
  chooseCountry: "Scegliere il paese",
  passwordChangedSuccessfully: "La password è stata modificata con successo",
  connected: "Collegato",
  passedALot: "Passato",
  chooseCountryBank:
    "Scegliete il Paese della vostra banca o del vostro servizio finanziario.",
  createAccountContinue: "Creare un account per continuare",
  metasignInTitle: "Accedi a Waylight",
  metasignUpTitle: "Iscriviti a Waylight",
  metapasswordForgotTitle: "Password dimenticata | Waylight",
  metapasswordResetTitle: "Reimpostare la password | Waylight",
  clientflowStep1Title: "Trova il link nella ricerca BIO o Waylight",
  clientflowStep1:
    "Trovate il link Waylight del vostro creatore, influencer, esperto o tutor nei social network (Instagram, Facebook, YouTube, LinkedIn, ecc.) o digitate il nome o il nickname nella ricerca Waylight.",
  clientflowStep2Title:
    "Prenotare una videochiamata o iscriversi al blog o alla chat",
  clientflowStep2:
    "Aprite ed esplorate il profilo aziendale del vostro creatore, influencer, esperto o tutor. Trovate un'offerta interessante per una videochiamata 1:1, prenotatela e fatela all'ora stabilita. Cercate di chattare dopo o prima per ottenere maggiori informazioni a lungo termine. Iscrivetevi al blog personale riservato ai membri con tutorial, articoli, consigli video, backstage, esperienze di vita personale, lezioni, musica, ecc.",
  clientflowStep3Title:
    "Controllare le valutazioni e le recensioni decentralizzate",
  clientflowStep3:
    "Le valutazioni decentralizzate creano un track record per i creatori e gli esperti, non possono essere vendute o falsificate e creano un senso di fiducia, trasparenza, prova di competenza e domanda di contenuti per clienti e abbonati. Lasciate le vostre valutazioni reali dopo le videochiamate, le chat e nei post dei blog riservati ai membri. Condividete le vostre recensioni per aiutare la comunità Waylight a mettere in evidenza le migliori.",
  confirmBooking: "Conferma la selezione",
  free: "Gratuito",
  unableVerifyEmail: "Non siamo riusciti a verificare la sua email.",
  thankYouForVerifiedEmail: "Grazie per aver verificato la tua email!",
  reSend: "Reinvio",
  signInToContinue: "Accedi per continuare",
  switchingToClientView: "Passare alla vista client",
  switchingToExpertView: "Passare alla vista business",
  cookiePermission:
    "Apprezziamo la vostra privacy. Utilizziamo i cookie per migliorare la vostra esperienza di navigazione, proporre contenuti personalizzati e analizzare il nostro traffico. Facendo clic su 'Accetta', si acconsente al nostro utilizzo dei cookie.",
  readMore: "Per saperne di più",
  notReadableDevicesWarning:
    "Attenzione: la fotocamera e/o il microfono sono in uso o non sono accessibili!",
  howPayoutsWork: "Come funzionano i pagamenti?",
  payoutsOnboarding1: "Completare l'onboarding per consentire i pagamenti.",
  payoutsOnboarding2:
    "Teniamo al sicuro il vostro denaro fino al momento in cui salite a bordo.",
  payoutsOnboarding3:
    "A seconda del Paese, sono necessarie le coordinate bancarie o i dati della carta di credito.",
  payoutsOnboarding4: "I pagamenti avvengono ogni lunedì.",
  payoutsOnboarding5:
    "È possibile controllare lo stato dei pagamenti in Transazioni.",
  payoutsOnboarding6:
    "Il gateway di pagamento addebita lo 0,25% + 0,25 dollari per ogni versamento.",
  payoutsOnboardingEcommpay6:
    "Il gateway di pagamento addebita lo 0,5% per le carte e lo 0% per i commercianti se le commissioni del cliente sono abilitate.",
  payoutsOnboarding7:
    "Se è la prima volta che si riceve il pagamento, l'arrivo può richiedere 7-14 giorni lavorativi a causa delle limitazioni del gateway.",
  payoutsOnboarding8:
    "Normalmente i pagamenti richiedono circa 3 giorni lavorativi per arrivare.",
  payoutsOnboarding9:
    "Al momento non è possibile cambiare il paese. Contattateci se ne avete bisogno e cercheremo di risolvere il problema caso per caso.",
  payoutsOnboarding10:
    "Waylight addebita una commissione variabile in base al tuo piano.",
  payoutsOnboarding11: "Se hai domande, contatta",
  supportAlternative: "Supporto",

  cancelledFreeSessionDescription:
    "Lei è l'unico che si è presentato. Ci scusiamo per l'inconveniente.",
  m: "m",
  cityNotFound:
    "Non sono state trovate opzioni. Scegliere la grande città più vicina.",
  notifications: "Notifiche",
  telegramBot: "Telegram Bot",
  notConnected: "non connesso",
  connectTelegramBot: "Connetti Telegram Bot",
  disconnectTelegramBot: "Disconnetti Telegram Bot",
  telegramConnected: "connesso",
  bgSettingsDescription:
    "Utilizzare queste impostazioni per regolare lo sfondo del flusso video",
  selectBackground: "Selezionare lo sfondo",
  bookingDelay: "Impostare un ritardo nella prenotazione",
  bookingDelayDescription:
    "Specificate con quanto anticipo potete essere prenotati.",
  processingFee: "Commissione per il sistema di pagamento",
  payoutsOnboardingEcommpay1:
    "Seleziona il metodo di pagamento personale con carta di credito o imprenditoria privata.",
  payoutsOnboardingEcommpay3:
    "Devi aggiungere almeno una carta di credito personale + il tuo codice fiscale individuale O completare l'onboarding del commerciante Fondy affinché le transazioni funzionino!",
  payoutsOnboardingEcommpay4: "Le pagamenti avvengono dopo ogni transazione.",
  payoutsOnboardingEcommpay7:
    "I pagamenti avvengono entro il successivo giorno lavorativo.",
  tutorials: "Prodotti",
  tutorialsAndCourses: "Acquista prodotti",
  noOffers: "Sembra che non ci siano ancora offerte qui",

  newTutorialOffer: "Nuova offerta di prodotto",
  newTutorialOfferDescription: "Crea un prodotto a pagamento o gratuito",

  tutorialName: "Nome del prodotto",
  tutorialNameDescription:
    "Scegli un nome per il tuo prodotto, ad esempio “Classe di danza n. 1”",
  cover: "Copertina",
  tutorialCoverTooltip:
    "Carica un'immagine o un video da mostrare nella descrizione del prodotto.",
  getTutorialPaid: "Ricevi un pagamento per ogni acquisto di questo prodotto.",
  unlockedAfterPurchase: "Sbloccato dopo l'acquisto",
  unlockedAfterPurchaseDescription:
    "Aggiungi contenuti che i tuoi clienti sbloccheranno.",
  tutorialDetailsDescription: "Aggiungi commenti, dettagli o istruzioni qui",
  removeSection: "Rimuovi sezione",
  addAnotherSection: "Aggiungi un'altra sezione",
  details: "Dettagli",
  toastEmptyUnlockedContent: "Il contenuto bloccato non può essere vuoto",
  toastTutorialNotEdited: "Questo prodotto non è stato modificato",
  removeSectionDescription: "Sei sicuro di voler rimuovere questa sezione?",
  buyFor: "Acquista per",
  buttonOpenChatAlt: "Apri chat",
  buttonShare: "Condividi",
  tutorial: "Prodotto",
  toastBuyingYourOwnTutorial: "Non puoi acquistare il tuo stesso prodotto!",
  purchasingInProgress: "Transazione in corso",
  byClickingConfirm: "Cliccando",
  iAgreeWith: "accetto i",
  allowWaylightToCharge:
    "e permettere a Waylight di addebitare la mia carta ora e in futuro secondo i termini di abbonamento fino a quando non lo cancello.",
  paymentProcessingFees:
    "Potrebbero essere applicate spese di elaborazione del pagamento.",
  bookingTutorialWarning:
    "Gli utenti potranno acquistare un prodotto facendo clic qui.",
  editTutorials: "Modifica prodotti",
  topReview: "Recensione principale",
  addForFree: "Aggiungi gratuitamente",
  tutorialPaymentFailed:
    "Il tuo pagamento non è andato a buon fine e non hai potuto acquistare questo prodotto.",
  buy: "Acquista",
  tutorialWith: "prodotto di",
  purchased: "Acquistato",
  noTutorialsFound: "Nessun prodotto trovato",
  noTutorialsFoundDescription:
    "Appariranno qui quando acquisterai o aggiungerai un prodotto",
  tags: "Tag",

  createTutorial: "Crea un'offerta di prodotto",
  createTutorialDescription: "Aggiungi un prodotto a pagamento o gratuito.",
  requires_transfer: "Richiede onboarding",
  pending: "In attesa di pagamento",
  requires_payout: "In attesa di pagamento",
  requires_capture: "In sospeso",
  reschedule: "Riprogramma",
  rescheduleDelay: "Imposta il ritardo per la riprogrammazione",

  buttonChange: "Riprogramma",
  timeslots: "Fasce orarie",
  specifyReason: "Specifica un motivo per il cambio di data e orario.",

  rescheduleReason: "Motivo della riprogrammazione",
  optionLink: "Aggiungi un link esterno",
  optionUpload: "Oppure carica un file",
  tutorialLinkDescription: "Aggiungi il link al tuo prodotto.",
  tutorialUploadDescription:
    "Carica qui qualsiasi file. Puoi aggiungere un file per sezione.",
  bgNone: "Nessuno",
  bgLowBlur: "Sfocatura bassa",
  bgMediumBlur: "Sfocatura media",
  bgHighBlur: "Sfocatura alta",
  bgSolidColour: "Colore solido",
  bgCustomImage: "Immagine personalizzata",
  telegramNotifications: "Notifiche Telegram",
  connectYourTelegramAccount:
    "Collega il tuo account Telegram per ricevere notifiche da Waylight.",
  buttonConnectTelegram: "Collega Telegram",
  notificationGoToMyProfile: "Vai al mio profilo",
  setupSchedule: "Imposta il programma",
  aboutTheTutorial: "Informazioni sul prodotto",
  added: "Aggiunto",
  orderingChangeInProgress: "Modifica dell'ordine in corso...",
  gloryToUkraine: "Gloria all'Ucraina!",
  terroristStates:
    "Waylight non funziona per gli utenti provenienti da paesi soggetti a regimi autoritari e terroristici: Russia, Iran, Corea del Nord, Siria, Cuba, Venezuela, Afghanistan.",
  never: "Nessun limite",
  expiration: "Scadenza",
  expirationDescription:
    "I clienti avranno accesso a questo prodotto per un certo numero di giorni",
  daysLeft: "giorni rimasti",
  tutorialExpired: "Il tuo accesso a questo prodotto è scaduto.",
  days: "giorni",
  tips: "Donazioni",
  newTipOffer: "Nuova offerta di donazione",
  newTipOfferDescription: "Crea una donazione",
  tipName: "Nome della donazione",
  tipNameDescription: "Scegli un nome per la tua donazione",
  tipCoverTooltip:
    "Carica un'immagine o un video da mostrare nella descrizione della donazione.",
  aboutTheTip: "Informazioni sulla donazione",
  describeYourTip:
    "Aggiungi una descrizione della donazione: raccogliere donazioni, organizzare una charity o comprare un caffè?",
  tipAmounts: "Aggiungi tre importi per la donazione",
  tipAmountsDescription:
    "I tuoi clienti potranno inserire anche il proprio importo se lo desiderano.",
  bonusTipper: "(Opzionale) Invia un bonus al cliente",
  bonusTipperDescription:
    "Aggiungi contenuti o un messaggio che i tuoi clienti riceveranno come bonus dopo aver effettuato la donazione.",
  tipLinkDescription: "Aggiungi il link al tuo bonus.",
  tipMessageDescription: "Aggiungi qui il tuo messaggio di testo.",
  toastTipNotEdited: "Questa donazione non è stata modificata",
  leaveATip: "Lascia una donazione",
  bookingTipWarning:
    "Gli utenti potranno lasciare una donazione cliccando qui.",
  includesAReward: "Include un bonus",
  selectTip: "Seleziona l'importo che desideri donare",
  selectTipAmount: "Seleziona un importo per la donazione",
  toastBuyingYourOwnTip: "Non puoi acquistare la tua stessa donazione!",
  tipSuccessfullyBought: "Donazione acquistata con successo",
  tip: "Donazione",
  tipPaymentFailed:
    "Il pagamento non è andato a buon fine e non è stato possibile acquistare questa donazione.",
  purchasedOffers: "Acquistate",
  noTipsFound: "Nessuna donazione trovata",
  noTipsFoundDescription: "Appariranno qui quando acquisti una donazione",
  thankYouExclamation: "Grazie!",
  editTips: "Modifica donazioni",
  tipPriceLimit: "Il prezzo può essere da ${{minPrice}} a ${{maxPrice}}.",
  tipPriceLimitForUA: "Il prezzo può essere da ₴{{minPrice}} a ₴{{maxPrice}}.",
  cardAuthorization: "Autorizzazione della carta di credito",
  failed: "Fallito",
  communityName: "Nome della community",
  communityNameDescription:
    "Scegli un nome per la tua community, come ad esempio “Il mio canale Telegram esclusivo solo per abbonati“",
  getCommunityPaid: "Prezzo dell'abbonamento",
  Period: "Periodo*",
  expirationCommunityDescription: "Imposta la durata dell'abbonamento",
  aboutTheCommunityOffer: "Informazioni sull'offerta",
  TelegramChannelName: "Nome del canale/gruppo Telegram*",
  communityTelegramNameDescription:
    "Seleziona il nome del tuo canale o gruppo. Se vuoto, fai clic sull'icona delle informazioni per le istruzioni.",
  communities: "Comunità",
  community: "Community",
  subscribeFor: "Abbonati per",
  subscribed: "Abbonato",
  unsubscribe: "Annulla l'abbonamento",
  unsubscribeDescription:
    "Sei sicuro di voler annullare l'abbonamento?\nL'accesso scadrà il",
  subscribeDescription: "{{community}}.",
  usubscribed: "Annullato l'abbonamento",
  unlimited: "Illimitato",
  subscribeAgain: "Abbonati di nuovo",
  unsubscribeDescriptionModal:
    "Sei sicuro di voler abbonarti di nuovo?\nVerrai addebitato per il prossimo periodo il",
  subscribe: "Abbonati",
  currentlyMember: "Attualmente sei un membro di {{title}} su Telegram.",
  notCurrentlyMember: "Attualmente non sei un membro di {{title}} su Telegram.",
  newCommunityOffer: "Nuova offerta della community",
  newCommunityOfferDescription: "Crea o gestisci una community",
  editCommunities: "Modifica le community",
  joinCommunities: "Unisciti alle community",
  noCommunitiesAdded: "Nessuna community aggiunta",
  TheyWillAppear: "Appariranno qui quando creerai le tue offerte.",
  createCommunity: "Crea una community",
  noAccessToData: "Nessun accesso ai dati! Aggiungi",
  noAccessToDataAsAnAdministrator:
    "come amministratore al tuo gruppo per fornire l'accesso.",
  retryPayment: "Riprova il pagamento",
  areYouSureYouWantToSetCard:
    "Sei sicuro di voler impostare la carta *{{last4}} come primaria?",
  thingsToTry: "Cose da provare:",
  checkIfYouHaveSufficient:
    "Controlla se hai fondi sufficienti sul tuo saldo o se i pagamenti su internet sono abilitati.",
  selectDifferentPaymentMethod: "Seleziona un metodo di pagamento diverso.",
  set: "Imposta",
  primaryCard: "Primaria",
  setPrimary: "Imposta come primaria",
  inviteMember: "Invita un membro",
  copyLink: "Copia il link",
  members: "Membri",
  cancel: "Annulla",
  actions: "AZIONI",
  subscribedUntil: "ABBOANTO FINO AL",
  waylightClients: "Clienti WAYLIGHT",
  nameMembers: "NOME",
  admin: "Amministratore",
  external: "Esterno",
  subscriber: "Abbonato",
  unsubscribed: "Annullato l'abbonamento",
  kicked: "Espulso",
  owner: "Proprietario",
  save: "Salva",
  kickAndBan: "Espelli",
  areYouSureYouWantToRemove:
    "Sei sicuro di voler rimuovere “{{name}}” dal tuo canale in modo?",
  WeHaveEncounteredAnIssue:
    "Abbiamo riscontrato un problema con il tuo pagamento ricorrente!",
  yourPaymentHasNotGone:
    "Il tuo pagamento non è andato a buon fine e non siamo stati in grado di riprovare ad addebitare l'importo per il tuo abbonamento.",
  gatewayFee: "I clienti pagano le commissioni del gateway",
  gatewayFeeDescription:
    "Con questa impostazione attivata, la commissione del gateway di pagamento verrà aggiunta alle fatture dei clienti. Quando è disattivata, la commissione verrà detratta dai pagamenti dell'azienda.",
  enableGatewayFee: "Abilitare la commissione del gateway per i clienti?",
  areYouSureYOuWantEnableGatewayFee:
    "Sei sicuro di voler abilitare la commissione del gateway per i clienti?",
  disableGatewayFee: "Disabilitare la commissione del gateway per i clienti?",
  areYouSureYOuWantDisableGatewayFee:
    "Sei sicuro di voler disabilitare la commissione del gateway per i clienti? La commissione verrà detratta dai tuoi pagamenti!",
  communityTooltip:
    "Assicurati di aver aggiunto Waylight Bot al tuo gruppo/canale come amministratore, di aver collegato il tuo account Telegram a Waylight e che questa sia l'unica offerta per questo canale!",
  communityCoverTooltip:
    "Carica un'immagine o un video da visualizzare nella descrizione della community.",
  setCard: "Imposta",
  expirationDateMustBeInFuture: "La data di scadenza deve essere nel futuro",
  accessGranted: "Accesso consentito",
  noAccess: "Nessun accesso",
  manageCommunity: "Gestisci la comunità",
  manage: "Gestisci",
  January: "Gennaio",
  February: "Febbraio",
  March: "Marzo",
  April: "Aprile",
  May: "Maggio",
  June: "Giugno",
  July: "Luglio",
  August: "Agosto",
  September: "Settembre",
  October: "Ottobre",
  November: "Novembre",
  December: "Dicembre",
  toastBuyingYourOwnCommunity: "Non puoi iscriverti alla tua stessa community!",
  priceLimitCommunity: "Il prezzo può essere da ${{minPrice}} a ${{maxPrice}}.",
  priceLimitCommunityForUA:
    "Il prezzo può essere da ₴{{minPrice}} a ₴{{maxPrice}}.",
  toastRetryPaymentSuccess: "Il tuo nuovo tentativo di pagamento è riuscito.",
  toastCommunityLinkCopied: "Link alla community copiato",
  communityPaymentFailed: "Pagamento della community non riuscito",
  AddExpirationDateManually: "Aggiungi data di scadenza manualmente:",
  SetTimeFormat: "Imposta il formato dell'ora",
  events: "Eventi",
  newEventOffer: "Nuova offerta evento",
  newEventOfferDescription: "Crea un evento pianificato",
  eventName: "Nome dell'evento",
  eventNameDescription:
    'Scegli un nome per il tuo evento, come "Masterclass SEO"',
  eventCoverTooltip: "Tooltip copertina evento",
  getEventPaid:
    "Ricevi un pagamento per ogni acquisto di posti in questo evento",
  aboutTheEventOffer: "Informazioni sull'evento",
  dateAndTime: "Data e ora",
  clickToSetDateAndTime: "Clicca per impostare data e ora",
  DescribeYourEvent: "Descrivi brevemente il tuo evento.",
  onlineEvent: "Evento online",
  offlineEvent: "Evento offline",
  eventLinkDescription:
    "Aggiungi informazioni sul luogo dell'evento, come Zoom o Meet",
  uploadOptional: "Carica (opzionale)",
  uploadEventDetails: "Carica qui un file con dettagli opzionali sull'evento",
  addInformationAboutLocation:
    "Aggiungi informazioni sul luogo dell'evento o un link alla mappa",
  location: "Link di posizione",
  address: "Indirizzo",
  addressOptional: "Indirizzo (opzionale)",
  addAddressEvent: "Aggiungi l'indirizzo dell'evento",
  AllDayEvent: "Questo è un evento per l'intera giornata.",
  toastBuyingYourOwnEvent: "You can not buy your own event!",
  event: "Evento",
  timeToEvent: "Tempo all'evento",
  hoursEvent: "ore",
  seatsLeft: "posti disponibili",
  follower: "Follower",
  priceLimitEventForUA:
    "Il prezzo può essere da ₴{{minPrice}} a ₴{{maxPrice}}.",
  soldOut: "Esaurito!",
  joinEvents: "Partecipa agli eventi",
  noEventFound: "Nessun evento trovato",
  noEventsFoundDescription:
    "Appariranno qui quando partecipi ad almeno un evento",
  editEvents: "Modifica eventi",
  attendForfree: "Partecipa gratuitamente",
  disabled: "Disabilitato",
  enabled: "Abilitato",
  personalCreditcard: "Personale (Carta di credito)",
  privateEntrepreneur: "Imprenditore privato (IBAN)",
  selectPayoutMethod: "Seleziona il metodo di pagamento",
  enterYourPersonalTaxCode: "Inserisci il tuo codice fiscale personale*",
  createFondyMerchantAccount:
    "Crea un account commerciante su Fondy e completa l'iscrizione:",
  create: "Crea",
  participantsMin: "Il numero minimo di partecipanti è 1",
  personalTaxCodeError:
    "Il codice fiscale individuale deve essere un numero valido!",
  participantsError: "L'importo dei partecipanti deve essere un numero valido!",
  peName: "Nome PE",
  url: "Sito web",
  okpo: "OKPO",
  phone: "Telefono",
  peNameTooltip:
    "Il nome ufficiale della tua Partita IVA: 'Cognome Nome Paternità'.",
  urlTooltip:
    "L'URL della tua attività. Può essere il tuo sito web, Facebook, Instagram, ecc.",
  okpoTooltip: "Il codice OKPO della tua Partita IVA.",
  phonePayoutTooltip: "Il tuo numero di telefono.",
  paymentsTipStripe:
    "I pagamenti sono sempre attivi per gli utenti di Stripe. I pagamenti sono disponibili dopo aver completato la registrazione.",
  paymentsTipFondy1:
    "Imprenditori privati: i pagamenti sono attivati dopo la creazione del commerciante. I pagamenti sono disponibili dopo aver completato la registrazione.",
  paymentsTipFondy2:
    "Carte di credito: i pagamenti e i prelievi sono attivati dopo l'aggiunta di una combinazione di carta di credito privata e codice fiscale individuale.",
  phoneError: "Il telefono deve essere un numero valido!",
  toastTaxCodeAdded:
    "Il tuo codice fiscale individuale è stato aggiunto con successo.",
  toastTaxCodeError:
    "Si è verificato un errore durante il tentativo di aggiungere il tuo codice fiscale individuale!",
  toastPECodeAdded:
    "Il tuo nuovo commerciante Fondy è stato creato con successo. Controlla la tua email per iniziare l'integrazione!",
  toastPECodeError:
    "Si è verificato un errore durante il tentativo di creare il tuo nuovo commerciante Fondy!",
  onboarding: "Onboarding",
  notCompletedOnboarding: "Non completato",
  completedOnboarding: "Completato",
  completeOnboarding: "Completa l'onboarding",
  onboardingTooltip:
    "Completa l'onboarding nel pannello di controllo di Fondy. Controlla la tua email per un invito a creare una password, accedi al pannello di controllo di Fondy, carica i documenti richiesti e aggiungi una firma digitale. Una volta approvato, il tuo account verrà attivato.",
  fondyMerchantAccountDetails: "Dettagli dell'account commerciante Fondy",
  eventIsLife: "L'evento è in diretta",
  created: "Iniziato",
  processing: "Elaborazione",
  goToFondyDashboard: "Apri il tuo pannello di controllo Fondy",
  youMustCompleteFondy:
    "Devi completare l'onboarding di Fondy tramite l'invito via email che hai ricevuto all'indirizzo:",
  chooseEventType: "Scegli il tipo di evento",
  addException: "Aggiungi eccezione",
  manageScheduleExceptions: "Gestisci eccezioni nell'orario",
  addRemoveSpecificTime:
    "Aggiungi o rimuovi un momento specifico in cui sei disponibile o non disponibile.",
  exceptionType: "Tipo di eccezione:",
  available: "Disponibile",
  notAvailable: "Non disponibile",
  yes: "Sì",
  no: "No",
  removeException: "Rimuovi eccezione",
  removeExceptionDesc: "Sei sicuro di voler rimuovere l'eccezione {{time}}?",
  NoExceptionsAdded: "Nessuna eccezione è stata aggiunta",
  availableAllDay: "Sarò disponibile per tutto il giorno.",
  notAvailableAllDay: "Non sarò disponibile per tutto il giorno.",
  exceptionDeleteSuccessfully: "Eccezione rimossa con successo",
  exceptionDeleteError: "Impossibile rimuovere la tua eccezione",
  exceptionSavedSuccessfully: "Eccezione salvata con successo",
  exceptionSavedError: "Impossibile salvare la tua eccezione",
  addJoininstructions: "Aggiungi istruzioni per unirti",
  addJoininstructionsDesc:
    "Aggiungi contenuto o messaggi che i tuoi clienti vedranno dopo l'acquisto quando non si sono ancora uniti al canale",
  joinChannel: "Unisciti al canale",
  communityUploadDescription: "Carica immagini o video.",
  detailsOptional: "Dettagli (opzionale)",
  type: "Tipo",
  youCannotAddPast:
    "Non puoi aggiungere un intervallo di tempo che finisce nel passato",
  Sun: "Dom",
  Mon: "Lun",
  Tue: "Mar",
  Wed: "Mer",
  Thu: "Gio",
  Fri: "Ven",
  Sat: "Sab",
  toastEventNotEdited:
    "Si è verificato un errore durante il tentativo di modificare il tuo evento!",
  refunded: "rimborsato",
  declined: "respinto",
  verified: "verificato",
  transactionSuccessful: "Transazione riuscita",
  myTabs: "Le mie schede",
  tabStatus: "{{tabName}} sono {{status}}",
  controlOfferTabs:
    "Tipi di offerte che i clienti possono vedere sulla tua pagina principale:",
  onlyEnglishLetters: "Sono ammesse solo lettere e cifre in inglese",
  notificationCompleteOnboarding: "Completa l'onboarding",
  notificationYourPaidOffersAreUnavailable:
    "Le tue offerte a pagamento non sono disponibili per i tuoi clienti.",
  products: "Prodotti",
  bookingAvailable: "Disponibilità prenotazione",
  bookingAvailableDescription:
    "Specificare se sei disponibile per le prenotazioni in generale.",
  availableKey: "Disponibile",
  notAvailableKey: "Non disponibile",
  paymentOccurs: "Pagamento dovuto ogni",
  paymentOccursOneTime: "Pagamento una tantum.",
  eventIsOver: "L'evento è finito",
  integrations: "Integrazioni",
  externalAnalytics: "Analisi esterna",
  externalAnalyticsDescription:
    "Imposta le tue ID account di analisi esterna qui sotto.",
  participantsManage: "Partecipanti",
  manageEvent: "Gestisci evento",
  noEventsAdded: "Nessun evento aggiunto",
  createEvent: "Crea evento",
  shareEvent: "Condividi evento",
  waylightProfile: "Profilo Waylight",
  tickedId: "ID del biglietto",
  checkIn: "Check-in",
  checkedIn: "Check-in effettuato",
  notCheckedin: "Check-in non effettuato",
  codeIsValid: "Il codice è valido! Check-in effettuato!",
  codeisInvalid: "Codice non valido! Si prega di riprovare.",
  codeIsUsed: "Questo codice è già stato utilizzato!",
  buyMore: "Compra di più",
  noParticipantsYet: "Nessun partecipante ancora",
  emptyDeteilsBuysTickets:
    "Appariranno qui quando qualcuno acquista i biglietti per il tuo evento.",
  connectYourTelegram:
    "Collega il tuo account Telegram per accedere al canale!",
  change: "Cambiamento",
  numberOfTickets: "Numero di biglietti:",
  selectPaymentMethodTitle: "Seleziona metodo di pagamento:",
  or: "- o -",
  addACard: "Aggiungi una carta",
  subscriptionLevels: "Livelli di abbonamento",
  subscriptionLevelsTooltip:
    "Aggiungi fino a 3 livelli al tuo abbonamento alla comunità. Ad esempio: mensile, trimestrale e annuale.",
  levelName: "Nome del livello",
  starter: "Inizio",
  pro: "Professionale",
  levelDiscription: "Nome del livello visualizzato ai clienti",
  period: "Periodo",
  periodDiscription:
    "Ai clienti verrà addebitato per rimanere iscritti a questo intervallo",
  priceDiscription: "Ricevi pagamento in intervalli specifici",
  removeLevel: "Rimuovi livello",
  addAnotherLevel: "Aggiungi un altro livello",
  noTrial: "Nessuna prova",
  trialDiscription:
    "Numero di giorni di accesso gratuito che i tuoi clienti otterranno",
  trialPeriod: "Periodo di prova:",
  selectSubscription: "Seleziona abbonamento:",
  subscription: "Abbonamento",
  removeLevelDescription: "Sei sicuro di voler rimuovere il livello?",
  tryForFree: "Prova gratuitamente",
  startTrial: "Inizia la prova",
  subscribedSuccessfully: "Iscritto con successo",
  edit: "Modifica",
  expirationColumName: "scadenza",
  kick: "Espelli",
  trial: "Prova",
  notJoined: "Non iscritto",
  telegramProfile: "Profilo Telegram",
  notAuthorized: "Non autorizzato",
  noMembersFoundFilter: "Nessun membro trovato secondo il filtro impostato.",
  tryADifferentFilter: "Prova un filtro diverso o un'input di ricerca.",
  "not joined": "Non iscritto",
  "not authorized": "Non autorizzato",
  membership: "Iscrizione",
  priceDiscriptionEvents:
    "Ricevi un pagamento per ogni acquisto di posti in questo evento",
  fanZone: "Zona fan",
  vipZone: "Zona VIP",
  selectTickets: "Seleziona i biglietti:",
  attendance: "Partecipazione",
  eventLevels: "Livelli dell'evento",
  noTicketsSelected: "Nessun biglietto selezionato",
  SetMaximumNumberOfSeats:
    "Imposta il numero massimo di posti per questo livello",
  participants: "Numero di posti",
  import: "Importa",
  export: "Esporta",
  labelNameDescription: "Scegli un'etichetta per i tuoi dati importati",
  hidden: "nascosto",
  upload: "Carica",
  labelName: "Nome etichetta",
  fileIsRequired: "Devi caricare un file!",
  code: "codice",
  level: "Livello",
  label: "Etichetta",
  exportFileError: "Esportazione non riuscita!",
  exportFileSuccess: "File esportato con successo.",
  importFileSuccess: "File importato con successo.",
  importFileError: "Importazione non riuscita!",
  statsSuccess: "Statistiche del check-in caricate con successo.",
  statsError:
    "Si è verificato un errore durante il caricamento delle statistiche del check-in!",
  uploadAText: "Testo normale o file CSV fino a 50 MB",
  onlyTextFilesAllowed: "Sono ammessi solo file di testo o CSV!",
  toastCommunityNotEdited:
    "Si è verificato un errore durante la modifica della community",
  toastYouCanSelectOnlyImage:
    "È possibile selezionare una sola immagine per l’intestazione",
  sections: "Sezioni",
  logInOrSignup: "Accedi o registrati a Waylight qui sotto",
  continue: "Continua",
  invalidEmail: "Email non valida",
  enterCode: "Inserisci il codice",
  haveSentCodeTo: "Abbiamo inviato un codice a",
  paste: "Incolla",
  resendCodeIn: "Rinvia il codice... (fra {{time}} secondi)",
  resendCode: "Rinvia il codice",
  waylight: "Waylight",
  pleaseChooseCountry:
    "Scegli il paese della tua banca o servizio finanziario.",
  buyAccessFor: "Acquista accesso per",
  thankYouForFollowing: "Grazie per seguirci!",
  incorrectCode: "Il codice è errato o scaduto!",
  enterEmailToContinue: "Inserisci l'email per continuare",
  city: "Città",
  social: "Sociale",
  earned: "totale",
  priceLimitARS:
    "Il prezzo può essere ARS$ 0, o da ARS$ {{minPrice}} a ARS$ {{maxPrice}}.",
  priceLimitAUD:
    "Il prezzo può essere A$ 0, o da A$ {{minPrice}} a A$ {{maxPrice}}.",
  priceLimitEUR: "Il prezzo può essere €0, o da €{{minPrice}} a €{{maxPrice}}.",
  priceLimitBOB:
    "Il prezzo può essere Bs. 0, o da Bs. {{minPrice}} a Bs. {{maxPrice}}.",
  priceLimitBGN:
    "Il prezzo può essere лв 0, o da лв {{minPrice}} a лв {{maxPrice}}.",
  priceLimitCAD:
    "Il prezzo può essere CA$ 0, o da CA$ {{minPrice}} a CA$ {{maxPrice}}.",
  priceLimitCLP:
    "Il prezzo può essere CL$ 0, o da CL$ {{minPrice}} a CL$ {{maxPrice}}.",
  priceLimitCOP:
    "Il prezzo può essere COL$ 0, o da COL$ {{minPrice}} a COL$ {{maxPrice}}.",
  priceLimitCRC: "Il prezzo può essere ₡0, o da ₡{{minPrice}} a ₡{{maxPrice}}.",
  priceLimitHRK:
    "Il prezzo può essere kn 0, o da kn {{minPrice}} a kn {{maxPrice}}.",
  priceLimitCZK:
    "Il prezzo può essere Kč 0, o da Kč {{minPrice}} a Kč {{maxPrice}}.",
  priceLimitDKK:
    "Il prezzo può essere kr 0, o da kr {{minPrice}} a kr {{maxPrice}}.",
  priceLimitDOP:
    "Il prezzo può essere RD$ 0, o da RD$ {{minPrice}} a RD$ {{maxPrice}}.",
  priceLimitHKD:
    "Il prezzo può essere HK$ 0, o da HK$ {{minPrice}} a HK$ {{maxPrice}}.",
  priceLimitEGP:
    "Il prezzo può essere EGP 0, o da EGP {{minPrice}} a EGP {{maxPrice}}.",
  priceLimitHUF:
    "Il prezzo può essere Ft 0, o da Ft {{minPrice}} a Ft {{maxPrice}}.",
  priceLimitISK:
    "Il prezzo può essere kr 0, o da kr {{minPrice}} a kr {{maxPrice}}.",
  priceLimitINR: "Il prezzo può essere ₹0, o da ₹{{minPrice}} a ₹{{maxPrice}}.",
  priceLimitIDR:
    "Il prezzo può essere Rp 0, o da Rp {{minPrice}} a Rp {{maxPrice}}.",
  priceLimitILS: "Il prezzo può essere ₪0, o da ₪{{minPrice}} a ₪{{maxPrice}}.",
  priceLimitUYU:
    "Il prezzo può essere $U 0, o da $U {{minPrice}} a $U {{maxPrice}}.",
  priceLimitGBP: "Il prezzo può essere £0, o da £{{minPrice}} a £{{maxPrice}}.",
  priceLimitTRY: "Il prezzo può essere ₺0, o da ₺{{minPrice}} a ₺{{maxPrice}}.",
  priceLimitTTD:
    "Il prezzo può essere TT$ 0, o da TT$ {{minPrice}} a TT$ {{maxPrice}}.",
  priceLimitTHB: "Il prezzo può essere ฿0, o da ฿{{minPrice}} a ฿{{maxPrice}}.",
  priceLimitCHF:
    "Il prezzo può essere CHF 0, o da CHF {{minPrice}} a CHF {{maxPrice}}.",
  priceLimitSEK:
    "Il prezzo può essere kr 0, o da kr {{minPrice}} a kr {{maxPrice}}.",
  priceLimitZAR:
    "Il prezzo può essere R 0, o da R {{minPrice}} a R {{maxPrice}}.",
  priceLimitSGD:
    "Il prezzo può essere SGD$ 0, o da SGD$ {{minPrice}} a SGD$ {{maxPrice}}.",
  priceLimitSAR:
    "Il prezzo può essere SAR 0, o da SAR {{minPrice}} a SAR {{maxPrice}}.",
  priceLimitRON:
    "Il prezzo può essere lei 0, o da lei {{minPrice}} a lei {{maxPrice}}.",
  priceLimitPLN:
    "Il prezzo può essere zł 0, o da zł {{minPrice}} a zł {{maxPrice}}.",
  priceLimitPHP:
    "Il prezzo può essere ₱ 0, o da ₱ {{minPrice}} a ₱ {{maxPrice}}.",
  priceLimitPEN:
    "Il prezzo può essere S/. 0, o da S/. {{minPrice}} a S/. {{maxPrice}}.",
  priceLimitPYG:
    "Il prezzo può essere ₲ 0, o da ₲ {{minPrice}} a ₲ {{maxPrice}}.",
  priceLimitNOK:
    "Il prezzo può essere kr 0, o da kr {{minPrice}} a kr {{maxPrice}}.",
  priceLimitNZD:
    "Il prezzo può essere NZ$ 0, o da NZ$ {{minPrice}} a NZ$ {{maxPrice}}.",
  priceLimitMXN:
    "Il prezzo può essere MX$ 0, o da MX$ {{minPrice}} a MX$ {{maxPrice}}.",
  priceLimitKES:
    "Il prezzo può essere KSh 0, o da KSh {{minPrice}} a KSh {{maxPrice}}.",
  linkInBIOtool: "Link nel tool BIO",
  thatHelpsYouEarn: "che ti aiuta a guadagnare!",
  earnMore: "Guadagna di più",
  withWaylight: "con Waylight:",
  descriptionEarn:
    "Guadagna 10 volte di più sul tuo guadagno dai social media!",
  descriptionEarnPart: "Lancio in soli 2 minuti!",
  earnOnPaidCommunities: "Guadagna sulle community a pagamento.",
  createAndManage: "Crea e gestisci le tue community a pagamento su Telegram.",
  EarnPaidDigital: "Guadagna con prodotti digitali a pagamento.",
  hostAndManage:
    "Ospita e gestisci i tuoi corsi, tutorial, e-book, guide, file, ecc.",
  earnOnline: "Guadagna su eventi online e di persona.",
  sellTicketsAndManage:
    "Vendi biglietti e gestisci i tuoi eventi di gruppo online e di persona.",
  EarnServices: "Guadagna con servizi a pagamento.",
  sellManage: "Vendi e gestisci il flusso dei tuoi servizi.",
  earnOnlineVideoSessions: "Guadagna con sessioni video online a pagamento.",

  EarnAppointments: "Guadagna con appuntamenti di persona a pagamento.",
  ScheduleSignups:
    "Imposta un calendario e ricevi iscrizioni per i tuoi appuntamenti individuali o di gruppo.",
  earnTipping: "Guadagna con le mance.",
  getTippingSupport:
    "Ottieni mance in supporto dei tuoi canali o per la condivisione di contenuti sui social network.",
  youWilGet: "Otterrai",
  tools: "1. Strumenti",
  sales: "2. Vendite",
  clientsGet: "3. Clienti",
  howItWorks: "Come funziona",
  GetClients: "Ottieni nuove vendite e clienti",
  useWaylightGrowth:
    "Usa gli strumenti e i consigli del motore di crescita Waylight per vendere di più e ottenere nuovi clienti dai social network.",
  createWaylight: "Crea Waylight",
  creatorsExperts: "Creatori ed esperti",
  interactionsMonthly: "Interazioni mensili",
  monthOverMonth: "Crescita mese dopo mese",
  forWhom: "Per chi:",
  pricing: "Prezzi:",
  chooseYourPlan: "Scegli il tuo piano",
  saveWhenChoosingYearlyPlan: "Risparmia di più scegliendo un piano annuale!",
  monthlyBilling: "Fatturazione mensile",
  annuallyBilling: "Fatturazione annuale",
  freePlan: "Gratuito",
  startLowestCosts: "Inizia con i costi più bassi ed esplora Waylight",
  mo: "/mese",
  signUp: "Iscriviti",
  impressionMo: "Commissioni del {{comission}}% sulle transazioni.",
  basicToolsIncluded: "Gli strumenti di base sono inclusi!",
  basic: "Base",
  getEverythingBasic:
    "Ottieni tutto in versione gratuito con un livello di commissioni più basso",
  contactSales: "Contatta il reparto vendite",
  transactionFees: "Commissioni di transazione del 2%.",
  publishingMarketplace: "Pubblicazione sulla ricerca del marketplace.",
  saveBadge: "Risparmia il {{percent}}%",
  bestValue: "Miglior valore",
  GetMoreSales:
    "Ottieni più vendite dai tuoi social network senza commissioni di transazione",
  ziroTransactionFees: "0% commissioni di transazione.",
  proToolsIncluded: "Gli strumenti Pro sono inclusi!",
  customizations: "Personalizzazioni.",
  verifiedSign: "Segno verificato.",
  prioritySupport: "Supporto prioritario.",
  launchExpert: "Esperto di lancio è incluso.",
  askTelegram: "Chiedi su Telegram",
  askWhatsApp: "Chiedi su WhatsApp",
  askEmail: "Chiedi via email",
  MakeTenX: "Guadagna 10 volte di più",
  onYourSocialMedia: "sui tuoi social media",
  revenue: "guadagni! 🎉",
  launchJust: "Lancia in soli",
  twoMinutes: "2 minuti!",
  product: "Prodotto:",
  customersDataManagement: "Gestione dei dati dei clienti",
  reviewsProduct: "Recensioni",
  portfolioProduct: "Portfolio",
  businessWebsite: "Sito web aziendale",
  securePayments: "Pagamenti sicuri",
  tipping: "Mance",
  paidAppointments: "Appuntamenti in persona a pagamento",

  paidServices: "Servizi a pagamento",
  ticketsOnlineEvents: "Biglietti per eventi online",
  ticketsScanner: "Scanner per biglietti",
  ticketsPersonEvents: "Biglietti per eventi di persona",
  paidAccessCourses: "Accesso a pagamento ai corsi",
  paidAccessDigitalProducts: "Accesso a pagamento ai prodotti digitali",
  paidGroupChannelAccessTelegram:
    "Accesso a pagamento ai gruppi e canali su Telegram",
  paidSubscriptionCommunitiesTelegram: "Community a pagamento su Telegram",
  productMenuItem: "Prodotto",
  PricingMenuItem: "Prezzi",
  Creator: "Creatore",
  Freelancer: "Freelance",
  Tutor: "Tutor",
  Coach: "Allenatore",
  Influencer: "Influencer",
  ask: "Chiedi",
  anyQuestions: "qualsiasi domanda qui:",
  how: "Come",
  itWorks: "come funziona",
  businessflowStep1new:
    "Aggiungi il tuo link Waylight al BIO dei social media e inizia a coinvolgere il tuo pubblico e i clienti attraverso la condivisione di storie e post a riguardo.",
  businessflowStep2new:
    "Utilizza gli strumenti e i consigli del motore di crescita di Waylight per vendere di più e ottenere nuovi clienti dai social media.",

  businessCalculatorNew: "Calcolatore di guadagno Waylight",
  howMuchMoneyNew:
    "Scopri quanti soldi porterebbero gli abbonati alla tua attività.",
  estimateNumberNew: "1. Stimare il numero di clienti e abbonati",
  howMuchYouChargeNew: "2. Quanto puoi addebitare per un'offerta",
  for: "Per",
  whom: "a chi",
  getEverythingBasicNew:
    "Ottieni tutto nella versione di base con un livello di commissioni più basso",
  startLowestCostsNew: "Inizia senza costi iniziali e esplora Waylight",
  GetMoreSalesNew:
    "Ottieni più vendite dal tuo social media senza commissioni di transazione",
  onYourSocialMediaNew: "sul tuo reddito dai social media! 🎉",
  accordionv2label0: "In quali paesi lavorate?",
  accordionv2text0:
    "Waylight Business opera in oltre 55 paesi su 5 continenti. Puoi contattare il nostro supporto riguardo al tuo paese o semplicemente provare a registrarti e verificare. I clienti possono pagare per le tue offerte da oltre 150 paesi del mondo.",
  accordionv2label1: "Come vengo pagato?",
  accordionv2text1:
    "Riceverai pagamenti sul tuo conto bancario ogni lunedì. Ci vogliono meno di 1 minuto per collegare il tuo conto bancario a Waylight Business.",
  accordionv2label2: "Quali strumenti sono inclusi nel prezzo?",

  accordionv2label3: "Avete Apple Pay, Google Pay, PayPal?",
  accordionv2text3:
    "Sì, ce l'abbiamo. Lo chiamiamo 'Smooth Checkout'. Waylight sta lavorando per automatizzare i flussi di vendita con tassi di conversione più alti, quindi abbiamo pagamenti con un clic (con PayPal in arrivo).",
  accordionv2label4: "Come Waylight mi aiuterà a fare 10 volte di più?",
  accordionv2text4:
    "Avrai accesso al motore di crescita delle vendite e all'accademia di monetizzazione, che ti aiuteranno a vendere di più e a ottenere più clienti dai tuoi social media. Inoltre, Waylight ha già creato flussi e strumenti automatizzati che ti offrono tassi di conversione più alti.",
  accordionv2label5: "Di cosa ho bisogno per iniziare?",
  accordionv2text5:
    "È facile 👌 Registrati, aggiungi il tuo conto bancario, crea offerte, unisciti all'accademia di monetizzazione, aggiungi link sui social media e inizia a guadagnare sempre di più.",
  connectWaylight: "Collegati su Waylight!",
  toastTooLargeFileTwilio:
    "Questo file è troppo grande. La dimensione massima del file è di 150 Mb.",
  enableSold: "Abilita indicatori di vendita offerta?",
  areYouSureYOuWantEnableSold:
    "Sei sicuro di voler abilitare gli indicatori di vendita offerta? I valori di vendita saranno visibili a tutti gli utenti.",
  disableSold: "Disabilita indicatori di vendita offerta?",
  areYouSureYOuWantDisableSold:
    "Sei sicuro di voler disabilitare gli indicatori di vendita offerta?",
  soldEnabled: "Gli indicatori di vendita offerta sono abilitati",
  soldEnabledDescription:
    "Quando questa impostazione è abilitata, i valori di vendita sono visibili a tutti gli utenti.",
  tipOrCommunitypriceLimitARS:
    "Il prezzo può essere da ARS$ 1879.31 a ARS$ 9396554.46.",
  tipOrCommunitypriceLimitAUD: "Il prezzo può essere da A$ 3.01 a A$ 15097.1 .",
  tipOrCommunitypriceLimitEUR: "Il prezzo può essere da €1.81 a €9070.",
  tipOrCommunitypriceLimitBOB:
    "Il prezzo può essere da Bs. 13.81 a Bs. 69119.48.",
  tipOrCommunitypriceLimitBGN: "Il prezzo può essere da лв 3.55 a лв 17750.81.",
  tipOrCommunitypriceLimitCAD:
    "Il prezzo può essere da CA$ 2.74 a CA$ 13718.98.",
  tipOrCommunitypriceLimitCLP:
    "Il prezzo può essere da CL$ 1864.56 a CL$ 9321644.2.",
  tipOrCommunitypriceLimitCOP:
    "Il prezzo può essere da COL$ 8072.11 a COL$ 40360576.84.",
  tipOrCommunitypriceLimitCRC:
    "Il prezzo può essere da ₡1055.02 a ₡5277582.56.",
  tipOrCommunitypriceLimitHRK: "Il prezzo può essere da kn 36 a kn 70472.",
  tipOrCommunitypriceLimitCZK: "Il prezzo può essere da Kč 45.71 a Kč 228558.",
  tipOrCommunitypriceLimitDKK: "Il prezzo può essere da kr 13.54 a kr 67719.",
  tipOrCommunitypriceLimitDOP: "Il prezzo può essere da RD$ 295 a RD$ 588928.",
  tipOrCommunitypriceLimitHKD: "Il prezzo può essere da HK$ 15.58 a HK$ 77915.",
  tipOrCommunitypriceLimitEGP:
    "Il prezzo può essere da EGP 154.75 a EGP 309501.",
  tipOrCommunitypriceLimitHUF: "Il prezzo può essere da Ft 716 a Ft 3579217.",
  tipOrCommunitypriceLimitISK: "Il prezzo può essere da kr 684 a kr 1369300.",
  tipOrCommunitypriceLimitINR: "Il prezzo può essere da ₹167.89 a ₹839435.",
  tipOrCommunitypriceLimitIDR:
    "Il prezzo può essere da Rp 78852 a Rp 157703833.",
  tipOrCommunitypriceLimitILS: "Il prezzo può essere da ₪19 a ₪36657.",
  tipOrCommunitypriceLimitUYU: "Il prezzo può essere da $U 81 a $U 402925.",
  tipOrCommunitypriceLimitGBP: "Il prezzo può essere da £1.56 a £7779.95.",
  tipOrCommunitypriceLimitTRY: "Il prezzo può essere da ₺67.06 a ₺335289.19.",
  tipOrCommunitypriceLimitTTD:
    "Il prezzo può essere da TT$ 13.58 a TT$ 67917.76.",
  tipOrCommunitypriceLimitTHB: "Il prezzo può essere da ฿69.82 a ฿349109.6.",
  tipOrCommunitypriceLimitCHF: "Il prezzo può essere da CHF 1.73 a CHF 8647.",
  tipOrCommunitypriceLimitSEK:
    "Il prezzo può essere da kr 20.82 a kr 104092.39.",
  tipOrCommunitypriceLimitZAR: "Il prezzo può essere da R 94 a R 186359.",
  tipOrCommunitypriceLimitSGD:
    "Il prezzo può essere da SGD$ 2.63 a SGD$ 13146.69.",
  tipOrCommunitypriceLimitSAR: "Il prezzo può essere da SAR 19 a SAR 37496.",
  tipOrCommunitypriceLimitRON:
    "Il prezzo può essere da lei 9.04 a lei 45196.94.",
  tipOrCommunitypriceLimitPLN: "Il prezzo può essere da zł 7.8 a zł 39008.41.",
  tipOrCommunitypriceLimitPHP: "Il prezzo può essere da ₱ 281 a ₱ 560255.",
  tipOrCommunitypriceLimitPEN: "Il prezzo può essere da S/. 19 a S/. 38040.",
  tipOrCommunitypriceLimitPYG: "Il prezzo può essere da ₲ 36387 a ₲ 72772680.",
  tipOrCommunitypriceLimitNOK: "Il prezzo può essere da kr 21.38 a kr 106903.",
  tipOrCommunitypriceLimitNZD: "Il prezzo può essere da NZ$ 3.33 a NZ$ 16646.",
  tipOrCommunitypriceLimitMXN:
    "Il prezzo può essere da MX$ 37.92 a MX$ 189595.",
  tipOrCommunitypriceLimitKES: "Il prezzo può essere da KSh 803 a KSh 1605000.",

  authorizeToAccess: "È necessario autorizzare l'accesso",
  noCommunitiesFound: "Nessuna comunità trovata",
  noCommunitiesFoundDescription:
    "Appariranno qui quando acquisti o aggiungi una comunità",
  following: "Seguente",
  appointments: "Appuntamenti",
  share: "Condividi",
  addOffer: "Aggiungi offerta",
  aboutMe: "Su di me",
  estimatedAmount:
    "Importo totale dei trasferimenti riusciti effettuati sul tuo account Stripe registrato nella sua valuta predefinita.",
  ConnectYourTelegram: "Collega il tuo Telegram:",
  ConnectYourTelegramAccount:
    "Collega il tuo account Telegram per ricevere notifiche dai creatori che stai seguendo.",
  connectTelegram: "Collega Telegram",
  buyAgain: "Acquista di nuovo",
  purchases: "Acquistato",
  videoGuide: "Guida video",
  businessBilling: "Fatturazione Business",
  noPaymentMethodsAdded: "Nessun metodo di pagamento aggiunto",
  yourPaymentMethods: "I tuoi metodi di pagamento",
  yourPlan: "Il tuo piano",
  yearlyBilling: "Fatturazione annuale",
  selectFree: "Seleziona Gratuito",
  whatIncluded: "Cosa è incluso",
  selectStarter: "Seleziona Base",
  selectPro: "Seleziona Pro",
  enterPromoCode: "Inserisci codice promozionale:",
  apply: "Applica",
  selected: "Selezionato",
  waylightSubscription: "Abbonamento Waylight",
  cashbackRefferals: "Riferimenti",
  referrals: "Affiliato",
  shareLink: "Condividi link",
  howReferralsWork: "Come funzionano i riferimenti",
  cashback: "Cashback",
  yourCashbackCode: "Il tuo codice cashback",
  yourCustomPartnerReferralCode:
    "Il tuo codice di riferimento personalizzato per partner",
  howCashbackWork: "Come funziona il cashback",
  toastPromoCodeSuccess:
    "Il tuo codice promozionale è stato applicato con successo!",
  toastPromoCodeError:
    "Si è verificato un errore durante l'applicazione del tuo codice promozionale!",
  enterPromoCodeSignUp: "Inserisci codice promozionale (opzionale):",
  enterPromoCodeBtn: "+ Inserisci codice promozionale",
  billingBusinessTooltip: "Seleziona il tuo piano qui sotto.",
  referralTooltip: "Questo è il tuo codice cashback unico.",
  cashbackTooltip: "Questo è il tuo codice affiliato unico.",
  currentPlan: "Piano attuale:",
  neverExpires: "Mai",
  expires: "Scade:",
  emptyBillingBusinessHistoryDescription:
    "Descrizione vuota della cronologia di fatturazione aziendale",
  notificationGoToBillingBusiness:
    "Abbiamo riscontrato un problema con il pagamento della tua sottoscrizione!",
  WeHaveEncounteredAnIssueSubscription:
    "Abbiamo riscontrato un problema con il pagamento della tua sottoscrizione",
  checkIfYouHaveSufficientSubscription:
    "Controlla se hai fondi sufficienti sul tuo saldo o se i pagamenti su Internet sono abilitati.",
  selectDifferentPaymentMethodSubscription:
    "Seleziona un metodo di pagamento diverso.",
  referralsDescriptions1:
    "Grazie per essere il nostro prezioso partner affiliato!",
  referralsDescriptions2:
    "Completa l'onboarding per ricevere i tuoi pagamenti affiliati. Manteniamo i tuoi soldi al sicuro finché non ti iscrivi.",
  referralsDescriptions3:
    "Avrai bisogno dei dettagli del tuo conto bancario o della tua carta a seconda del tuo paese.",
  referralsDescriptions4:
    "I pagamenti avvengono ogni lunedì. Puoi controllare lo stato del pagamento in Transazioni.",
  referralsDescriptions5:
    "Il tuo codice affiliato fornisce sconti aggiuntivi per le sottoscrizioni Waylight.",
  referralsDescriptions6:
    "I tuoi sconti e le tue tariffe di revenue sharing sono personalizzati in base al nostro accordo.",
  referralsDescriptions7:
    "Se è la prima volta che ricevi il pagamento, possono occorrere da 7 a 14 giorni lavorativi per arrivare a causa di limitazioni del gateway. Normalmente i pagamenti richiedono circa 3 giorni lavorativi per arrivare.",
  cashbackDescriptions1:
    "Grazie per essere un membro del nostro programma cashback!",
  cashbackDescriptions2:
    "Completa l'onboarding per ricevere i tuoi pagamenti affiliati. Manteniamo i tuoi soldi al sicuro finché non ti iscrivi.",
  cashbackDescriptions3:
    "Avrai bisogno dei dettagli del tuo conto bancario o della tua carta a seconda del tuo paese.",
  cashbackDescriptions4:
    "I pagamenti avvengono ogni lunedì. Puoi controllare lo stato del pagamento in Transazioni.",
  cashbackDescriptions5:
    "Condividi il tuo link e ottieni una condivisione dei ricavi dai nuovi clienti.",
  cashbackDescriptions6:
    "Il tuo cashback è del 20-30% di tutte le commissioni di Waylight per 1 anno (solo il 30% delle sottoscrizioni annuali Pro).",
  cashbackDescriptions7:
    "Se è la prima volta che ricevi il pagamento, possono occorrere da 7 a 14 giorni lavorativi per arrivare a causa di limitazioni del gateway. Normalmente i pagamenti richiedono circa 3 giorni lavorativi per arrivare.",
  invalidGTMiD:
    "ID GTM non valido. Inserisci solo il tuo ID, non il codice, ad esempio: GTM-AA11BB2.",
  invalidMetaPixelID:
    "ID Meta Pixel non valido. Inserisci solo il tuo ID, non il codice, ad esempio: 123456789012345.",
  invalidHotjarID:
    "ID Hotjar non valido. Inserisci solo il tuo ID, non il codice, ad esempio: 1112223.",
  accountCreated: "Account creato",
  toastOUrlCopied: "Link di riferimento copiato con successo!",
  YouAreOnFreePlan: "Sei attualmente già su un piano gratuito",
  referralCodeCanNotBeUsed:
    "Questo codice di riferimento non può essere utilizzato per il paese del tuo account attuale: {{country}}",
  createWaylightBusiness: "Crea un'attività Waylight",
  pleaseChooseCountryBank:
    "Scegli il paese della tua banca o del tuo servizio finanziario:",
  referralsDescriptionsUA4:
    "I pagamenti avvengono dopo ogni transazione. Puoi controllare lo stato dei pagamenti nelle Transazioni.",
  referralsDescriptionsUA7:
    "I pagamenti avvengono entro il giorno lavorativo successivo.",
  cashbackDescriptionsUA4:
    "I pagamenti avvengono dopo ogni transazione. Puoi controllare lo stato dei pagamenti nelle Transazioni.",
  cashbackDescriptionsUA7:
    "I pagamenti avvengono entro il giorno lavorativo successivo.",
  issueSubscriptionPayment:
    "Abbiamo riscontrato un problema con il pagamento della tua sottoscrizione!",
  revenueSharhingSelectPE:
    "Il programma di referral è disponibile solo per gli account PE attivati in Ucraina.",
  youCanChangeYourAccount:
    "Puoi cambiare il tipo di account nelle impostazioni.",
  yourReferralCodeWillAppear:
    "Il tuo codice di referral apparirà qui una volta completato l'onboarding di PE.",
  goToPayoutSettings: "Vai alle impostazioni di pagamento",
  commission: "Commissione",
  youAreOnSamePlan:
    "Attualmente sei sullo stesso piano che stai cercando di scegliere! Scegli un altro piano, per favore.",
  onlyCyrillicLettersAllowed: "Sono ammesse solo lettere cirilliche",
  dragToChangePosition: "Trascina per cambiare posizione",
  eventsQrcode: "Codici brevi evento",
  eventsQrcodeDescription:
    "Quando questa impostazione è attiva, i tuoi biglietti per eventi verranno generati con PIN corti a 4 cifre e codici QR. Quando è disattivato, verranno generati PIN più sicuri a 8 cifre con codici a barre.",
  areYouSureYOuWantEnableQrcode:
    "Sei sicuro di voler abilitare i PIN brevi degli eventi con i codici QR?",
  areYouSureYOuWantDisableQrcode:
    "Sei sicuro di voler disabilitare i PIN brevi degli eventi e generare invece i codici a barre?",
  enableEventsQrcode: "Abilita codici brevi evento",
  disableEventsQrcode: "Disabilita codici brevi e passa ai codici a barre",
  authorizeScanner: "Autorizza scanner",
  toastScannerUrlCopied: "URL dello scanner copiato",
  total: "Totale:",
  gtmIdDescription: "GTM locale per questa offerta.",
  metaPixelIdDescription: "Meta Pixel locale per questa offerta.",
  tutorialBoughtPreview:
    "Questa è l'anteprima dello stato acquistato del tuo prodotto.",
  tutorialNotBoughtPreview:
    "Ecco come i clienti vedono il tuo prodotto quando non è acquistato.",
  authorizeToAccessPurchases: "Autorizza per accedere ai tuoi acquisti!",
  myPromotions: "Il mio codice promozionale",

  describeTheDetailsPromotions:
    "Seleziona l'offerta, specifica il nome, scegli un URL e aggiungi i dettagli della promozione qui sotto",
  promotionDropdownDescription:
    "Seleziona la tua offerta dall'elenco. Non potrai cambiarla in seguito per questa promozione.",
  offerNamePromotions: "Offerta",
  promotionsTooltip:
    "Seleziona un'offerta a cui si applicherà questa promozione. Puoi creare tutte le altre promozioni per questa offerta che desideri.",
  offerNameDescriptionPromotions:
    "Inserisci il nome della promozione. Questo è solo per i tuoi scopi interni.",
  codePromotionsDescription:
    "Inserisci un codice promo univoco usando lettere e numeri inglesi. Prova il pulsante magico di generazione, se lo desideri!",
  expirationPromotions: "Scadenza",
  name: "Nome",
  codePromotions: "Codice",
  usersNumDescription:
    "Questo codice promo può essere utilizzato un numero specificato di volte.",
  max_uses: "Numero di utilizzi",
  pricingPromotionsDiscription: "pricingPromotionsDiscription",
  discount: "Sconto",
  discountTitle: "Sconto(i):",
  selectOfferDiscounts:
    "Seleziona l'offerta sopra per iniziare a gestire gli sconti per essa.",
  promoNameCanNotBeEmpty: "Il nome della promozione non può essere vuoto",
  urlIsAlreadyTaken: "Questo URL è già preso!",
  promoCodeValidation:
    "Il codice promo può contenere solo lettere maiuscole e minuscole inglesi e numeri da 0 a 9!",
  promotions: "Promozioni",
  enterPromoCodePromotions: "Inserisci il codice promo",
  promoCodeIncorrectExpired: "Questo codice promo è errato o scaduto!",
  selectedPromoCodeNotAvailable:
    "Il codice promo selezionato non è disponibile!",
  weWereUnableToProcessYourTransaction:
    "Non siamo riusciti a elaborare la tua transazione con il codice promo selezionato a causa della scadenza, del limite di utilizzo o dell'indisponibilità. Si prega di iniziare di nuovo l'acquisto.",
  promotionsEmptyStateHeader:
    "Nessuna promozione trovata in base al filtro impostato.",
  promotionsEmptyStateDescription:
    "Crea promozioni, prova un filtro diverso o una ricerca.",
  toastPromotionUrlCopied: "URL promozione copiato!",
  toastPromotionCodeCopied: "Codice promo copiato!",
  managePromotionsForYourOffers: "Gestisci promozioni per le tue offerte",
  offerPromotions: "Offerte promozionali",
  deletePromotionDescription: "Sei sicuro di voler eliminare la promozione?",
  deletePromotionTitle: "Elimina promozione",
  addPromotion: "Aggiungi promozione",
  toastPromotionNotEditedEnabled:
    "Non siamo riusciti a modificare la tua promozione!",
  toastPromotionNotEdited: "Non siamo riusciti a modificare la tua promozione!",
  usage: "Usato",
  discountedPriceError:
    "Il prezzo scontato non può essere inferiore all'equivalente di 2 USD!",
  priceWithDiscount: "Prezzo con sconto",
  promoCodeAddedToast: "Codice promo applicato con successo!",
  toastPromotionSavedSuccessfully: "Codice promo creato con successo",
  toastPromotiomNotAdded:
    "Si è verificato un errore durante la creazione della tua promozione!",
  myPromotionsMenu: "Le mie promozioni",
  setSubscriptionlevelManually:
    "Imposta il livello di abbonamento manualmente:",
  settingLevelManuallyNotifyClientAutomatically:
    "Impostare un livello manualmente notificherà automaticamente il cliente e sarà applicato con il prossimo addebito su {{expiration}}",
  subscribeForFree: "Iscriviti gratuitamente",
  toastSavedSuccessfullyUpdatedLevelCommunity:
    "Livello della comunità salvato e aggiornato con successo.",
  toastCommunityLevelNotUpdated: "Livello della comunità non aggiornato.",
  addNewCardStripe: "Aggiungi nuova carta (Stripe):",
  creditCard: "Carta di credito",
  promocode: "Promocode",
  nonFollowers: "Non seguaci",
  dueOn: "Previsto per",
  exportMyClientsSuccess: "Esportazione dei clienti completata con successo.",
  exportMyClientsError: "Errore durante l'esportazione dei clienti.",
  eventTelegramDescription:
    "Seleziona il nome del tuo canale o gruppo. Se non vedi nulla qui, assicurati di aver aggiunto Waylight Bot al tuo gruppo o canale come amministratore e questa è l'unica offerta per questo canale!",
  onlineTelegramEvent: "Evento telegramma online",
  joinEvent: "Partecipa all'evento",
  levels: "Livelli",
  paidAccess: "Accesso a pagamento",
  clientWillPayOnceAccess:
    "Il cliente pagherà una volta per ottenere l'accesso",
  noOffersFound: "Nessuna offerta trovata",
  noOffersFoundDescription:
    "Appariranno qui quando acquisterai o aggiungerai un'offerta",
  youCanNotManageEnabled: "Non hai alcuna offerta abilitata che puoi gestire.",

  youGetPaidSpecifiedPriceForFixedAmountTime:
    "Vieni pagato il prezzo specificato per un tempo fisso.",
  videoCallQuick: "Videochiamata",
  tabs: "Schede",

  disconnectWhatsAppBot: "Disconnetti WhatsApp-bot",
  connectWhatsAppBot: "Connetti WhatsApp-bot",
  WhatsAppBot: "WhatsApp-bot",
  videoCallSession: "Videochiamata",
  offerCardSize: "Dimensione della carta offerta",
  large: "Grande",
  small: "Piccolo",
  chooseCardSizeOffer:
    "Scegli la dimensione della carta per la tua offerta nell'elenco",
  copyProfileLink: "Copia link del profilo",
  profileQRCode: "Il mio codice QR del profilo",
  copyCommunitiesLink: "Link delle comunità",
  copyEventsLink: "Link degli eventi",
  copyProductsLink: "Link dei prodotti",
  copySessionsLink: "Link degli appuntamenti",
  copyTipsLink: "Link dei consigli",
  copyPortfolioLink: "Link del portfolio",
  copyReviewsLink: "Link delle recensioni",
  leaveReviewForMe: "Lascia una recensione per me",
  shareYourWaylight: "Condividi il tuo Waylight",
  myProfileQRCode: "Il mio codice QR del profilo",
  menu: "Menu",
  selectOfferExportData: "Seleziona un'offerta per esportare i dati",
  setCancellationDelay: "Imposta il ritardo di annullamento",
  approvalRequired: "Approvazione richiesta",
  cancellationFailed: "Annullamento fallito",
  youUnableCancelDesctiption:
    "Non è possibile annullare un appuntamento entro {{cancelDelay}} ore prima dell'inizio. Contatta il proprietario dell'appuntamento per ulteriori dettagli.",
  rescheduleFailed: "Riprogrammazione fallita",
  unableRescheduleDescription:
    "Non è possibile riprogrammare un appuntamento entro {{rescheduleDelay}} ore prima dell'inizio. Contatta il proprietario dell'appuntamento per ulteriori dettagli.",
  errorRefundDescription:
    "Qualcosa è andato storto durante l'annullamento dell'appuntamento. Si prega di contattare ",
  refundFailed: "Rimborso fallito",
  appointment: "Appuntamento",
  addInformationAboutAppointment:
    "Aggiungi informazioni sull'appuntamento che i tuoi clienti sbloccheranno.",
  addMapLink: "Aggiungi link alla mappa",
  addAddressAppointment: "Aggiungi l'indirizzo dell'appuntamento",
  uploadFileOptionalAppointmentDetails:
    "Carica un file con dettagli opzionali sull'appuntamento",
  locationOptional: "Link di posizione (opzionale)",
  appointmentName: "Nome dell'appuntamento",
  appointmentErrorName: "Il nome dell'appuntamento non può essere vuoto",
  upcomingIn: "In arrivo tra ",
  joinVideoCall: "Partecipa alla videochiamata",
  cancelAppointment: "Annulla appuntamento",
  areYouSureWantCancelClient:
    "Sei sicuro di voler annullare? Il tuo pagamento sarà rimborsato entro 5-10 giorni. Le commissioni del gateway di pagamento potrebbero non essere restituite.",
  areYouSureWantCancelExpert:
    "Sei sicuro di voler annullare? Il pagamento del tuo cliente sarà rimborsato entro 5-10 giorni. Le commissioni del gateway di pagamento potrebbero non essere restituite.",
  aboutAppointment: "Informazioni sull'offerta",
  finishAppointmentTitle: "Sei sicuro di voler terminare l'appuntamento?",
  finishAppointmentDescription:
    "Lo stato del tuo appuntamento sarà cambiato in ‘concluso’ e terminerà.",
  newAppointmentOffer: "Nuova offerta di appuntamento",
  editAppointments: "Modifica appuntamenti",
  cancellationReason: "Motivo della cancellazione",
  aboutAppointmentCreatUpdate: "Informazioni sull'appuntamento",
  totalSessions: "Totale appuntamenti",
  yourSession: "Il tuo appuntamento",
  notHaveUpcomingSessions: "Non hai ancora appuntamenti futuri",
  notHavePastSessions: "Non hai ancora appuntamenti passati",
  notHaveQuickSessions: "Non hai ancora iniziato appuntamenti rapidi",
  upcomingSessions: "Appuntamenti futuri",
  session: "Appuntamento",
  sessionWith: "appuntamento con",
  requestSession: "Richiedi appuntamento",
  goToSession: "Vai all'appuntamento",
  sessions: "Appuntamenti",
  declineSession: "Rifiuta appuntamento",
  wantDeclineSession: "Sei sicuro di voler rifiutare questo appuntamento?",
  endSession: "Termina appuntamento",
  areYouSureYouWantEndSession:
    "Sei sicuro di voler terminare questo appuntamento?",
  aboutOffer: "Informazioni sull'appuntamento",
  aboutTheOffer: "Informazioni sull'appuntamento",
  noTransactionsDescription:
    "Appariranno una volta che avrai ottenuto appuntamenti a pagamento con successo",
  sessionsPassed: "Appuntamenti passati",
  upcomingSession: "Appuntamento futuro",
  bookSession: "Prenota appuntamento",
  sessionsManagement: "Gestione degli appuntamenti",
  hadNoSessions: "Nessun appuntamento questa settimana",
  bookASession: "Quando prenoti un appuntamento, verrà visualizzato qui",
  knowEachOther: "4. Conoscersi e pianificare gli appuntamenti",
  describeRequirements:
    "Descrivi i requisiti per i clienti prima di partecipare agli appuntamenti: messaggi o allegati.",
  preCharge:
    "Verrà applicata una pre-autorizzazione per richiedere il tuo appuntamento.",
  fewRecomendations:
    "Alcuni consigli su come rendere il tuo appuntamento confortevole e produttivo.",
  enjoyTheSession: "Goditi l'appuntamento!",
  getToKnow: "4. Conoscersi e pianificare gli appuntamenti",
  approveSession: "Approva appuntamento",
  approveThisSession:
    "Sei sicuro di voler accettare questo appuntamento? Accettando, accetti di partecipare all'ora specificata.",
  toastSessionCanceled:
    "Questo appuntamento è stato annullato a causa di vincoli di tempo",
  toastSessionWithYourself: "Non puoi prenotare un appuntamento con te stesso!",
  toastNotMayJoin:
    "Sfortunatamente, non puoi partecipare a questo appuntamento poiché ha raggiunto la sua capacità.",
  toastCannotStartSession: "Impossibile avviare l'appuntamento rapido ora",
  notificationAddPaymentMethod:
    "Aggiungi un metodo di pagamento per poter prenotare appuntamenti.",
  notificationSetupVariousKindsOfSessions:
    "Configura vari tipi di appuntamenti per i tuoi clienti, che saranno disponibili per la prenotazione nel tuo profilo.",
  notificationSetupSchedule:
    "Configura un programma e i tuoi clienti potranno prenotare appuntamenti solo in base ad esso.",
  cancelSession: "Annulla appuntamento",
  paymentFailedDescription:
    "Il pagamento non è andato a buon fine e non siamo riusciti a richiedere un nuovo appuntamento.",
  areYouSureCancel: "Sei sicuro di voler annullare questo appuntamento?",
  sessionEnded: "Appuntamento terminato con successo",
  wereNoChatMessages:
    "Non ci sono stati messaggi di chat in questo appuntamento",
  scrollPastHistory:
    "Scorri nella cronologia del tuo appuntamento passato sopra",
  earnedFromLastSession: "guadagnato dall'ultimo appuntamento",
  emptyBillingHistoryDescription:
    "Appariranno qui quando inizierai a prenotare appuntamenti",
  editOffers: "Modifica appuntamenti",
  freeChatWarning:
    "Questo account ha disabilitato la chat gratuita. Puoi richiedere un appuntamento per comunicare.",
  unableToJoinSession: "Impossibile partecipare all'appuntamento",
  scheduleSession: "Pianifica un appuntamento con un esperto o un creatore.",
  startSessionImmediately:
    "Inizia subito un appuntamento gratuito. Invita altri direttamente con un link.",
  quickSessionWarningTittle: "Sei sicuro di voler iniziare l'appuntamento?",
  quickSessionWarningDescription:
    "Hai un appuntamento programmato che si sovrappone all'appuntamento rapido che stai tentando di avviare.",
  expertSessionDecision:
    "Vuoi terminare l'appuntamento o aspettare più a lungo? Terminare l'appuntamento comporterà il trasferimento del pagamento sul tuo conto.",
  clientSessionDecision:
    "Vuoi terminare l'appuntamento o aspettare più a lungo? Terminare l'appuntamento rimborserà il tuo pagamento.",
  cancelledSessionTittle: "Questo appuntamento è stato annullato",
  areYouSureYouWantLeaveSession: "Sei sicuro di voler lasciare l'appuntamento?",
  areYouSureYouWantLeaveSessionDescription:
    "Potrai tornare all'appuntamento fino a quando non finirà.",
  addExtraMinutesDescription:
    "Sei sicuro di voler aggiungere altri 10 minuti a questo appuntamento?",
  unableAddExtraMinutesDescription:
    "Sfortunatamente, hai un appuntamento programmato tra meno di 10 minuti.",
  freeSessionDecision: "Vuoi terminare l'appuntamento o aspettare più a lungo?",
  newSessionOffer: "Nuova offerta di appuntamento",
  newSessionOfferDescription:
    "Crea un appuntamento video a pagamento o gratuito",
  sessionName: "Nome dell'appuntamento",
  createSessionOffer: "Crea offerta di appuntamento",
  createSessionOfferDescription:
    "Aggiungi un appuntamento video a pagamento o gratuito.",
  rescheduleDelayDescription:
    "Specifica con quanto anticipo i tuoi appuntamenti possono essere riprogrammati.",
  rescheduleSession: "Riprogramma appuntamento",
  rescheduleSessionDescription:
    "Sei sicuro di voler riprogrammare l'appuntamento?",
  cannotRescheduleSession: "Impossibile riprogrammare l'appuntamento",
  ScheduleBookingsOnlineVideo:
    "Imposta un programma e ricevi prenotazioni per appuntamenti video online con i tuoi clienti.",
  paidVideoSessions: "Appuntamenti video online a pagamento",
  businessflowStep3new:
    "Ricevi pagamenti per le tue comunità, prodotti digitali, eventi online e offline, mance, appuntamenti in persona e video, ecc.",
  accordionv2text2:
    "Dipende dal piano che scegli, ma in ogni caso avrai accesso ai flussi di monetizzazione completi di Waylight, come comunità a pagamento su Telegram, biglietti, prodotti digitali, corsi, appuntamenti, appuntamenti video, mance, sito web aziendale, dati dei clienti, motori di vendita, accademia di monetizzazione, ecc.",
  enableOffersForManagement:
    "Abilita appuntamenti, eventi o comunità per gestirli qui.",
  specifyQuickSessionPriceDuration:
    "Specifica il prezzo e la durata dell'appuntamento rapido",
  pleaseEnterEmailAccessQuickSession:
    "Inserisci la tua e-mail per accedere all'appuntamento rapido",
  sessionRescheduledByHost:
    "Il tuo appuntamento è stato riprogrammato dall'host",
  active: "Attivo",
  headline: "Titolo",
  userName: "Nome utente",
  turnOf: "Spegnere",
  promotion: "Promozione",
  qRCode: "Codice QR",
  timeframe: "Periodo di tempo",
  youAreInPreviewMode:
    "Sei in modalità anteprima. Per interagire con il profilo, clicca qui sotto per uscire da questa modalità!",
  backToMyProfile: "Torna al mio profilo",
  previewMode: "Modalità anteprima",
  editMenu: "Modifica menu",
  createMenu: "Crea menu",
  digitalProduct: "Prodotto digitale",
  buyTicketsHere: "Acquista i biglietti qui",
  unsubscribedSuccessfully: "Annullamento dell'iscrizione riuscito",

  errorWhileSubscribing: "Errore durante l'iscrizione",
  errorWhileUnsubscribing: "Errore durante l'annullamento dell'iscrizione",
  add: "Aggiungere",
  bio: "Biografia",
  bioTooLong: "Per favore, limita la tua biografia a 300 caratteri",
  publicIdNotAllowed:
    "Questo ID pubblico non può essere creato. Per favore, scegline un altro.",
  telegram: "Telegram",
  whatsApp: "WhatsApp",
  email: "E-mail",
  instagram: "Instagram",
  less: "Meno",
  moreText: "Più",
};
